/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Typography,
  CircularProgress,
  Backdrop,
  IconButton,
  Icon,
  Autocomplete,
  TextField,
  Modal,
  LinearProgress,
  Paper,
  ClickAwayListener,
  Fade,
  Popper,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import Grid from "@mui/material/Grid";
import CustomGrid from "../../components/CustomGrid";
import { Card } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AxiosError, AxiosResponse } from "axios";
import { API_CONSTANTS } from "../../constants/index";
import axios from "axios";
import { useIsMount } from "../../hooks";
import Moment from "react-moment";
import * as _ from "lodash";
import DownloadIcon from "@mui/icons-material/Download";
import API from "../../services/apis/api";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useAppDispatch } from "../../hooks";
import { openSnackbar } from "../../redux/features/snackBarSlice";
import { Upload } from "@mui/icons-material";
import moment from "moment";
import { get } from "lodash";
import { Box } from "@mui/system";
import { getLocalStorageItem } from "../../services/localStorage";
import CloseIcon from "@mui/icons-material/Close";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import samplereport from '../../../public/samplereport.csv'
export const LoadSpinner = styled("div")(({ theme }) => ({
  marginTop: "-39px",
  textAlign: "center",
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0,
};
export const Heading = styled("div")(({ theme }) => ({
  marginBottom: 5,
}));
export const GridWrapper = styled(Box)(({ theme }) => ({}));
const FileDownload = require("js-file-download");

function UploadPage() {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [store, setStore] = useState<any>(null);
  const [storeValue, setStoreValue] = useState<any>(null);
  const [fileUpload, setFile] = useState<any>(null);
  const [refresh, setRefresh] = useState(false);
  const gridOptions: any = useRef();
  const [pageCount, setPageCount] = useState(0);

  const [AnalyticsData, setAnalytics] = useState([]);
  const [download, setDownload] = useState(false);
  const [rowSize, setRowSize]: any = useState(10);
  const isMount = useIsMount();
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedDate1, setSelectedDate1] = useState<any>(null);
  const [totalRowSize, setTotalRowSize]: any = useState("");
  const [currentpage, setCurrentpage] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [stateValue, setStateValue] = useState<any>(null);
  const [cityValue, setCityValue] = useState<any>(null);
  const role = JSON.parse(getLocalStorageItem("role") as any);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedDate1(null);
    setFile(null);
  };

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const headerHeight = 60;
  const rowHeight = 60;
  const rowStyle = {
    borderTop: "white 8px solid",
  };
  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenFilter((previousOpen) => !previousOpen);
  };

  const canBeOpen = openFilter && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      flex: 1,
      resizable: true,
    };
  }, []);
  const defaultColGroupDef = useMemo(() => {
    return {
      marryChildren: true,
    };
  }, []);
  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");

            var dateParts = dateAsString.split("/");

            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
    };
  }, []);

  const onGridReady = useCallback((params: any) => {
    gridOptions.current = params;
  }, []);

  const cleanUp = () => {
    setTotalRowSize(0);
    setAnalytics([]);
  };

  useEffect(() => {
    if (totalRowSize > 0) {
      if (totalRowSize <= rowSize) {
        setPageCount(Math.ceil(totalRowSize / rowSize - 1));
      } else {
        setPageCount(Math.ceil(totalRowSize / rowSize));
      }
    }
  }, [storeValue, rowSize, currentpage, totalRowSize, selectedDate]);

  const columnDef = [
    {
      field: "storecode",
      headerName: "storecode",
      sortable: true,
      minWidth: 180,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "storecode",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
    {
      field: "date",
      minWidth: 150,
      resizable: true,
      tooltipField: "date",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
      type: ["dateColumn", "nonEditableColumn"],
      cellRendererFramework: (props: any) => {
        const { data } = props;

        return <Moment format="MMM-YYYY">{_.get(data, "date", "")}</Moment>;
      },
    },
    {
      field: "storename",
      minWidth: 380,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "storename",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },

    {
      field: "location",
      minWidth: 250,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "location",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "state",
      minWidth: 250,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "state",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "keyword",
      minWidth: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "keyword",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "volume",
      minWidth: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "volume",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Keyword_relevant",
      minWidth: 260,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "Keyword_relevant",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "category",
      minWidth: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "category",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
  ];
  const fileHandler = (event: any) => {
    setFile(event.target.files[0]);
  };

  function handleSubmit(event: any) {
    event.preventDefault();
    setLoading1(true);

    const formData = new FormData();
    formData.append("searchLogs", fileUpload);
    formData.append("date", selectedDate1);
    API.PostFormRequest(
      `${API_CONSTANTS.POST_UPLOADSEARCHLOGS}`,
      (res: AxiosResponse) => {
        setLoading1(false);
        handleClose();
        dispatch(
          openSnackbar({
            severity: "success",
            messege: "Uploaded Successfully",
          })
        );
      },
      (error: AxiosError) => {
        setLoading1(false);

        if (error) {
          dispatch(
            openSnackbar({
              severity: "error",
              messege: get(error, "response.data.messege", "Error"),
            })
          );
        } else {
          dispatch(
            openSnackbar({
              severity: "error",
              messege: "Try again Later",
            })
          );
        }
      },
      formData
    );
  }
  useEffect(() => {
    if (totalRowSize > 0) {
      if (totalRowSize <= rowSize) {
        setPageCount(Math.ceil(totalRowSize / rowSize - 1));
      } else {
        setPageCount(Math.ceil(totalRowSize / rowSize));
      }
    }
  }, [rowSize, currentpage, isMount, totalRowSize]);

  useEffect(() => {
    API.fetchWithParams(
      API_CONSTANTS.GET_SERACH_AVALILABLE_DATES,
      (res: any) => {
        setSelectedDate([
          moment(res.data.data.endMoth).startOf("month").format("YYYY-MM-DD"),
          moment(res.data.data.endMoth).endOf("month").format("YYYY-MM-DD"),
        ]);
      },
      (err: any) => {},
      {}
    );
    let match;
    if (cityValue !== null && stateValue !== null) {
      match = JSON.stringify({ city: cityValue });
    } else if (stateValue !== null && cityValue === null) {
      match = JSON.stringify({ state: stateValue });
    } else {
      match = JSON.stringify({});
    }
    API.fetchWithParams(
      API_CONSTANTS.GET_STORE_DATA,
      (res: any) => {
        setStore(res.data?.data);
      },
      (err: any) => {
        if (err.response.status <= 400 && err.response.status > 500) {
          localStorage.clear();

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
        match: match,
      }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_STATES,
      (res: any) => {
        setState(res?.data?.data);
      },
      (err: any) => {
        if (err.response.status <= 400 && err.response.status > 500) {
          localStorage.clear();

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
      }
    );
    if (stateValue !== null) {
      API.fetchWithParams(
        API_CONSTANTS.GET_CITIES,
        (res: any) => {
          setCity(res.data.data);
        },
        (err: any) => {
          if (err.response.status <= 400 && err.response.status > 500) {
            localStorage.clear();
            // navigate('/login', { replace: true });

            dispatch(
              openSnackbar({
                messege: get(err, "", "Token Expired! Log In Again"),
                severity: "error",
              })
            );
          }
        },
        {
          brandId: "62d1157242a9f208b4388b8a",
          state: `${stateValue}`,
        }
      );
    }
  }, [stateValue, cityValue]);
  useEffect(() => {
    setLoading(true);
    let match;
    if (stateValue !== null && cityValue === null && storeValue !== null) {
      match = JSON.stringify({ state: stateValue, storeId: storeValue });
    } else if (
      stateValue !== null &&
      cityValue === null &&
      storeValue === null
    ) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && storeValue === null) {
      match = JSON.stringify({ city: cityValue });
    } else if (cityValue !== null && storeValue !== null) {
      match = JSON.stringify({ city: cityValue, storeId: storeValue });
    } else if (
      storeValue !== null &&
      cityValue === null &&
      stateValue === null
    ) {
      match = JSON.stringify({ storeId: storeValue });
    } else {
      match = JSON.stringify({});
    }
    if (selectedDate !== null) {
      API.fetchWithParams(
        API_CONSTANTS.GET_SEARCH_DATA,
        (res: any) => {
          setAnalytics(_.get(res, "data.data.result", []));
          setTotalRowSize(_.get(res, "data.data.totalRecords", 0));
          setLoading(false);
          if (storeValue.length === 0 && res.data.messege === "no data found") {
            dispatch(
              openSnackbar({
                messege: get(res, "response.data.message", "No Data Found"),
                severity: "error",
              })
            );
          } else if (storeValue && res.data.messege === "no data found") {
            dispatch(
              openSnackbar({
                messege: get(res, "response.data.message", "Store Not Found"),
                severity: "error",
              })
            );
          }
        },
        (err: any) => {
          setLoading(false);
          if (err.response.status >= 400 && err.response.status <= 500) {
            localStorage.clear();
            dispatch(
              openSnackbar({
                messege: get(err, "response.data.message", "Token Expired!"),
                severity: "error",
              })
            );
          }
        },
        {
          fromDate: moment(selectedDate[0]).format("YYYY-MM-DD"),
          toDate: moment(selectedDate[1]).format("YYYY-MM-DD"),
          pageNo: currentpage,
          size: rowSize,
          // storeCode: storeValue,
          match: match,
        }
      );
    }
    setRefresh(false);
  }, [
    stateValue,
    selectedDate,
    cityValue,
    storeValue,
    currentpage,
    rowSize,
    refresh,
  ]);
  const handleClickAway = () => {
    setOpenFilter(false);
  };

  let stateArray = state?.map((i: any) => {
    return { stateName: i };
  });

  const cityArray = city?.map((i: any) => {
    return { cityName: i };
  });

  const Token = getLocalStorageItem("token");
  const handleDownload = () => {
    setDownload(true);
    let match;
    if (stateValue !== null && cityValue === null && storeValue !== null) {
      match = JSON.stringify({ state: stateValue, storeId: storeValue });
    } else if (
      stateValue !== null &&
      cityValue === null &&
      storeValue === null
    ) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && storeValue === null) {
      match = JSON.stringify({ city: cityValue });
    } else if (cityValue !== null && storeValue !== null) {
      match = JSON.stringify({ city: cityValue, storeId: storeValue });
    } else if (
      storeValue !== null &&
      cityValue === null &&
      stateValue === null
    ) {
      match = JSON.stringify({ storeId: storeValue });
    } else {
      match = JSON.stringify({});
    }
    const path = `${process.env.REACT_APP_BACKEND_API_PROXY}${
      API_CONSTANTS.DOWNLOAD_KEYWORDS
    }?fromDate=${moment(selectedDate[0]).format("YYYY-MM-DD")}&toDate=${moment(
      selectedDate[1]
    )
      .add(1, "day")
      .format("YYYY-MM-DD")}&match=${match}`;
    const name = moment(selectedDate[0]).format("MMM-YYYY");
    axios({
      url: path, //your url
      method: "POST",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        setDownload(false);

        FileDownload(res.data, `Close_Bi ${name}.csv`);
      })
      .catch((error) => {
        setDownload(false);
        dispatch(
          openSnackbar({
            messege: get(
              error,
              "response.data.message",
              " Try again later-yes"
            ),
            severity: "error",
          })
        );
        console.error("download file error", error);
      });
  };

  return (
    <div>
      <Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress sx={{ color: "green" }} />
        </Backdrop>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={7} xl={7}>
          <Heading sx={{ mt: 1, ml: 1 }}>
            <Typography variant="h6" style={{ fontSize: "23px" }}>
              Search Keywords
            </Typography>
          </Heading>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1}
          xl={1}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button
            aria-describedby={id}
            sx={{
              backgroundColor: "#A0D5EF",
              color: "black",
              boxShadow: "1px 1px 1px 1px #DCDCDC",
              "&:hover": {
                backgroundColor: "#A0D5EF",
              },
              width: "100%",
            }}
            onClick={handleClickFilter}
            variant="contained"
            startIcon={<FilterAltIcon />}
          >
            Filter
          </Button>

          <Popper
            id={id}
            open={openFilter}
            anchorEl={anchorEl}
            transition
            sx={{ width: "360px", marginTop: "20px" }}
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography sx={{ p: 3 }}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                          label="Select Month"
                          views={["year", "month"]}
                          disabled={loading1}
                          value={selectedDate[0] ? selectedDate[0] : null}
                          onMonthChange={(date1) => {
                            const date = get(date1, "_d");
                            setSelectedDate([
                              moment(date)
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                              moment(date).endOf("month").format("YYYY-MM-DD"),
                            ]);
                            cleanUp();
                          }}
                          onChange={(date) => {}}
                          disableFuture={true}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              placeholder={moment(selectedDate[0]).format(
                                "YYYY-MMM"
                              )}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: `${moment(selectedDate[0]).format(
                                  "YYYY-MMM"
                                )}`,
                              }}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <div style={{ marginTop: "20px" }}>
                        <Autocomplete
                          id="storeName"
                          style={{ width: "100%" }}
                          options={stateArray}
                          clearOnBlur={true}
                          getOptionLabel={(option: any) => option.stateName}
                          sx={{ width: 300 }}
                          value={
                            stateValue !== null
                              ? stateArray?.find(
                                  (item: any) => item.stateName === stateValue
                                )
                              : null
                          }
                          placeholder="Select State"
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                style: {
                                  color: "green",
                                  fontSize: "20px",
                                  fontFamily: "Inter",
                                },
                              }}
                              variant="standard"
                              placeholder="Select state"
                            />
                          )}
                          onChange={(event: any, newValue: any, reason) => {
                            if (reason === "clear") {
                              setCityValue(null);
                              setStateValue(null);
                              setStoreValue(null);
                              setCurrentpage(1);
                              cleanUp();
                              return;
                            } else {
                              setCityValue(null);
                              setStoreValue(null);
                              setStateValue(newValue.stateName);
                              setCurrentpage(1);
                              cleanUp();
                            }
                          }}
                        />
                      </div>
                      <div>
                        <div style={{ marginTop: "20px" }}>
                          <Autocomplete
                            id="storeName"
                            style={{ width: "100%" }}
                            options={cityArray}
                            getOptionLabel={(option: any) => option?.cityName}
                            clearOnBlur={true}
                            value={
                              cityValue !== null
                                ? cityArray?.find(
                                    (item: any) => item.cityName === cityValue
                                  )
                                : null
                            }
                            placeholder="Select City"
                            disabled={stateValue === null || !cityArray}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  style: {
                                    color: "green",
                                    fontSize: "20px",
                                    fontFamily: "Inter",
                                  },
                                }}
                                variant="standard"
                                placeholder="Select City"
                              />
                            )}
                            onChange={(event: any, newValue: any, reason) => {
                              if (reason === "clear") {
                                setCityValue(null); //for reset the value()
                                setStoreValue(null);
                                setCurrentpage(1);
                                cleanUp();
                                return;
                              } else {
                                setCityValue(newValue.cityName);
                                setStoreValue(null);
                                setCurrentpage(1);
                                cleanUp();
                              }
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <Autocomplete
                            id="storeName"
                            style={{ width: "100%" }}
                            options={store}
                            getOptionLabel={(option: any) => option.storeName}
                            sx={{ width: 300 }}
                            value={
                              storeValue !== null
                                ? store?.find(
                                    (item: any) => item.storeId === storeValue
                                  )
                                : null
                            }
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option.storeName} - {option.city}
                              </Box>
                            )}
                            placeholder="Select Store"
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  style: {
                                    color: "green",
                                    fontSize: "20px",
                                    fontFamily: "Inter",
                                  },
                                }}
                                variant="standard"
                                placeholder="Select store"
                              />
                            )}
                            onChange={(event: any, newValue: any, reason) => {
                              if (reason === "clear") {
                                setStoreValue(null);
                                setCurrentpage(1);
                                cleanUp();
                                return;
                              } else {
                                setStoreValue(newValue.storeId);
                                setCurrentpage(1);
                                cleanUp();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Typography>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          lg={2}
          xl={2}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Paper
            style={{
              backgroundColor: "#A0D5EF",
              color: "black",
              boxShadow: "1px 1px 1px 1px #DCDCDC",
            }}
          >
            <IconButton
              onClick={() => {
                cleanUp();
                setRefresh(true);
              }}
            >
              <Icon style={{ color: "black" }}>sync</Icon>
            </IconButton>
          </Paper>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            sx={{ marginLeft: "10px", width: "100%" }}
          >
            <Button
              variant="contained"
              onClick={handleDownload}
              startIcon={<DownloadIcon />}
              sx={{
                backgroundColor: "#A0D5EF",
                color: "black",
                boxShadow: "1px 1px 1px 1px #DCDCDC",
                "&:hover": {
                  backgroundColor: "#A0D5EF",
                },
              }}
            >
              <Typography style={{ color: "#00000" }}>
                {download ? "Exporting" : "Export"}
              </Typography>
            </Button>
            <Box></Box>
          </Grid>
          {role === "admin" ? (
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{ marginLeft: "90px", width: "100%" }}
            >
              <Button
                variant="contained"
                onClick={handleOpen}
                startIcon={<UploadIcon />}
                sx={{
                  backgroundColor: "#A0D5EF",
                  color: "black",
                  boxShadow: "1px 1px 1px 1px #DCDCDC",
                  "&:hover": {
                    backgroundColor: "#A0D5EF",
                  },
                }}
              >
                <Typography style={{ color: "#00000" }}>Upload</Typography>
              </Button>
              <Box>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loading1}
                >
                  <CircularProgress sx={{ color: "green" }} />
                </Backdrop>
              </Box>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <>
                  <Box sx={style}>
                    {loading1 ? <LinearProgress /> : null}
                    <Box sx={{ p: 4 }}>
                      <IconButton
                        onClick={handleClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: () => theme.palette.grey[500],
                        }}
                        disabled={loading1}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ marginTop: "30px" }}
                        >
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                              label="Select Month"
                              views={["year", "month"]}
                              disabled={loading1}
                              value={selectedDate1 ? selectedDate1 : null}
                              onChange={(date) => {
                                const date1 = get(date, "_d");
                                setSelectedDate1(
                                  moment(date1).format("YYYY-MM-DD")
                                );
                              }}
                              disableFuture={true}
                              renderInput={(params) => (
                                <TextField fullWidth {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ marginTop: "15px" }}
                        >
                          <input
                            style={{ width: "100%" }}
                            onChange={(e) => fileHandler(e)}
                            type="file"
                            disabled={!selectedDate1 || loading1}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ marginTop: "15px" }}
                        >
                          <Button
                            style={{
                              width: "100%",
                            }}
                            variant="contained"
                            disabled={!fileUpload || loading1}
                            onClick={handleSubmit}
                            endIcon={<Upload />}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </>
              </Modal>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Card style={{ marginTop: "15px", padding: "10px" }}>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} sm={5} md={6} lg={6} xl={6}>
            <Typography
              sx={{ mx: 1, my: 2 }}
              style={{
                fontWeight: 400,
                fontSize: "15px",
                color: "#000000",
              }}
            >
              Total Records Founds For This Search : {totalRowSize}
            </Typography>
          </Grid>
          {role === "admin" ? (
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              xl={5}
              sm={5}
              style={{
                display: "flex",
                alignItems: "top",
                justifyContent: "flex-end",
              }}
            >
              <a
                href={process.env.PUBLIC_URL + "/samplereport.csv"}
                download={"sample.csv"}
              >
                Download sample report
              </a>
            </Grid>
          ) : null}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomGrid
              handleCellClick={undefined}
              loading={loading}
              onGridReady={onGridReady}
              suppressExcelExport={true}
              serverSidePagination={true}
              disableClickSelectionRenderers={false}
              columnDefs={columnDef}
              defaultColDef={defaultColDef}
              defaultColGroupDef={defaultColGroupDef}
              columnTypes={columnTypes}
              suppressMultiRangeSelection={true}
              noDataTxt="No Data..."
              rowData={AnalyticsData}
              TableHeight={80}
              serverPageCount={pageCount}
              totalDataCount={totalRowSize}
              currentPage={currentpage}
              setServerSidePage={(event: any, page: number) => {
                setCurrentpage(page);
              }}
              setServerRowSize={setRowSize}
              serverRowSize={rowSize}
              rowHeight={rowHeight}
              rowStyle={rowStyle}
              headerHeight={headerHeight}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default UploadPage;
