import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  userDetails: any;
}

const initialState: UserState = {
  userDetails: "",
};

export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getCurrentUser: (state, payload: PayloadAction<UserState>) => {
      state.userDetails = payload.payload.userDetails;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getCurrentUser } = userSlice.actions;

export default userSlice.reducer;
