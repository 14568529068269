import CustomGrid from "../../../components/CustomGrid";
import { Grid, Paper, Typography, useTheme } from "@mui/material";

import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { openSnackbar } from "../../../redux/features/snackBarSlice";
import * as _ from "lodash";
import { useAppDispatch } from "../../../hooks";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { API_CONSTANTS } from "../../../constants";
import API from "../../../services/apis/api";
import "../../../App.css";
import { get } from "lodash";

// import { startOfDay, endOfDay, subDays } from "date-fns";
import "rsuite/dist/rsuite.min.css";

export const GridWrapper = styled(Box)(({ theme }) => ({}));

function HomeTable({ stateValue, cityValue, selectedDate, storeValue }: any) {
  const [actionDatas, SetActionDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const dispatch = useAppDispatch();
  const [currentpage, setCurrentpage] = useState(1);

  const [rowSize, setRowSize]: any = useState(10);

  const [totalRowSize, setTotalRowSize]: any = useState(10);
  let navigate = useNavigate();

  // Export Datas
  const gridOptions: any = useRef();
  const onGridReady = useCallback((params: any) => {
    gridOptions.current = params;
  }, []);

  useEffect(() => {
    setLoading(true);
    let match;
    if (stateValue !== null && cityValue === null && storeValue !== null) {
      match = JSON.stringify({ state: stateValue, storeId: storeValue });
    } else if (
      stateValue !== null &&
      cityValue === null &&
      storeValue === null
    ) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && storeValue === null) {
      match = JSON.stringify({ city: cityValue });
    } else if (cityValue !== null && storeValue !== null) {
      match = JSON.stringify({ city: cityValue, storeId: storeValue });
    } else if (
      storeValue !== null &&
      cityValue === null &&
      stateValue === null
    ) {
      match = JSON.stringify({ storeId: storeValue });
    } else {
      match = JSON.stringify({});
    }

    API.fetchWithParams(
      API_CONSTANTS.GET_ACTION_DATA,
      (res: any) => {
        setLoading(false);

        SetActionDatas(_.get(res, "data.data.result", []));
        setTotalRowSize(_.get(res, "data.data.totalRecords", 0));
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status >= 400 && err.response.status < 500) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        fromDate: moment(selectedDate[0]).format("YYYY-MM-DD"),
        toDate: moment(selectedDate[1]).add(1, "day").format("YYYY-MM-DD"),
        pageNo: currentpage,
        size: rowSize,
        match: match,
      }
    );
  }, [
    stateValue,
    selectedDate,
    cityValue,
    storeValue,
    totalRowSize,
    currentpage,
  ]);

  useEffect(() => {
    if (totalRowSize > 0) {
      if (totalRowSize <= rowSize) {
        setPageCount(Math.ceil(totalRowSize / rowSize - 1));
      } else {
        setPageCount(Math.ceil(totalRowSize / rowSize));
      }
    }
  }, [rowSize, currentpage, totalRowSize, selectedDate, pageCount]);

  // useEffect(() => {
  //   API.fetchWithParams(
  //     API_CONSTANTS.GET_AVALILABLE_DATES,
  //     (res: any) => {
  //       setLoading(false);
  //       setFromDate(moment(res.data.data.startDate).format('YYYY-MM-DD'));
  //       setToDate(moment(res.data.data.endDate).format('YYYY-MM-DD'));
  //       // setSelectedDate([
  //       //   moment(res.data.data.endDate).format('YYYY-MM-DD'),
  //       //   moment(res.data.data.endDate).format('YYYY-MM-DD'),
  //       // ]);
  //     },
  //     (err: any) => {
  //       setLoading(false);
  //       if (err.response.status <= 400 && err.response.status >= 500) {
  //         localStorage.clear();
  //         navigate('/login', { replace: true });

  //         dispatch(
  //           openSnackbar({
  //             messege: get(err, 'response.data.message', ' Token Expired'),
  //             severity: 'error',
  //           })
  //         );
  //       }
  //     },
  //     {}
  //   );
  // }, []);

  // const onDownload = (path: any, name: string) => {
  //   setDownload(true);
  //   axios({
  //     url: path, //your url
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //     method: 'GET',
  //     responseType: 'blob', // important
  //   })
  //     .then((res) => {
  //       setDownload(false);
  //       FileDownload(res.data, `Close_Bi_${name}.csv`);
  //     })
  //     .catch((error) => {
  //       setDownload(false);
  //       dispatch(
  //         openSnackbar({
  //           messege: get(error, 'response.data.message', ' Try again later'),
  //           severity: 'error',
  //         })
  //       );
  //       console.error('download file error', error);
  //     });
  // };
  const headerHeight = 60;
  const rowHeight = 60;
  const rowStyle = {
    borderTop: "white 8px solid",
  };
  const theme = useTheme();

  const columnDef = [
    {
      field: "Store code",
      headerTooltip: "Store code",
      minWidth: 180,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filter: "agSetColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: " Store code",
    },
    {
      field: "Business name",
      headerTooltip: "Business name",
      minWidth: 380,
      cellStyle: () => ({
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
      }),
      filter: "agSetColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "Business name",
    },
    {
      field: "Date",
      sortable: true,
      minWidth: 200,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      cellRendererFramework: (props: any) => {
        const { data } = props;
        const date = _.get(data, "Date", "");
        const secToMintues = moment(date).format("DD/MMM/YYYY");
        return secToMintues;
      },
      resizable: true,
      tooltipField: "Date",
      headerTooltip: "Date",
    },
    {
      field: "GoogleSearchMobile",
      minWidth: 220,
      resizable: true,
      headerTooltip: "GoogleSearchMobile",
      filter: "agSetColumnFilter",
      sortable: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
    },
    {
      field: "GoogleSearchDesktop",
      filter: "agSetColumnFilter",
      sortable: true,
      minWidth: 220,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      headerTooltip: "GoogleSearchDesktop",
      resizable: true,

      tooltipField: "GoogleSearchDesktop",
    },
    {
      field: "GoogleMapsMobile",
      filter: "agSetColumnFilter",
      sortable: true,
      minWidth: 220,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      headerTooltip: "GoogleMapsMobile",
      resizable: true,
      tooltipField: "GoogleMapsMobile",
    },
    {
      field: "GoogleMapsDesktop",
      filter: "agSetColumnFilter",
      sortable: true,
      minWidth: 220,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),

      resizable: true,
      headerTooltip: "GoogleMapsDesktop",
      tooltipField: "GoogleMapsDesktop",
    },
    {
      field: "Calls",
      filter: "agSetColumnFilter",
      sortable: true,
      minWidth: 220,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),

      resizable: true,
      headerTooltip: "Calls",
      tooltipField: "Calls",
    },
    {
      field: "Directions",
      filter: "agSetColumnFilter",
      sortable: true,
      minWidth: 250,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),

      resizable: true,
      headerTooltip: "Directions",
      tooltipField: "Directions",
    },
    {
      field: "Websiteclicks",
      filter: "agSetColumnFilter",
      sortable: true,
      minWidth: 250,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),

      resizable: true,
      headerTooltip: "Websiteclicks",
      tooltipField: "Websiteclicks",
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      flex: 1,
      resizable: true,
    };
  }, []);
  const defaultColGroupDef = useMemo(() => {
    return {
      marryChildren: true,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: {
        width: 200,
        filter: "agNumberColumnFilter",
        flex: 5,
        marginLeft: 200,
      },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");

            var dateParts = dateAsString.split("/");

            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );

            if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
    };
  }, []);

  return (
    <div>
      <Paper
        style={{
          padding: theme.spacing(2),
          background: "#FFFFFF",
          boxShadow: "-6px -4px 15px #FFFFFF, 4px 4px 15px #C4C4C4",
          borderRadius: "4px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3} sm={5} md={5} lg={6} xl={6}>
            <Typography
              variant="h6"
              sx={{ mx: 1, my: 2 }}
              style={{
                fontWeight: 500,
                fontSize: "23px",
                color: "#000000",
              }}
            >
              Actions
            </Typography>
            <Typography
              sx={{ mx: 1, my: 2 }}
              style={{
                fontWeight: 400,
                fontSize: "15px",
                color: "#000000",
              }}
            >
              Total Records Founds For This Search : {totalRowSize}
            </Typography>
          </Grid>
          {/* <Grid
            item
            xs={7}
            sm={5}
            md={5}
            lg={4}
            xl={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <IconButton
                onClick={() => {
                  updateFields(selectedDate);
                }}
              >
                <Icon color={"primary"}>sync</Icon>
              </IconButton>
            </Box>
            <DateRangePicker
              placeholder={moment(selectedDate[0]).format("DD/MMM/YYYY")}
              format="dd-MMM-yyyy"
              disabledDate={allowedRange(fromDate, toDate)}
              style={{ width: "700px" }}
              disabled={loading}
              onChange={(dates) => {
                if (dates) {
                  setSelectedDate(dates);
                  cleanUp();
                }
              }}
            />
          </Grid>
          <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{ mx: 1, my: 2 }}
              variant="contained"
              style={{ borderRadius: "3px" }}
              onClick={() => {
                onDownload(
                  `${process.env.REACT_APP_BACKEND_API_PROXY}${
                    API_CONSTANTS.DOWNLOAD_ANALYTICS_IN_RANGE
                  }?fromDate=${moment(selectedDate[0]).format("YYYY-MM-DD")}
                  &toDate=${moment(selectedDate[1])
                    .add(1, "day")
                    .format("YYYY-MM-DD")}`,
                  `${moment(selectedDate[0]).format("YYYY-MM-DD")}_${moment(
                    selectedDate[1]
                  ).format("YYYY-MM-DD")}&storeCode=${storeData}`
                );
              }}
              disabled={download || totalRowSize === 0}
            >
              <Typography style={{ color: "#FFFFFF", fontSize: "16px" }}>
                {download ? "Please Wait..." : "Export Report"}
              </Typography>
            </Button>
          </Grid> */}
        </Grid>
        <GridWrapper>
          <CustomGrid
            handleCellClick={undefined}
            loading={loading}
            onGridReady={onGridReady}
            suppressExcelExport={true}
            serverSidePagination={true}
            disableClickSelectionRenderers={false}
            columnDefs={columnDef}
            defaultColDef={defaultColDef}
            defaultColGroupDef={defaultColGroupDef}
            columnTypes={columnTypes}
            suppressMultiRangeSelection={true}
            noDataTxt="No Data..."
            rowData={actionDatas}
            TableHeight={80}
            serverPageCount={pageCount}
            totalDataCount={totalRowSize}
            currentPage={currentpage}
            setServerSidePage={(event: any, page: number) => {
              setCurrentpage(page);
            }}
            setServerRowSize={setRowSize}
            serverRowSize={rowSize}
            rowHeight={rowHeight}
            rowStyle={rowStyle}
            headerHeight={headerHeight}
          />
        </GridWrapper>
      </Paper>
    </div>
  );
}

export default HomeTable;
