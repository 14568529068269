/* eslint-disable react-hooks/exhaustive-deps */
import CustomGrid from "../../../components/CustomGrid";
import {
  Button,
  Grid,
  Icon,
  IconButton,
  Paper,
  Typography,
  useTheme,
  TextField,
  Autocomplete,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import * as _ from "lodash";
import moment from "moment";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as React from "react";
import { Box } from "@mui/system";
import { API_CONSTANTS } from "../../../constants";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import API from "../../../services/apis/api";
import { useAppDispatch } from "../../../hooks";
import { openSnackbar } from "../../../redux/features/snackBarSlice";
import { DateRangePicker } from "rsuite";
import { startOfDay, endOfDay, subDays } from "date-fns";
import "rsuite/dist/rsuite.min.css";
import { ColDef } from "ag-grid-community";
import { useIsMount } from "../../../hooks";
import axios from "axios";
import { get } from "lodash";
import { getLocalStorageItem } from "../../../services/localStorage";
import { useNavigate } from "react-router-dom";

export const GridWrapper = styled(Box)(({ theme }) => ({}));

const FileDownload = require("js-file-download");
function OpportunityTable({ rowData }: any) {
  const gridOptions: any = useRef();
  const isMount = useIsMount();
  const dispatch = useAppDispatch();
  const [rowSize, setRowSize]: any = useState(10);
  const [download, setDownload] = useState(false);
  const [currentpage, setCurrentpage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalRowSize, setTotalRowSize]: any = useState(0);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [store, setStore] = useState<any>(null);
  const [storeValue, setStoreValue] = useState<any>(null);

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { afterToday, allowedMaxDays, combine, before }: any = DateRangePicker;
  const onGridReady = useCallback((params: any) => {
    gridOptions.current = params;
  }, []);
  let navigate = useNavigate();
  const [AnalyticsData, setAnalytics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>([
    moment().format(),
    moment().add(1, "day").format(),
  ]);
  const [stateValue, setStateValue] = useState<any>(null);
  const [cityValue, setCityValue] = useState<any>(null);
  const [updateData, setUpdateData] = useState(false);

  const cleanUp = () => {
    setTotalRowSize(0);
    setAnalytics([]);
  };

  useEffect(() => {
    if (totalRowSize > 0) {
      if (totalRowSize <= rowSize) {
        setPageCount(Math.ceil(totalRowSize / rowSize - 1));
      } else {
        setPageCount(Math.ceil(totalRowSize / rowSize));
      }
    }
  }, [rowSize, currentpage, isMount, totalRowSize]);
  // setPageCount(0);
  useEffect(() => {
    setLoading(true);
    let match;
    if (stateValue !== null && cityValue === null && storeValue !== null) {
      match = JSON.stringify({ state: stateValue, storeId: storeValue });
    } else if (
      stateValue !== null &&
      cityValue === null &&
      storeValue === null
    ) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && storeValue === null) {
      match = JSON.stringify({ city: cityValue });
    } else if (cityValue !== null && storeValue !== null) {
      match = JSON.stringify({ city: cityValue, storeId: storeValue });
    } else if (
      storeValue !== null &&
      cityValue === null &&
      stateValue === null
    ) {
      match = JSON.stringify({ storeId: storeValue });
    } else {
      match = JSON.stringify({});
    }

    API.fetchWithParams(
      API_CONSTANTS.GET_EXOTEL_CALLLOG,
      (res: any) => {
        setLoading(false);
        setAnalytics(_.get(res, "data.data.result", []));
        setTotalRowSize(_.get(res, "data.data.totalRecords", 0));
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status >= 400 && err.response.status < 500) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        fromDate: moment(selectedDate[0]).format("YYYY-MM-DD"),
        toDate: moment(selectedDate[1]).add(1, "day").format("YYYY-MM-DD"),
        pageNo: currentpage,
        size: rowSize,
        match: match,
      }
    );
  }, [
    stateValue,
    selectedDate,
    cityValue,
    storeValue,
    updateData,
    currentpage,
    rowSize,
  ]);

  const headerHeight = 60;
  const rowHeight = 40;
  const rowStyle = {
    borderTop: "white 8px solid",
  };
  const theme = useTheme();
  const columnDef: ColDef[] = [
    {
      field: "storeDetails.storeId",
      headerName: "Id",
      minWidth: 130,
      filter: "agSetColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "storeId",
      headerTooltip: "STORE ID",

      cellStyle: {
        lineHeight: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      },
    },
    {
      field: "storeDetails",
      headerName: "STORE DETAILS",
      minWidth: 320,
      filter: "agSetColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "storeDetails.storeName",
      headerTooltip: "STORE NAME",

      cellStyle: {
        display: "flex",
        alignItems: "start",
        justifyContent: "left",
        textAlign: "left",
        wordBreak: "normal",
      },
      cellRenderer: function (param: any) {
        const { data } = param;
        return `${get(data, "storeDetails.storeName", "")} ${get(
          data,
          "storeDetails.city",
          ""
        )},${get(data, "storeDetails.state", "")}`;
      },
    },
    {
      field: "callerNumber",
      headerName: "From",
      headerTooltip: "FROM",
      filter: "agSetColumnFilter",
      minWidth: 140,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      resizable: true,
      tooltipField: "From",
    },
    {
      field: "calledNumber",
      headerName: "To",
      filter: "agSetColumnFilter",
      minWidth: 130,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      resizable: true,
      headerTooltip: "T0",
    },
    {
      field: "status",
      filter: "agSetColumnFilter",
      sortable: true,
      headerName: "STATUS",
      minWidth: 150,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      resizable: true,
      headerTooltip: "status",
      cellRendererFramework: (props: any) => {
        const { data } = props;
        const status = _.get(data, "status", "");
        if (status === "NOT_ANSWERED") {
          return "UNANSWERED";
        }

        return status;
      },
    },
    {
      field: "startTime",
      sortable: true,
      sort: "desc",
      headerName: "START TIME",
      minWidth: 160,
      resizable: true,
      headerTooltip: "START TIME",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      cellRendererFramework: (props: any) => {
        const { data } = props;

        const date = _.get(data, "startTime", "");

        const secToMintues =
          date.substring(0, 10) + " " + date.substring(11, 19);
        return secToMintues;
      },
    },
    {
      field: "callDuration",
      sortable: true,
      minWidth: 150,
      resizable: true,
      headerName: "Duration",
      headerTooltip: "DURATION",

      cellRendererFramework: (props: any) => {
        const { data } = props;
        const date = _.get(data, "callDuration", "");
        const secToMintues = moment.utc(date * 1000).format("mm:ss");
        return secToMintues;
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
    },
    {
      field: "keyPressed",
      headerName: "Key",
      filter: "agSetColumnFilter",
      minWidth: 150,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      resizable: true,
      headerTooltip: "T0",
    },

    {
      field: "recordUrl",
      minWidth: 300,
      headerTooltip: "Record Url",
      cellRenderer: ({ value }: any) => {
        return (
          <audio controls>
            <source src={value} type="audio/ogg" />
          </audio>
        );
      },
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
    };
  }, []);
  const defaultColGroupDef = useMemo(() => {
    return {
      marryChildren: true,
    };
  }, []);
  const Token = getLocalStorageItem("token");
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const onDownload = (name: string) => {
    let match;
    if (stateValue !== null && cityValue === null && storeValue !== null) {
      match = JSON.stringify({ state: stateValue, storeId: storeValue });
    } else if (
      stateValue !== null &&
      cityValue === null &&
      storeValue === null
    ) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && storeValue === null) {
      match = JSON.stringify({ city: cityValue });
    } else if (cityValue !== null && storeValue !== null) {
      match = JSON.stringify({ city: cityValue, storeId: storeValue });
    } else if (
      storeValue !== null &&
      cityValue === null &&
      stateValue === null
    ) {
      match = JSON.stringify({ storeId: storeValue });
    } else {
      match = JSON.stringify({});
    }
    const path = `${process.env.REACT_APP_BACKEND_API_PROXY}${
      API_CONSTANTS.DOWNLOAD_CALL_LOG
    }?fromDate=${moment(selectedDate[0]).format("YYYY-MM-DD")}&toDate=${moment(
      selectedDate[1]
    )
      .add(1, "day")
      .format("YYYY-MM-DD")}&match=${match}`;
    setDownload(true);
    axios({
      url: path, //your url
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      method: "GET",
      responseType: "blob", // important
    })
      .then((res) => {
        setDownload(false);
        FileDownload(res.data, `Close_Bi_${name}.csv`);
      })
      .catch((error) => {
        setDownload(false);
        dispatch(
          openSnackbar({
            messege: get(error, "response.data.message", " Try again later"),
            severity: "error",
          })
        );
        console.error("download file error", error);
      });
  };
  const columnTypes = useMemo(() => {
    return {
      numberColumn: {
        width: 200,
        filter: "agNumberColumnFilter",
        flex: 5,
        marginLeft: 200,
      },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");

            var dateParts = dateAsString.split("/");

            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    let match;
    if (cityValue !== null && stateValue !== null) {
      match = JSON.stringify({ city: cityValue });
    } else if (stateValue !== null && cityValue === null) {
      match = JSON.stringify({ state: stateValue });
    } else {
      match = JSON.stringify({});
    }
    API.fetchWithParams(
      API_CONSTANTS.GET_STORE_DATA,
      (res: any) => {
        setLoading(false);
        setStore(res.data?.data);
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status <= 400 && err.response.status > 500) {
          localStorage.clear();
          // navigate('/login', { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
        match: match,
      }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_STATES,
      (res: any) => {
        setState(res?.data?.data);
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status <= 400 && err.response.status > 500) {
          localStorage.clear();

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
      }
    );
    if (stateValue !== null) {
      API.fetchWithParams(
        API_CONSTANTS.GET_CITIES,
        (res: any) => {
          setCity(res.data.data);
        },
        (err: any) => {
          setLoading(false);
          if (err.response.status <= 400 && err.response.status > 500) {
            localStorage.clear();
            // navigate('/login', { replace: true });

            dispatch(
              openSnackbar({
                messege: get(err, "", "Token Expired! Log In Again"),
                severity: "error",
              })
            );
          }
        },
        {
          brandId: "62d1157242a9f208b4388b8a",
          state: `${stateValue}`,
        }
      );
    }
  }, [stateValue, cityValue]);
  const handleClickAway = () => {
    setOpen(false);
  };

  let stateArray = state?.map((i: any) => {
    return { stateName: i };
  });

  const cityArray = city?.map((i: any) => {
    return { cityName: i };
  });

  return (
    <div>
      <Paper
        style={{
          padding: theme.spacing(2),
          background: "#FFFFFF",
          boxShadow: "3px 3px 3px 3px #DCDCDC",
        }}
        square
      >
        <Grid container spacing={2}>
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <Typography
              variant="h6"
              sx={{ mx: 1, my: 2 }}
              style={{
                fontWeight: 500,
                fontSize: "23px",
                color: "#000000",
                marginRight: "30px",
              }}
            >
              Store Wise analytics
            </Typography>
            <Typography
              sx={{ mx: 1, my: 2 }}
              style={{
                fontWeight: 400,
                fontSize: "15px",
                color: "#000000",
              }}
            >
              Total Records Found For This Search : {totalRowSize}
            </Typography>
          </Grid>

          <Grid
            item
            xs={1.5}
            sm={1.5}
            md={1.5}
            lg={1}
            xl={1}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button
              aria-describedby={id}
              style={{
                backgroundColor: "#A0D5EF",
                color: "black",
                boxShadow: "1px 1px 1px 1px #DCDCDC",
              }}
              onClick={handleClick}
              variant="contained"
              startIcon={<FilterAltIcon />}
            >
              Filter
            </Button>

            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              transition
              sx={{ width: "360px", marginTop: "20px" }}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Typography sx={{ p: 3 }}>
                        <p> Select Date</p>
                        <Grid
                          item
                          xs={7}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <DateRangePicker
                            placeholder="Select Date Range"
                            format="dd-MMM-yyyy"
                            disabledDate={combine(
                              allowedMaxDays(31),
                              combine(before("2022-02-17"), afterToday())
                            )}
                            // showOneCalendar
                            defaultValue={[
                              new Date(selectedDate[0]),
                              new Date(selectedDate[0]),
                            ]}
                            style={{ width: "700px" }}
                            disabled={loading}
                            onChange={(dates) => {
                              if (dates) {
                                setSelectedDate(dates);
                                cleanUp();
                              }
                            }}
                            ranges={
                              loading
                                ? []
                                : [
                                    {
                                      label: "Today",
                                      value: [
                                        startOfDay(new Date()),
                                        endOfDay(new Date()),
                                      ],
                                    },
                                    {
                                      label: "Yesterday",
                                      value: [
                                        startOfDay(subDays(new Date(), 1)),
                                        endOfDay(subDays(new Date(), 1)),
                                      ],
                                    },
                                    {
                                      label: " 7 Days",
                                      value: [
                                        startOfDay(subDays(new Date(), 6)),
                                        endOfDay(new Date()),
                                      ],
                                    },
                                    {
                                      label: " 14 Days",
                                      value: [
                                        startOfDay(subDays(new Date(), 13)),
                                        endOfDay(new Date()),
                                      ],
                                    },
                                    {
                                      label: " 28 Days",
                                      value: [
                                        startOfDay(subDays(new Date(), 27)),
                                        endOfDay(new Date()),
                                      ],
                                    },
                                  ]
                            }
                          />
                        </Grid>
                        <div style={{ marginTop: "20px" }}>
                          <Autocomplete
                            id="storeName"
                            style={{ width: "100%" }}
                            options={stateArray}
                            clearOnBlur={true}
                            getOptionLabel={(option: any) => option.stateName}
                            sx={{ width: 300 }}
                            value={
                              stateValue !== null
                                ? stateArray?.find(
                                    (item: any) => item.stateName === stateValue
                                  )
                                : null
                            }
                            placeholder="Select State"
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  style: {
                                    color: "green",
                                    fontSize: "20px",
                                    fontFamily: "Inter",
                                  },
                                }}
                                variant="standard"
                                placeholder="Select state"
                              />
                            )}
                            onChange={(event: any, newValue: any, reason) => {
                              if (reason === "clear") {
                                setCityValue(null);
                                setStateValue(null);
                                setStoreValue(null);
                                setCurrentpage(1);
                                cleanUp();
                                return;
                              } else {
                                setCityValue(null);
                                setStoreValue(null);
                                setStateValue(newValue.stateName);
                                setCurrentpage(1);
                                cleanUp();
                              }
                            }}
                          />
                        </div>
                        <div>
                          <div style={{ marginTop: "20px" }}>
                            <Autocomplete
                              id="storeName"
                              style={{ width: "100%" }}
                              options={cityArray}
                              getOptionLabel={(option: any) => option?.cityName}
                              clearOnBlur={true}
                              value={
                                cityValue !== null
                                  ? cityArray?.find(
                                      (item: any) => item.cityName === cityValue
                                    )
                                  : null
                              }
                              placeholder="Select City"
                              disabled={stateValue === null || !cityArray}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    style: {
                                      color: "green",
                                      fontSize: "20px",
                                      fontFamily: "Inter",
                                    },
                                  }}
                                  variant="standard"
                                  placeholder="Select City"
                                />
                              )}
                              onChange={(event: any, newValue: any, reason) => {
                                if (reason === "clear") {
                                  setCityValue(null); //for reset the value()
                                  setStoreValue(null);
                                  setCurrentpage(1);
                                  cleanUp();
                                  return;
                                } else {
                                  setCityValue(newValue.cityName);
                                  setStoreValue(null);
                                  setCurrentpage(1);
                                  cleanUp();
                                }
                              }}
                            />
                          </div>
                          <div style={{ marginTop: "20px" }}>
                            <Autocomplete
                              id="storeName"
                              style={{ width: "100%" }}
                              options={store}
                              getOptionLabel={(option: any) => option.storeName}
                              sx={{ width: 300 }}
                              value={
                                storeValue !== null
                                  ? store?.find(
                                      (item: any) => item.storeId === storeValue
                                    )
                                  : null
                              }
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.storeName} - {option.city}
                                </Box>
                              )}
                              placeholder="Select Store"
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    style: {
                                      color: "green",
                                      fontSize: "20px",
                                      fontFamily: "Inter",
                                    },
                                  }}
                                  variant="standard"
                                  placeholder="Select store"
                                />
                              )}
                              onChange={(event: any, newValue: any, reason) => {
                                if (reason === "clear") {
                                  setStoreValue(null);
                                  setCurrentpage(1);
                                  cleanUp();
                                  return;
                                } else {
                                  setStoreValue(newValue.storeId);
                                  setCurrentpage(1);
                                  cleanUp();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Typography>
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </Grid>

          <Grid
            item
            xs={1.5}
            sm={1.5}
            md={1.5}
            lg={1.5}
            xl={1.5}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button
              // sx={{ }}
              variant="contained"
              sx={{
                mx: 1,
                my: 2,
                backgroundColor: "#A0D5EF",
                color: "black",
                boxShadow: "1px 1px 1px 1px #DCDCDC",
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0",
                },
                "&:hover": {
                  backgroundColor: "#A0D5EF",
                },
              }}
              onClick={() => {
                onDownload(
                  `${moment(selectedDate[0]).format("YYYY-MM-DD")}_${moment(
                    selectedDate[1]
                  ).format("YYYY-MM-DD")}`
                );
              }}
              disabled={download || totalRowSize === 0}
            >
              {download ? (
                "Exporting .."
              ) : (
                <>
                  Export{" "}
                  <FileDownloadIcon
                    sx={{ marginLeft: "7px", fontSize: "22px" }}
                  />{" "}
                </>
              )}
            </Button>
          </Grid>
          <Grid
            item
            xs={0.4}
            sm={0.4}
            md={0.4}
            lg={0.4}
            xl={0.4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                backgroundColor: "#A0D5EF",
                color: "black",
                boxShadow: "1px 1px 1px 1px #DCDCDC",
              }}
            >
              <IconButton
                onClick={() => {
                  setUpdateData(true);
                }}
              >
                <Icon style={{ color: "black" }}>sync</Icon>
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
        <GridWrapper>
          <>
            <CustomGrid
              handleCellClick={undefined}
              loading={loading}
              onGridReady={onGridReady}
              suppressExcelExport={true}
              serverSidePagination={true}
              disableClickSelectionRenderers={false}
              columnDefs={columnDef}
              defaultColDef={defaultColDef}
              defaultColGroupDef={defaultColGroupDef}
              columnTypes={columnTypes}
              suppressMultiRangeSelection={true}
              noDataTxt="No Data..."
              rowData={AnalyticsData}
              TableHeight={80}
              serverPageCount={pageCount}
              totalDataCount={totalRowSize}
              currentPage={currentpage}
              setServerSidePage={(event: any, page: number) => {
                setCurrentpage(page);
              }}
              setServerRowSize={setRowSize}
              serverRowSize={rowSize}
              rowHeight={rowHeight}
              rowStyle={rowStyle}
              headerHeight={headerHeight}
            />
          </>
        </GridWrapper>
      </Paper>
    </div>
  );
}

export default OpportunityTable;
