import React from "react";
import {
  Grid,
  Typography,
  Skeleton,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/CardActions";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import VerticalBarChart from "../../components/VerticalBarChart";
import Paper from "@mui/material/Paper";
import * as _ from "lodash";
import OpportunityTable from "./OpportunityTable";
import { API_CONSTANTS } from "../../constants/index";
import API from "../../services/apis/api";
import { AxiosResponse } from "axios";
import { get, map, sortBy } from "lodash";
import { useAppDispatch } from "../../hooks";
import { openSnackbar } from "../../redux/features/snackBarSlice";

export const GraphWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  boxShadow: "3px 3px 3px 3px #DCDCDC",
  cursor: "pointer",
  width: "100%",
  borderRadius: 10,
}));
export const Heading = styled("div")(({ theme }) => ({
  marginBottom: 25,
}));
function Oppertunity() {
  const [dayWiseData, setDayWiseData] = useState<any>([]);
  const [quaterlyData, setQuaterlyData] = useState<any>([]);
  const [halfYearlyData, setHalfYearlyData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setLoading(true);
    API.fetchWithParams(
      API_CONSTANTS.GET_CALL_ANALYTICS_DAYS,
      (res: AxiosResponse) => {
        setLoading(false);
        const data = get(res, "data.data");

        setDayWiseData(
          sortBy(data, (o) => {
            return o._id;
          })
        );
      },
      (err: any) => {
        setLoading(false);

        // if (err.response.status >=400 || err.response.status <500) {
        //   localStorage.clear();
        //   navigate("/login", { replace: true });

        //   dispatch(
        //     openSnackbar({
        //       messege: get(err, "", "Token Expired! Log In Again"),
        //       severity: "error",
        //     })
        //   );
        // }
      },
      {
        fromDate: moment().subtract(6, "day").format("YYYY-MM-DD"),
        toDate: moment().add(1, "day").format("YYYY-MM-DD"),
      }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_CALL_ANALYTICS_MONTH,
      (res: AxiosResponse) => {
        setLoading(false);
        const data = get(res, "data.data");
        setQuaterlyData(
          sortBy(data, (o) => {
            return o._id;
          })
        );
      },
      (error: any) => {
        setLoading(false);
        if (error.response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(error, "response.data.message", " Token Expired"),
              severity: "error",
            })
          );
        }
      },
      {
        fromDate: moment()
          .subtract(3, "month")
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD"),
        toDate: moment().add(1, "day").format("YYYY-MM-DD"),
      }
    );

    API.fetchWithParams(
      API_CONSTANTS.GET_CALL_ANALYTICS_MONTH,
      (res: AxiosResponse) => {
        setLoading(false);
        const data = get(res, "data.data");
        setHalfYearlyData(
          sortBy(data, (o) => {
            return o._id;
          })
        );
      },
      (error: any) => {
        setLoading(false);
        if (error.response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(error, "response.data.message", " Token Expired"),
              severity: "error",
            })
          );
        }
      },
      {
        fromDate: moment()
          .subtract(6, "month")
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD"),
        toDate: moment().add(1, "day").format("YYYY-MM-DD"),
      }
    );
  }, []);
  const getLabelsforGraph = (data: any) => {
    return map(data, (o) => {
      return moment(o._id, "YYYY-MM").format("MMMM-YYYY");
    });
  };
  const getLabelsforDayGraph = (data: any) => {
    return map(data, (o) => {
      return moment(o._id, "YYYY-MM-DD").format("DD-MM-YYYY");
    });
  };
  const getDataForGraph = (data: any, label: string) => {
    return map(data, (o) => {
      return o[label];
    });
  };
  return (
    <div>
      <Box>
        <Backdrop
          sx={{ color: "#fffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress sx={{ color: "green" }} />
        </Backdrop>
      </Box>
      <Grid container sx={{ flexGrow: 1 }} spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Heading>
            <Typography variant="h4" style={{ fontSize: "23px" }}>
              Call Log
            </Typography>
          </Heading>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Typography
            variant="h5"
            style={{
              fontSize: "13px",
              color: "red",
              textAlign: "right",
              marginTop: "20px",
            }}
            sx={{ my: 2, mx: 1 }}
          >
            *Unanswered Calls are not considered for Graph
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Card sx={{ width: "100%" }}>
            <GraphWrapper>
              <Box sx={{ width: "100%", height: "240px" }}>
                {getLabelsforDayGraph(dayWiseData).length > 0 ? (
                  <VerticalBarChart
                    title={` 7 Days Call Status  (${moment(new Date())
                      .subtract(6, "days")
                      .format("DD MMM YYYY")}-${moment(new Date()).format(
                      " DD MMM YYYY"
                    )})`}
                    data={{
                      labels: getLabelsforDayGraph(dayWiseData),
                      datasets: [
                        {
                          label: "Answered Call",
                          data:
                            getDataForGraph(dayWiseData, "answered").length < 0
                              ? [0, 0, 0, 0]
                              : getDataForGraph(dayWiseData, "answeredCall"),
                          barPercentage: 0.7,
                          backgroundColor: "#78F288",
                        },
                        {
                          label: "Missed Call",
                          data: getDataForGraph(dayWiseData, "missedCall"),
                          barPercentage: 0.7,
                          backgroundColor: "#F55B5B",
                        },
                        {
                          label: "Initiated Call",
                          data: getDataForGraph(dayWiseData, "Initidate"),
                          barPercentage: 0.7,
                          backgroundColor: "#A9A9A9",
                        },
                      ],
                    }}
                  />
                ) : (
                  <Box>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton
                      sx={{ height: 150 }}
                      animation="wave"
                      variant="rectangular"
                    />
                  </Box>
                )}
              </Box>
            </GraphWrapper>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Card sx={{ width: "100%" }}>
            <GraphWrapper>
              <Box sx={{ width: "100%", height: "240px" }}>
                {getLabelsforGraph(quaterlyData).length > 0 ? (
                  <VerticalBarChart
                    title={` 3 Month Call Status `}
                    data={{
                      labels: getLabelsforGraph(quaterlyData),
                      datasets: [
                        {
                          label: "Answered Call",
                          data:
                            getDataForGraph(quaterlyData, "answeredCall")
                              .length < 0
                              ? [0, 0, 0]
                              : getDataForGraph(quaterlyData, "answeredCall"),
                          barPercentage: 0.7,
                          backgroundColor: "#78F288",
                        },
                        {
                          label: "Missed Call",
                          data:
                            getDataForGraph(quaterlyData, "missedCall").length <
                            0
                              ? [0, 0, 0, 0]
                              : getDataForGraph(quaterlyData, "missedCall"),
                          barPercentage: 0.7,
                          backgroundColor: "#F55B5B",
                        },
                        {
                          label: "Initiated Call",
                          data:
                            getDataForGraph(quaterlyData, "Initidate").length <
                            0
                              ? [0, 0, 0, 0]
                              : getDataForGraph(quaterlyData, "Initidate"),
                          barPercentage: 0.7,
                          backgroundColor: "#A9A9A9",
                        },
                      ],
                    }}
                  />
                ) : (
                  <Box>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton
                      sx={{ height: 150 }}
                      animation="wave"
                      variant="rectangular"
                    />
                  </Box>
                )}
              </Box>
            </GraphWrapper>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card sx={{ width: "100%" }}>
            <GraphWrapper>
              <Box sx={{ width: "97%", height: "300px" }}>
                {getLabelsforGraph(halfYearlyData).length > 0 ? (
                  <VerticalBarChart
                    title={` 6 Month Call Status  `}
                    data={{
                      labels: getLabelsforGraph(halfYearlyData),
                      datasets: [
                        {
                          label: "Answered Call",
                          data:
                            getDataForGraph(halfYearlyData, "answeredCall")
                              .length < 0
                              ? [0, 0, 0]
                              : getDataForGraph(halfYearlyData, "answeredCall"),
                          barPercentage: 0.7,
                          backgroundColor: "#78F288",
                        },
                        {
                          label: "Missed Call",
                          data:
                            getDataForGraph(halfYearlyData, "missedCall")
                              .length < 0
                              ? [0, 0, 0, 0]
                              : getDataForGraph(halfYearlyData, "missedCall"),
                          barPercentage: 0.7,
                          backgroundColor: "#F55B5B",
                        },
                        {
                          label: "Initiated Call",
                          data:
                            getDataForGraph(halfYearlyData, "Initidate")
                              .length < 0
                              ? [0, 0, 0, 0]
                              : getDataForGraph(halfYearlyData, "Initidate"),
                          barPercentage: 0.7,
                          backgroundColor: "#A9A9A9",
                        },
                      ],
                    }}
                  />
                ) : (
                  <Box>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton
                      sx={{ height: 150 }}
                      animation="wave"
                      variant="rectangular"
                    />
                  </Box>
                )}
              </Box>
            </GraphWrapper>
          </Card>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "30px", flexGrow: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OpportunityTable />
        </Grid>
      </Grid>
    </div>
  );
}

export default Oppertunity;
