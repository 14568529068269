/* eslint-disable import/no-named-as-default */
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/authSlice";
import snackBarSlice from "./features/snackBarSlice";
import userSlice from "./features/userSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    snackbar: snackBarSlice,
    user: userSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
