import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import { CategoryScale, LinearScale, registerables } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, ...registerables);
function BarChat(props: any) {
  const { data, title } = props;
  const options: any = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: title,
      },
      tooltip: {
        callbacks: {
          beforeTitle: function () {
            return title;
          },
        },
      },
      legend: {
        position: "top",
        display: false,
      },
    },
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    scales: {
      yAxes: {
        ticks: {
          callback: function (datas: any) {
            if (datas < 1000) {
              return datas;
            } else if (datas < 900000) {
              return datas / 1000 + "k";
            }
            return datas / 1000000 + "M";
          },
        },
      },
    },
  };
  return <Bar data={data} options={options} />;
}

export default BarChat;
