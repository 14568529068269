import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import StreetviewIcon from "@mui/icons-material/Streetview";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

interface IProps {
  data: any;
  handleDelete: any;
}

const DropdownWithActionButton = (props: IProps) => {
  const { data, handleDelete } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        style={{
          backgroundColor: "#A0D5EF",
          color: "black",
          boxShadow: "1px 1px 1px 1px #DCDCDC",
          borderRadius: "200px",
        }}
        onClick={handleClick}
        variant="contained"
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            navigate("/admin/articleForm", {
              state: {
                isEdit: true,
                data: data,
              },
            });
          }}
        >
          <EditIcon style={{ fontSize: "16px", color: "green" }} />
          <p style={{ paddingLeft: "10px" }}>Edit</p>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/admin/articleForm", {
              state: {
                isView: true,
                data: data,
              },
            });
          }}
        >
          <StreetviewIcon style={{ fontSize: "16px" }} />
          <p style={{ paddingLeft: "10px" }}>View</p>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleDelete(data);
          }}
        >
          <DeleteOutlineIcon style={{ fontSize: "18px", color: "red" }} />
          <p style={{ paddingLeft: "10px" }}>Delete</p>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DropdownWithActionButton;
