import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { getLocalStorageItem } from "../../services/localStorage";
import { setLocalStorageItem } from "../../services/localStorage";
import api from "../../services/apis/api";
import { AxiosError, AxiosResponse } from "axios";
import { useAppDispatch } from "../../hooks";
import { openSnackbar } from "../../redux/features/snackBarSlice";
import { get } from "lodash";
import { getCurrentUser } from "../../redux/features/userSlice";
import logo from "../../assets/logonew.svg";
import sideImg from "../../assets/closebi.svg";
// import logo from '../../assets/closebi1.png';
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormControlLabel, FormGroup, IconButton } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#181C32",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#181C32",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#181C32",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#181C32",
    },
  },
});

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        CloseBi
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function SignIn() {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const from= location.state?.from?.pathname || "/";
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);

    if (data.get("email") && data.get("password")) {
      const values = {
        emailId: data.get("email"),
        password: data.get("password"),
      };
      api.login(
        (res: AxiosResponse) => {
          setLoading(false);
          const userName = res.data;

          dispatch(
            getCurrentUser({
              userDetails: userName,
            })
          );
          setLocalStorageItem("role", JSON.stringify(res.data.data.role)).then(
            () => {
              navigate(`/${res.data.data.role}/callLog`);
            }
          );
          setLocalStorageItem("user", res.data.data.firstName);
          setLocalStorageItem("token", res.data.token);
        },
        (error: AxiosError) => {
          console.error(error);
          setLoading(false);
          dispatch(
            openSnackbar({
              messege: get(error, "response.data.message", " Try again later"),
              severity: "error",
            })
          );
        },
        values
      );
    }
  };
  useEffect(() => {
    let res = getLocalStorageItem("role");
    if (res && getLocalStorageItem("user")) {
      const values = JSON.parse(res);
      navigate(`/${values}/callLog`);
    }
  });
  // const handleRegister = () => {
  //   navigate("/pages/register");
  // };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleChange = (e: any) => {
    setPassword(e.target.value);
    setUser(e.target.value);
  };

  return (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        md={6}
        lg={6}
        xl={6}
        sm={12}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <img
          src={logo}
          // width={150}
          height={120}
          // style={{ marginLeft: '70px' }}
        ></img>
        <div style={{ marginLeft: "60px", marginRight: "60px" }}>
          {/* <div> */}
          <Typography
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: "27px",
            }}
            variant="h4"
          >
            Login
          </Typography>
          <Typography style={{ fontSize: "14px", marginTop: "10px" }}>
            See your growth and get consulting support!
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 1, color: "#181C32", marginTop: "25px" }}
          >
            <label style={{ fontWeight: 500 }}>Email*</label>
            <CssTextField
              margin="normal"
              required
              // fullWidth
              style={{ width: "100%" }}
              id="email"
              variant="outlined"
              name="email"
              autoComplete="email"
              onChange={(e) => handleChange(e)}
              autoFocus
            />
            <label style={{ fontWeight: 500 }}>Password*</label>
            <CssTextField
              margin="normal"
              variant="outlined"
              required
              // fullWidth
              style={{ width: "100%" }}
              name="password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleChange(e)}
            />
            <div>
              <Grid container>
                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Remember me"
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "right", marginTop: "10px" }}
                >
                  <Typography>Forget Password?</Typography>
                </Grid>
              </Grid>
            </div>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                disabled={!password || !user || loading}
                color="primary"
                style={{
                  height: "40px",
                  backgroundColor: "#61A734",
                  width: "200px",
                  color: "white",
                }}
                sx={{
                  mt: 3,
                  mb: 2,
                  "&.MuiButton-colorInherit": {
                    backgroundColor: "#61A734",
                    color: "white",
                  },
                }}
              >
                {!loading ? "Login" : "Please Wait..."}
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 1, mb: 1 }} />
        </div>
      </Grid>
      <Grid item xs={0} md={6} sm={0} lg={6} xl={6}>
        <div
          style={{
            height: "100Vh",
            backgroundImage: `url(${sideImg})`,
            backgroundSize: "cover",
          }}
        ></div>
      </Grid>
    </Grid>
  );
}
