import { IdrawerOptions } from "../components/CustomDrawer";

// import AnalyticsIcon from '@mui/icons-material/Analytics';

export const drawerOptions: IdrawerOptions[] = [
  {
    label: "Call Log",
    path: "/user/calllog",
    icon: "phone_in_talk",
  },
  {
    label: "Discovery",
    path: "/user/dashBoard",
    icon: "person_search_sharp",
  },
  {
    label: "Action",
    path: "/user/action",
    icon: "equalizerIcon",
  },
  {
    label: "KeywordsData-Upload",
    path: "/user/keywordupload",
    icon: "abc",
  },
  {
    label: "Generated Report",
    path: "/user/generatereport",
    icon: "printIcon",
  },
  {
    label: "Articles",
    path: "/user/articles",
    icon: "descriptionIcon",
  },
  {
    label: "Wati",
    path: "/admin/wati",
    icon: "WhatsAppIcon",
  },
];
