import CustomGrid from "../../../components/CustomGrid";
import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import * as _ from "lodash";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { API_CONSTANTS } from "../../../constants";
import API from "../../../services/apis/api";
import "../../../App.css";
//Date Range Filter
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks";
import { openSnackbar } from "../../../redux/features/snackBarSlice";
import { get } from "lodash";
import { getLocalStorageItem } from "../../../services/localStorage";

//Date Range Filter
export const GridWrapper = styled(Box)(({ theme }) => ({}));
function HomeTable({ stateValue, cityValue, selectedDate, storeValue }: any) {
  const [actionDatas, SetActionDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentpage, setCurrentpage] = useState(1);
  const [rowSize, setRowSize]: any = useState(10);
  // const [selectedDate, setSelectedDate] = useState<any>([]);
  const [totalRowSize, setTotalRowSize]: any = useState(10);

  const [dates] = useState({ fromDate: "", toDate: "" });
  const [updateData] = useState(true);
  const [state, setState] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const headerHeight = 50;
  const rowHeight = 60;
  const rowStyle = {
    // borderBottom: 'white 8px solid',
    borderTop: "white 8px solid",
  };

  useEffect(() => {
    if (totalRowSize > 0) {
      if (totalRowSize < rowSize) {
        setPageCount(Math.ceil(totalRowSize / rowSize - 1));
      } else {
        setPageCount(Math.ceil(totalRowSize / rowSize));
      }
    }
  }, [
    storeValue,
    dates.toDate,
    rowSize,
    currentpage,
    totalRowSize,
    selectedDate,
    pageCount,
  ]);

  useEffect(() => {
    API.fetchWithParams(
      API_CONSTANTS.GET_AVALILABLE_DATES,
      (res: any) => {
        setLoading(false);
      },
      (err: any) => {
        setLoading(false);
      },
      {}
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    let match;
    if (stateValue !== null && cityValue === null && storeValue !== null) {
      match = JSON.stringify({ state: stateValue, storeId: storeValue });
    } else if (
      stateValue !== null &&
      cityValue === null &&
      storeValue === null
    ) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && storeValue === null) {
      match = JSON.stringify({ city: cityValue });
    } else if (cityValue !== null && storeValue !== null) {
      match = JSON.stringify({ city: cityValue, storeId: storeValue });
    } else if (
      storeValue !== null &&
      cityValue === null &&
      stateValue === null
    ) {
      match = JSON.stringify({ storeId: storeValue });
    } else {
      match = JSON.stringify({});
    }

    API.fetchWithParams(
      API_CONSTANTS.GET_ACTION_DATA,
      (res: any) => {
        setLoading(false);
        SetActionDatas(_.get(res, "data.data.result", []));
        setTotalRowSize(_.get(res, "data.data.totalRecords", 0));
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status >= 400 && err.response.status < 500) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        fromDate: moment(selectedDate[0]).format("YYYY-MM-DD"),
        toDate: moment(selectedDate[1]).add(1, "day").format("YYYY-MM-DD"),
        pageNo: currentpage,
        size: rowSize,
        match: match,
      }
    );
  }, [
    stateValue,
    selectedDate,
    cityValue,
    storeValue,
    updateData,
    totalRowSize,
    currentpage,
  ]);

  const theme = useTheme();
  const columnDef = [
    {
      field: "Store code",
      headerTooltip: "Store code",
      minWidth: 80,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filter: "agSetColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "Store code",
    },
    {
      field: "GoogleSearchMobile",
      headerTooltip: "GoogleSearchMobile",
      width: 80,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filter: "agSetColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "GoogleSearchMobile",
    },
    {
      field: "GoogleMapsMobile",
      headerTooltip: "GoogleMapsMobile",
      width: 50,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filter: "agSetColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "GoogleMapsMobile",
    },
    {
      field: "Calls",
      headerTooltip: "Calls",
      width: 50,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filter: "agSetColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "Calls",
    },
    {
      field: "Date",
      headerTooltip: "Date",
      width: 50,
      resizable: true,
      sortable: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      cellRendererFramework: (props: any) => {
        const { data } = props;
        const date = _.get(data, "Date", "");
        const secToMintues = moment(date).format("DD/MMM/YYYY");
        return secToMintues;
      },
      tooltipField: "Discovery searches",
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      flex: 1,
      resizable: true,
    };
  }, []);
  const defaultColGroupDef = useMemo(() => {
    return {
      marryChildren: true,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: {
        width: 100,
        filter: "agNumberColumnFilter",
        flex: 5,
        marginLeft: 0,
      },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");

            var dateParts = dateAsString.split("/");

            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]),
              Number(dateParts[0])
            );

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
    };
  }, []);

  const gridOptions: any = useRef();
  const onGridReady = useCallback((params: any) => {
    gridOptions.current = params;
  }, []);

  useEffect(() => {
    setLoading(true);
    let match;
    if (stateValue !== null && cityValue === null) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && stateValue !== null) {
      match = JSON.stringify({ city: cityValue });
    } else {
      match = JSON.stringify({});
    }
    API.fetchWithParams(
      API_CONSTANTS.GET_STORE_DATA,
      (res: any) => {
        setLoading(false);
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status <= 400 && err.response.status >= 500) {
          localStorage.clear();
          // navigate('/login', { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
        match: match,
      }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_STATES,
      (res: any) => {
        setState(res?.data?.data);
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status <= 400 && err.response.status > 500) {
          localStorage.clear();

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
      }
    );
    if (stateValue !== null) {
      API.fetchWithParams(
        API_CONSTANTS.GET_CITIES,
        (res: any) => {
          setCity(res.data.data);
        },
        (err: any) => {
          setLoading(false);
          if (err.response.status <= 400 && err.response.status > 500) {
            localStorage.clear();
            // navigate('/login', { replace: true });

            dispatch(
              openSnackbar({
                messege: get(err, "", "Token Expired! Log In Again"),
                severity: "error",
              })
            );
          }
        },
        {
          brandId: "62d1157242a9f208b4388b8a",
          state: `${stateValue}`,
        }
      );
    }
  }, [stateValue, cityValue]);
  let stateArray = state?.map((i: any) => {
    return { stateName: i };
  });

  const cityArray = city?.map((i: any) => {
    return { cityName: i };
  });
  return (
    <div>
      <Paper
        style={{
          padding: theme.spacing(2),
          background: "#FFFFFF",
          boxShadow: "3px 3px 3px 3px #DCDCDC",
        }}
        square
      >
        <Grid container spacing={2}>
          <Grid item xs={3} sm={5} md={5} lg={6} xl={6}>
            <Typography
              variant="h6"
              sx={{ mx: 1, my: 2 }}
              style={{
                fontWeight: 500,
                fontSize: "23px",
                color: "#000000",
              }}
            >
              Store Wise analytics
            </Typography>
            <Typography
              sx={{ mx: 1, my: 2 }}
              style={{
                fontWeight: 400,
                fontSize: "15px",
                color: "#000000",
              }}
            >
              Total Records Found For This Search : {totalRowSize}
            </Typography>
          </Grid>
        </Grid>
        <GridWrapper>
          <CustomGrid
            handleCellClick={undefined}
            loading={loading}
            onGridReady={onGridReady}
            suppressExcelExport={true}
            serverSidePagination={true}
            disableClickSelectionRenderers={false}
            columnDefs={columnDef}
            defaultColDef={defaultColDef}
            defaultColGroupDef={defaultColGroupDef}
            columnTypes={columnTypes}
            suppressMultiRangeSelection={true}
            noDataTxt="No Data..."
            rowData={actionDatas}
            TableHeight={80}
            serverPageCount={pageCount}
            totalDataCount={totalRowSize}
            currentPage={currentpage}
            setServerSidePage={(event: any, page: number) => {
              setCurrentpage(page);
            }}
            setServerRowSize={setRowSize}
            serverRowSize={rowSize}
            rowHeight={rowHeight}
            rowStyle={rowStyle}
            headerHeight={headerHeight}
          />
        </GridWrapper>
      </Paper>
    </div>
  );
}

export default HomeTable;
