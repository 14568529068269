import * as React from "react";
import {
  Drawer as MuiDrawer,
  DrawerProps,
  Typography,
  ListItemButton,
  ListItemIcon,
  Icon,
  Box,
  Tooltip,
} from "@mui/material";

import logo from "../../assets/logonew.svg";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { drawerWidth } from "../../constants";
import { Link } from "react-router-dom";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export const DrawerBody = styled("div")(({ theme }) => ({
  marginTop: "10px",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export const DrawerIcon = styled("div")(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
    color: theme.palette.almostBlack,
  },
}));
export const DrawerItem = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  marginTop: "10px",
  "&:hover": {
    cursor: "pointer",
    fontWeight: 900,
    backgroundColor: theme.palette.primary.light,
  },
}));
export const DrawerLabel = styled("div")(({ theme }) => ({
  color: "black",
  fontSize: "16px",
  "&:hover": {
    cursor: "pointer",
  },
}));
export const Heading = styled(Typography)(({ theme }) => ({}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  border: "none",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export interface IdrawerOptions {
  label: string;
  path: string;
  icon?: string;
}
interface IProps extends DrawerProps {
  options: IdrawerOptions[];
  toggleDrawer: any;
}

export default function CustomDrawer(props: IProps) {
  const { options, open } = props;

  const theme = useTheme();
  const location = useLocation();

  return (
    <>
      <Box>
        <Drawer variant="permanent" anchor="left" open={open}>
          <DrawerHeader>
            <Box
              component="div"
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                textAlign: "center",
              }}
              style={{ color: "black" }}
            >
              <img src={logo} height={90} width={100} alt="logo" />
            </Box>
          </DrawerHeader>

          <DrawerBody>
            {_.map(
              options,
              ({ label, path, icon }: IdrawerOptions, idx: any) => (
                <span key={idx}>
                  <Link to={path} style={{ textDecoration: "none" }}>
                    <DrawerItem>
                      <ListItemButton
                        selected={path === location.pathname}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: theme.palette.primary.light,
                          },
                        }}
                      >
                        {icon && (
                          <Tooltip
                            title={label}
                            componentsProps={{
                              tooltip: {
                                // sx: {
                                //   bgcolor: 'common.black',
                                //   // color: 'black',
                                // },
                              },
                            }}
                          >
                            <ListItemIcon>
                              <Icon>
                                <DrawerIcon
                                  style={{ color: theme.palette.primary.dark }}
                                >
                                  {icon}
                                </DrawerIcon>
                              </Icon>
                            </ListItemIcon>
                          </Tooltip>
                        )}
                        <DrawerLabel>{label}</DrawerLabel>
                      </ListItemButton>
                    </DrawerItem>
                  </Link>
                </span>
              )
            )}
          </DrawerBody>
        </Drawer>
      </Box>
    </>
  );
}
