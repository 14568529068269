import { Card, CardContent, Box, Typography, Grid } from "@mui/material";
import moment from "moment";
import { keys, map } from "lodash";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

interface Iprops {
  data: any;
  endDate: any;
}
const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "310px",
  display: "flex",
  padding: 0,
  marginTop: "8px",
  "&:last-child": {
    paddingBottom: 0,
  },
  borderRadius: "10px",
  boxShadow: "3px 3px 3px 3px #DCDCDC",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));
const useStyles = makeStyles({
  card: {
    flexGrow: 1,
    margin: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  header: {
    textAlign: "center",
    justifyContent: "center",
    fontSize: "20px",
  },
  ruler: {
    borderBottom: "1px dashed #999",
    textDecoration: "none",
  },
  cardContent: {
    flexGrow: 1,
    margin: "2px",
  },
  gridContainer: {
    display: "flex",
    marginTop: "8px",
  },
});

const Content = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
    marginLeft: "250px",
  },
}));
const CustomDetailCard = ({ data, endDate }: Iprops) => {
  const keylist = keys(data).slice(1);
  const classes = useStyles();
  const theme = useTheme();
  //const totalSearch:any=Math.round()
  return (
    <StyledCard>
      <CardContent
        className={classes.card}
        sx={{
          "&.MuiCardContent-root": {
            "&:last-child": {
              paddingBottom: 2,
            },
            "&:first-child": {
              paddingTop: 2,
            },
          },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "center",
            justifyContent: "center",
            fontSize: "17px",
          }}
        >
          <p>Google Search Mobile</p>
          {endDate &&
            moment(new Date(endDate))
              .subtract(180, "days")
              .format("DD MMM YYYY")}{" "}
          <span> - </span>
          {moment(new Date(endDate)).format("DD MMM YYYY")}
          <hr className={classes.ruler} />
        </Typography>
        <Content>
          <Box className={classes.cardContent}>
            {map(keylist, (value, idx) => {
              return (
                <Box className={classes.gridContainer}>
                  <Grid
                    container
                    columnSpacing={{ xs: 4, sm: 4, md: 4, lg: 4 }}
                  >
                    <Grid item md={6} sx={{ textAlign: "left" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          width: theme.spacing(25),
                          fontSize: 18,
                        }}
                      >
                        {value}
                      </Typography>
                    </Grid>
                    <Grid item md={6} sx={{ textAlign: "right" }}>
                      <Typography
                        sx={{
                          width: theme.spacing(10),
                          fontSize: 18,
                        }}
                      >
                        {data[value] < 1000
                          ? data[value]
                          : (data[value] / 1000).toFixed(2) + "k"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Box>
        </Content>
      </CardContent>
    </StyledCard>
  );
};

export default CustomDetailCard;
