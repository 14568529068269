import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import barArrow from "../../../assets/barArrow.png";
import barLine from "../../../assets/barLine.png";
import barUpward from "../../../assets/barUpward.png";
import { Skeleton } from "@mui/material";
interface Iprops {
  direct: any;
  discovery: any;
  total: any;
}
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  "&:hover": {
    boxShadow: "3px 3px 3px 3px #DCDCDC",
    cursor: "pointer",
    width: "100%",
  },
}));

const useStyles = makeStyles({
  root: {
    margin: "auto",
    padding: "auto",
  },
  card: {
    marginBottom: "15px",
    position: "relative",
    minHeight: 150,
  },
  content: {
    top: "35px",
    left: "70px",
    position: "absolute",
  },
  header: {
    float: "right",
    textAlign: "right",
    top: 20,
    position: "absolute",
    PaddingTop: "20px",
    paddingRight: "15px",
    right: "8px",
    color: "black",
  },
  date: {
    float: "right",
    textAlign: "right",
    top: 45,
    position: "absolute",
    PaddingTop: "20px",
    paddingRight: "15px",
    right: "8px",
    color: "black",
  },
  value: {
    float: "right",
    textAlign: "right",
    top: 70,
    position: "absolute",
    PaddingTop: "20px",
    paddingRight: "15px",
    right: "8px",
  },
  percentage: {
    top: "15px",
    left: "90px",
    position: "absolute",
  },
});
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: "15px",
  position: "relative",
  minHeight: 130,
  borderRadius: "10px",
}));

const AdminCardSection = ({ direct, discovery, total }: Iprops) => {
  const directSearch: number = direct["6 months"];
  const discoverySearch: number = discovery["6 months"];
  const totalSearch: number = total["6 months"];
  const classes = useStyles();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          {totalSearch > 0 ? (
            <Item className={classes.root} elevation={8}>
              <StyledCard
                sx={{
                  backgroundColor: "#f7f2f4",
                  border: "1px solid #F1416C",
                }}
              >
                <CardContent>
                  <Box>
                    {/* <span className={classes.percentage}>
                    <p style={{ color: " #F1416C" }}>{totalNumber}%</p>
                  </span> */}
                    <span className={classes.content}>
                      <img
                        src={barUpward}
                        height={50}
                        width={70}
                        alt="barchart"
                      />
                    </span>
                    <Box component="span">
                      <Typography
                        gutterBottom
                        variant="h6"
                        className={classes.header}
                      >
                        Google Search Mobile
                      </Typography>
                      <p className={classes.date}>
                        {/* {moment(new Date())
                        .subtract(6, "months")
                        .format("MMM YYYY")}{" "}
                      <span> - </span>
                      {moment(new Date()).format(" MMM YYYY")} */}
                        Last 6 Months
                      </p>

                      <Typography
                        gutterBottom
                        variant="h6"
                        className={classes.value}
                      >
                        {totalSearch}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </StyledCard>
            </Item>
          ) : (
            <Box>
              <Skeleton
                sx={{ height: 128, borderRadius: "10px" }}
                animation="wave"
                variant="rectangular"
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          {discoverySearch > 0 ? (
            <Item className={classes.root} elevation={8}>
              <StyledCard
                sx={{
                  backgroundColor: "#EDF9FF",
                  border: "1px solid #51BEFA",
                }}
              >
                <CardContent>
                  <Box>
                    {/* <span className={classes.percentage}>
                    <p style={{ color: "#51BEFA" }}>{discoveryNumber}%</p>
                  </span> */}
                    <span className={classes.content}>
                      <img
                        src={barArrow}
                        height={50}
                        width={70}
                        alt="barchart"
                      />
                    </span>
                    <Box component="span">
                      <Typography
                        gutterBottom
                        variant="h6"
                        className={classes.header}
                      >
                        Google Maps Mobile
                      </Typography>
                      <p className={classes.date}>Last 6 Months</p>

                      <Typography
                        gutterBottom
                        variant="h6"
                        className={classes.value}
                      >
                        {discoverySearch}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </StyledCard>
            </Item>
          ) : (
            <Box>
              <Skeleton
                sx={{ height: 128, borderRadius: "10px" }}
                animation="wave"
                variant="rectangular"
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          {directSearch ? (
            <Item className={classes.root} elevation={8}>
              <StyledCard
                sx={{
                  backgroundColor: "#FFF8DD",
                  border: "1px solid #FFC700",
                }}
              >
                <CardContent>
                  <Box>
                    {/* <span className={classes.percentage}>
                    <p style={{ color: "#FFC700" }}>{directNumber}%</p>
                  </span> */}
                    <span className={classes.content}>
                      <img
                        src={barLine}
                        height={50}
                        width={70}
                        alt="barchart"
                      />
                    </span>
                    <Box component="span">
                      <Typography
                        gutterBottom
                        variant="h6"
                        className={classes.header}
                      >
                        Calls
                      </Typography>
                      <p className={classes.date}>Last 6 Months</p>

                      <Typography
                        gutterBottom
                        variant="h6"
                        className={classes.value}
                      >
                        {directSearch}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </StyledCard>
            </Item>
          ) : (
            <Box>
              <Skeleton
                sx={{ height: 128, borderRadius: "10px" }}
                animation="wave"
                variant="rectangular"
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default AdminCardSection;
