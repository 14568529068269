import moment from "moment";
export const ACTION_TYPES = {
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  HIDE_SNACKBAR: "HIDE_SNACKBAR",
};
export const drawerWidth = 249;
export const API_CONSTANTS = {
  UPDATE_PAGEONFLY_CONTENT: "/api/dynamicPages/content",
  GET_ANALYTICS: "/api/analytics/getAnalytics",
  UPLOAD_ANALYTICS: "/api/analytics/uploadAnalyticsSheets",
  GET_ANALYTICS_GRAPH: "/api/analytics/getAnalyticsGraph",
  GET_ANALYTICS_FOR_CALLLOG: "/api/analytics/callLog/getAnalyticsForCallLog",
  DOWNLOAD_KEYWORDS: "/api/search/downloadInRange",
  GET_EXOTEL_CALLLOG: "/api/analytics/callLog/getExotelCallLog",
  GET_ACTION_DATA: "/api/analytics/getAnalyticsInRange",
  GET_SEARCH_DATA: "/api/search/getInRange",
  GET_ALL_STORES_FOR_ARTICLES: "/api/store/getallstores",
  // GET_ACTION_DATA:'/api/analytics/getAnalyticsInRange',
  POST_UPLOAD: "/api/analytics/uploadAnalyticsSheets",
  POST_UPLOADSEARCHLOGS: "/api/search/uploadSearchLogs",
  POST_CALLLOG: "/api/analytics/callLog/uploadAnalyticsCallLog",
  GET_WEEKLY_DATA: `/api/analytics/callLog/getExotelCallLog?fromDate=${moment()
    .subtract(7, "days")
    .format("YYYY-MM-DD")}&toDate=${moment()
    .add(1, "days")
    .format("YYYY-MM-DD")}`,
  GET_THREEMONTHS_DATA: `/api/analytics/callLog/getExotelCallLog?fromDate=${moment()
    .subtract(90, "days")
    .format("YYYY-MM-DD")}&toDate=${moment()
    .add(1, "days")
    .format("YYYY-MM-DD")}`,
  GET_SIXMONTHS_DATA: `/api/analytics/callLog/getExotelCallLog?fromDate=${moment()
    .subtract(181, "days")
    .format("YYYY-MM-DD")}&toDate=${moment()
    .add(1, "days")
    .format("YYYY-MM-DD")}`,
  GET_CALL_ANALYTICS_MONTH: `/api/analytics/callLog/getAnalyticsMonthWise`,
  GET_CALL_ANALYTICS_DAYS: "/api/analytics/callLog/getAnalyticsDayWise",
  DOWNLOAD_CALL_LOG: "/api/analytics/callLog/downloadExotelCallLog",
  GET_STORE_DATA: "/api/store/getStoreWithBasicDetails",
  DOWNLOAD_ANALYTICS_IN_RANGE: "/api/analytics/downloadAnalyticsInRange",
  DELETE_ANALYTICS_INRANGE: "/api/analytics/deleteAnalyticsInRange",
  GET_AVALILABLE_DATES: "/api/analytics/getAvalilabeDates",
  GET_SERACH_AVALILABLE_DATES: "/api/search/getAvailableDates",
  GET_OVERALLSTATUS: "/api/calls/overAllStatus",
  GET_MONTHLY_REPORTS: "/api/report/monthly",
  POST_GENERATE_REPORT: "/api/report/monthly/gen",
  GET_STATES: "/api/microsite/store/getStates",
  GET_CITIES: "/api/microsite/store/getCities",
  GET_ALL_STORES: "/api/microsite/store/getAllStore",
  GET_STORE_BY_CITY: "/api/microsite/store/getStoreByCity",
  GET_KEYWORD: "/api/dynamicPages/getKeyWords",
  GET_PRODUCT_DATA: "/api/product/getProducts",
  EDIT_RELATEDPRODUCTS: "/api/dynamicPages/relatedProducts",
  ADD_RELATEDPRODUCTS: "/api/dynamicPages/updateRelatedProducts",
  GET_PRODUCT: "/api/microsite/product/getProducts",
  PATCH_KEYWORS: "/api/dynamicPages/keyword",
  DOWNLOAD_KEYWORD_SELECT: "/api/dynamicPages/exportKeywords",
  DYNAMIC_CONTENT_AVAILABLEDATES: "/api/dynamicPages/getAvailableDates",
  //
  POST_ARTILCES: "/api/articles/postarticle",
  GET_ARTICLES: "/api/articles/getarticle",
  UPDATE_ARTICLES: "/api/articles/updatearticles",
  DELETE_ARTICLES: "/api/articles/deletearticle",
  GET_ACTIVE_STORES: "/api/articles/getactivestores",
  GET_WATI_CHAT: "/api/wati/getwatidataforadmin",
};
