import { Routes, Route, Navigate } from "react-router-dom";
import { useState } from "react";

import { CssBaseline, Box } from "@mui/material";

import CustomDrawer, { DrawerHeader } from "../components/CustomDrawer";
import { drawerOptions } from "./drawer";
import DashBoard from "../pages/dashBoard";
import Oppertunity from "../pages/Oppertunity";
import Actions from "../pages/Action";
import NavBar from "../components/NavBar";
import PageNotFound from "../pages/404";
import GenerateReport from "../pages/GenrateReport";
import KeywordUploadPage from "../pages/keywordUpload";
import Articles from "../pages/Articles";
import ArticleForm from "../pages/Articles/articleForm";
import Wati from "../pages/Wati";

function AdditionalLayout(children: JSX.Element) {
  const [drawerState, setDrawerState] = useState(false);

  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <CustomDrawer
        options={drawerOptions}
        toggleDrawer={toggleDrawer}
        open={drawerState}
      />
      <NavBar
        toggleDrawer={toggleDrawer}
        drawerState={drawerState}
        title="CloseBI"
      />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>{" "}
    </Box>
  );
}

function Layout() {
  return (
    <Routes>
      <Route path="" element={<Navigate to="/user/calllog" />} />
      <Route path="dashBoard" element={AdditionalLayout(<DashBoard />)} />
      <Route path="action" element={AdditionalLayout(<Actions />)} />
      <Route path="calllog" element={AdditionalLayout(<Oppertunity />)} />
      <Route path="articles" element={AdditionalLayout(<Articles />)} />
      <Route path="articleForm" element={AdditionalLayout(<ArticleForm />)} />
      <Route path="wati" element={AdditionalLayout(<Wati />)} />
      <Route
        path="keywordupload"
        element={AdditionalLayout(<KeywordUploadPage />)}
      />
      <Route
        path="generatereport"
        element={AdditionalLayout(<GenerateReport />)}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Layout;
