import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { keys, map } from "lodash";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
interface Iprops {
  data: any;
  endDate: any;
}
const useStyles = makeStyles({
  content: {
    flexGrow: 1,
    margin: "10px",
    textAlign: "right",
    alignItems: "right",
    justifyContent: "right",
  },
  ruler: {
    borderBottom: "1px dashed #999",
    textDecoration: "none",
    width: "90%",
    margin: "0 auto",
  },
  contentBox: {
    display: "flex",
    marginTop: "7px",
    marginLeft: "140px",
    textAlign: "right",
  },
});
const Content = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    marginLeft: "90px",
  },
}));
const DetailCard = ({ data, endDate }: Iprops) => {
  const keylist = keys(data).slice(1);
  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              justifyContent: "center",
              fontSize: "20px",
              color: "black",
            }}
          >
            {" "}
            {endDate &&
              moment(endDate).subtract(180, "days").format("DD MMM YYYY")}{" "}
            <span> - </span>
            {moment(endDate).format(" DD MMM YYYY")}
            <hr className={classes.ruler} />
          </Typography>
          <Content>
            <Box className={classes.content}>
              {map(keylist, (value) => {
                return (
                  <Box className={classes.contentBox}>
                    <Grid
                      container
                      columnSpacing={{ xs: 5, sm: 5, md: 2, lg: 2 }}
                    >
                      <Grid item md={6} sx={{ textAlign: "left" }}>
                        <Typography
                          variant="body1"
                          sx={{
                            width: theme.spacing(25),
                            fontSize: 17,
                            color: "black",
                          }}
                        >
                          {value}
                        </Typography>
                      </Grid>
                      <Grid item md={6} sx={{ textAlign: "right" }}>
                        <Typography
                          sx={{
                            width: theme.spacing(10),
                            fontSize: 19,
                            color: "black",
                          }}
                        >
                          {data[value] < 1000
                            ? data[value]
                            : (data[value] / 1000).toFixed(2) + "k"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </Box>
          </Content>
        </Box>
      </Box>
    </>
  );
};

export default DetailCard;
