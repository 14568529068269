import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

export const DropZoneWrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(1),
  border: "2px gray dashed",
  backgroundColor: "#e5e4e2",
  margin: "auto",
  height: "200px",
}));
export const ContentAdjust = styled(Box)(({ theme }) => ({
  textAlign: "center",
  cursor: "pointer",
  margin: "50px",
  marginLeft: "10px",
}));

interface Iprops {
  handleError: any;
  handleChange: any;
  disabled: boolean;
}

function Dropzone({ handleChange, handleError, disabled }: Iprops) {
  const [fileDetails, setFileDetails] = useState<any>();

  const { getRootProps, getInputProps } = useDropzone({
    disabled: disabled,
    onDrop: (file) => {
      if (file.length > 0) {
        handleChange(file[0]);
        setFileDetails(file[0]);
      } else {
        handleError();
      }
    },

    // onDropAccepted: (file) => {
    //   setFileDetails(null);
    // },

    maxFiles: 1,
    accept: {
      "text/*": [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    },
  });

  const handleRemove = () => {
    setFileDetails(null);
  };
  if (disabled === true) {
    window.location.reload();
  }

  return (
    // eslint-disable-next-line no-sequences
    <DropZoneWrapper {...getRootProps({ className: "dropzone" })}>
      <input className="input-zone" {...getInputProps()} />
      <div className="text-center">
        {fileDetails ? (
          <ContentAdjust>
            <p>
              {fileDetails.name}
              <HighlightOffIcon onClick={handleRemove} />
            </p>
          </ContentAdjust>
        ) : (
          <ContentAdjust>
            <CloudUploadOutlinedIcon
              style={{ color: "#65b17f", fontSize: "80px" }}
            />
            <p>Drag and Drop File here...</p>
          </ContentAdjust>
        )}
      </div>
    </DropZoneWrapper>
  );
}

export default Dropzone;
