/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Typography,
  CircularProgress,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Popper,
  Fade,
  Paper,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import FormGroup from "@mui/material/FormGroup";
import SendIcon from "@mui/icons-material/Send";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CustomGrid from "../../components/CustomGrid";
import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { API_CONSTANTS } from "../../constants/index";
import * as _ from "lodash";
import API from "../../services/apis/api";
import { useAppDispatch } from "../../hooks";
import { openSnackbar } from "../../redux/features/snackBarSlice";
import { get } from "lodash";
import PagesOnFlyEdit from "../../components/PagesOnFlyEdit";
import CloseIcon from "@mui/icons-material/Close";
import { ICellRendererParams } from "ag-grid-community";
import { Box, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import "./styles.css";
import { FileDownloadOutlined } from "@mui/icons-material";
import axios from "axios";
import { getLocalStorageItem } from "../../services/localStorage";
import moment from "moment";
const FileDownload = require("js-file-download");
export const LoadSpinner = styled("div")(({ theme }) => ({
  marginTop: "-39px",
  textAlign: "center",
}));

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  } /** Added */,
  contentStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1000px",
    bgcolor: "background.paper",
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "15px",
  },
  menuPaper: {
    maxHeight: "50px",
  },
}));

const PopupModal = ({ postModalOpen, handleClose, children }: any) => {
  const classes = useStyles();

  return (
    // Note the className prop
    <Modal
      open={postModalOpen}
      onClose={handleClose}
      // className={classes.modalStyle}
    >
      <Box className={classes.contentStyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={{
            fontSize: "14px",
            marginLeft: "5px",
          }}
        >
          {children}
        </Typography>
      </Box>
    </Modal>
  );
};

function PagesOnFly() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [keywordData, setKeywordData] = useState<any>([]);
  const [download, setDownload] = useState(false);
  const [selected, setSelected] = useState<any>(false);
  const [store, setStore] = useState<any>(null);
  const [resultData, setResultData] = useState<any>(null);
  const dispatch = useAppDispatch();
  const [storeId, setStoreId] = useState("");
  const [reportData, setReportData] = useState<any>([]);
  const [storeData, setStoreData] = useState<any>([]);
  const [value, setValue] = React.useState("1");
  const [productData, setProductData] = useState([]);
  const [finalData, setfinalData] = useState<any>([]);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [productLoading, setProductLoading] = useState(false);
  const [keywordId, setKeywordId] = useState("");
  const [openState, setOpenState] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDate, setSelectedDate] = useState<any>(null);

  const handleClose = () => setOpen(false);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  useEffect(() => {
    setLoading(true);
    API.fetchWithParams(
      API_CONSTANTS.GET_STORE_DATA,
      (res: any) => {
        setStoreId(res.data.data[0].storeId);
        setStore(res.data?.data);
        setStoreData(res.data.data[0]);
      },
      (err: any) => {
        if (err.response.status <= 400 && err.response.status >= 500) {
          localStorage.clear();
          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
        match: {},
      }
    );

    API.fetchWithParams(
      API_CONSTANTS.GET_PRODUCT_DATA,
      (res: any) => {
        setProductData(res.data?.data);
      },
      (err: any) => {
        if (err.response.status <= 400 && err.response.status >= 500) {
          localStorage.clear();
          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
      }
    );
  }, []);

  useEffect(() => {
    const storeFind = store?.find((val: any) => {
      return val.storeId === storeId;
    });
    setStoreData(storeFind);
  }, [storeId]);

  useEffect(() => {
    if (storeId !== "" && selectedDate !== null) {
      let match;
      match = JSON.stringify({
        store: storeId,
        createdAt: { $gte: selectedDate?.[0], $lte: selectedDate?.[1] },
      });

      API.fetchWithParams(
        `${API_CONSTANTS.GET_KEYWORD}`,
        (res: any) => {
          setLoading(false);
          setSelected(false);
          const newArray = _.cloneDeep(res?.data.data);
          setResultData(newArray);
          setReportData(res?.data.data);
          setfinalData(res?.data.data);
        },
        (err: any) => {
          setLoading(false);
          setSelected(false);

          setfinalData([]);
          setReportData([]);

          setResultData(null);
          if (err.request.status === 400) {
            dispatch(
              openSnackbar({
                messege: "No Keywords Found",
                severity: "error",
              })
            );
          }
        },
        {
          match: match,
        }
      );
    }
  }, [storeId, selected, selectedDate]);
  const TotalValueRenderer = (props: ICellRendererParams) => {
    const checkedHandler = (event: any) => {
      let checked = event.target.checked;

      const tempArr = resultData;
      const index = tempArr.findIndex((x: any) => x._id === props.data._id);
      tempArr[index].status = !tempArr[index].status;
      setResultData(tempArr);
      let colId = props?.column?.getId();

      props.node.setDataValue(`${colId}`, checked);
    };

    return (
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          label={props.value === true ? "Active" : "Inactive"}
          onChange={(e: any) => checkedHandler(e)}
          checked={props.value}
        />
      </FormGroup>
    );
  };

  const handleSubmit = () => {
    const data: any = [];

    for (let i = 0; i < resultData.length; i++) {
      for (let j = 0; j < finalData.length; j++) {
        if (resultData[i]._id === finalData[j]._id) {
          if (resultData[i].status !== finalData[j].status) {
            data.push(resultData[i]);
          }
        }
      }
    }

    if (data.length < 0) {
      dispatch(
        openSnackbar({
          messege: "No Changes",
          severity: "error",
        })
      );
    } else {
      const getKeywordID = data?.map((val: any) => {
        return { id: val?._id, status: val?.status };
      });
      setLoading(true);
      API.PatchRequest(
        API_CONSTANTS.PATCH_KEYWORS,
        (res: any) => {
          // setLoading(false);

          setSelected(true);

          dispatch(
            openSnackbar({
              messege: get(res, "data.message", "content updated succesfully"),
              severity: "success",
            })
          );
        },
        (err: any) => {
          setLoading(false);

          dispatch(
            openSnackbar({
              messege: get(err, "", "Error while updating"),
              severity: "error",
            })
          );
          console.error(err);
          if (err.response.status <= 400 && err.response.status > 500) {
            localStorage.clear();

            dispatch(
              openSnackbar({
                messege: get(err, "", "Token Expired! Log In Again"),
                severity: "error",
              })
            );
          }
        },
        {
          match: getKeywordID,
        }
      );
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenState((previousOpen) => !previousOpen);
  };
  const headerHeight = 65;
  const rowHeight = 60;
  const rowStyle = {
    borderTop: "white 8px solid",
  };

  const TotalValueRenderer2 = (props: ICellRendererParams) => {
    const cellValue = props.data;
    const buttonClicked1 = () => {
      setOpen(true);

      setKeywordData(cellValue);
      setKeywordId(cellValue._id);
    };

    return (
      <div>
        <Button
          type="button"
          disabled={!cellValue.status}
          onClick={() => {
            buttonClicked1();
          }}
          variant="contained"
        >
          Edit
        </Button>
        <PopupModal postModalOpen={open} onClose={handleClose}>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button onClick={() => setOpen(false)}>
              <CloseIcon />
            </Button>
          </div>
          <PagesOnFlyEdit
            keyword={keywordData}
            product={productData}
            relatedProducts={relatedProduct}
            getKeywordsData={getKeywordsData}
            loading={productLoading}
            storeId={storeId}
            fetch={fetch}
          />
        </PopupModal>
      </div>
    );
  };
  const Token = getLocalStorageItem("token");
  const onDownload = (name: any) => {
    // const match = JSON.stringify({ store: storeId });
    // const match1 = JSON.stringify({});

    const path = `${process.env.REACT_APP_BACKEND_API_PROXY}${
      API_CONSTANTS.DOWNLOAD_KEYWORD_SELECT
    }?match=${JSON.stringify({
      createdAt: { $gte: selectedDate[0], $lte: selectedDate[1] },
    })}`;
    setDownload(true);
    axios({
      url: path, //your url
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      method: "GET",
      responseType: "blob", // important
    })
      .then((res) => {
        setDownload(false);
        FileDownload(res.data, `Close_Bi_keyword.csv`);
      })
      .catch((error) => {
        setDownload(false);
        dispatch(
          openSnackbar({
            messege: get(error, "response.data.message", " Try again later"),
            severity: "error",
          })
        );
        console.error("download file error", error);
      });
  };

  const LinkRenderer = (props: ICellRendererParams) => {
    const cellValue = props.data.keyWord;
    const status = props.data.status;
    const storePath = `${get(storeData, "locality")}-${get(
      storeData,
      "city"
    )}/${get(storeData, "_id")}/${storeId}`
      .toLowerCase()
      .replace(/\s/g, "-");

    const Link = `https://locate.tvs-e.in/${storePath}/${cellValue}`.replace(
      /\s/g,
      "-"
    );

    return (
      <div>
        <a href={`${Link}`} target="_blank" rel="noreferrer">
          <Button
            variant="contained"
            sx={{
              marginRight: "5px",
              borderRadius: "10px",
              fontSize: "15px",
              color: "#00000",
              backgroundColor: "#fffff",
            }}
            disabled={!status}
          >
            Redirect
          </Button>
        </a>
      </div>
    );
  };
  useEffect(() => {
    API.fetchWithParams(
      API_CONSTANTS.DYNAMIC_CONTENT_AVAILABLEDATES,
      (res: any) => {
        setSelectedDate([
          moment(res.data.data.endMoth).startOf("month").format("YYYY-MM-DD"),
          moment(res.data.data.endMoth).endOf("month").format("YYYY-MM-DD"),
        ]);
      },
      (err: any) => {},
      {}
    );
  }, []);

  const columnDef1 = [
    {
      field: "store",
      headerName: "Store",
      sortable: true,
      minWidth: 150,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "No",

      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),

      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
    {
      field: "keyWord",
      headerName: "keyWord",
      sortable: true,
      minWidth: 400,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "No",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),

      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
    {
      field: "volume",
      headerName: "volume",
      sortable: true,
      minWidth: 120,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "No",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // marginLeft:"20px",
      }),

      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Link",
      headerName: "Link",
      sortable: true,
      minWidth: 120,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "No",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // marginLeft:"20px",
      }),

      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
      cellRenderer: LinkRenderer,
    },
    {
      field: "status",
      minWidth: 100,
      resizable: true,
      cellClass: "noTransition",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        backgroundColor: "#f4f4f4",
      }),
      cellRenderer: TotalValueRenderer,
    },
    {
      field: "Date",
      minWidth: 100,
      resizable: true,
      cellClass: "noTransition",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        backgroundColor: "#f4f4f4",
      }),
      cellRenderer: (props: ICellRendererParams) => {
        const cellValue = props.data;
        return (
          <Typography>
            {moment(cellValue.createdAt).format("DD/MM/YYYY")}
          </Typography>
        );
      },
    },
  ];
  const columnDef2 = [
    {
      field: "store",
      headerName: "Store",
      sortable: true,
      minWidth: 120,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "store",

      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // marginLeft:"20px",
      }),

      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
    {
      field: "keyWord",
      headerName: "keyWord",
      sortable: true,
      minWidth: 150,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "keyWord",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        // marginLeft:"20px",
      }),

      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
    {
      field: "volume",
      headerName: "volume",
      sortable: true,
      minWidth: 120,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "volume",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // marginLeft:"20px",
      }),

      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "content",
      headerName: "content",
      sortable: true,
      minWidth: 200,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "content",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        // marginLeft:"20px",
      }),

      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
    {
      field: "status",
      headerName: "status",
      sortable: true,
      minWidth: 50,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "status",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // marginLeft:"20px",
      }),
      cellRenderer: (props: any) => {
        const { data } = props;
        return (
          <Typography>
            {data.status === true ? (
              <CheckCircleOutlineIcon color="secondary" />
            ) : (
              <HighlightOffIcon color="error" />
            )}
          </Typography>
        );
      },

      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
    {
      field: "Date",
      minWidth: 100,
      resizable: true,
      cellClass: "noTransition",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        backgroundColor: "#f4f4f4",
      }),
      cellRenderer: (props: ICellRendererParams) => {
        const cellValue = props.data;
        return (
          <Typography>
            {moment(cellValue.createdAt).format("DD/MM/YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "Action",
      minWidth: 50,
      resizable: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      cellRenderer: TotalValueRenderer2,
    },
  ];

  const getKeywordsData = async () => {
    setProductLoading(true);
    if (storeId !== "" && selectedDate !== null) {
      let match;
      match = JSON.stringify({
        store: storeId,
        _id: keywordId,
        createdAt: { $gte: selectedDate[0], $lte: selectedDate[1] },
      });
      setRelatedProduct([]);
      setProductLoading(true);
      await API.fetchWithParams(
        `${API_CONSTANTS.GET_KEYWORD}`,
        (res: any) => {
          if (res.data.data.length > 0) {
            setProductLoading(false);
            const data = res.data.data[0].relatedProducts;
            setRelatedProduct(data);
          } else {
            setProductLoading(true);
          }
        },
        (err: any) => {
          setProductLoading(false);
        },
        {
          match: match,
        }
      );
    }
  };
  useEffect(() => {
    getKeywordsData();
  }, [keywordId]);

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const handleClickAway = () => {
    setOpenState(false);
  };
  return (
    <div>
      <Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress sx={{ color: "green" }} />
        </Backdrop>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8} lg={9} xl={9}>
          <Typography variant="h4" style={{ fontSize: "23px" }}>
            Pages On Fly
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1.5}
          xl={1.5}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button
            aria-describedby={id}
            sx={{
              // ml: 5,
              backgroundColor: "#A0D5EF",
              color: "black",
              boxShadow: "1px 1px 1px 1px #DCDCDC",
              "&.Mui-disabled": {
                background: "#eaeaea",
                color: "#c0c0c0",
              },
              "&:hover": {
                backgroundColor: "#A0D5EF",
              },
              width: "100%",
            }}
            onClick={handleClick}
            variant="contained"
            startIcon={<FilterAltIcon />}
          >
            Filter
          </Button>
          <Popper
            id={id}
            open={openState}
            anchorEl={anchorEl}
            transition
            sx={{ width: 350 }}
          >
            {({ TransitionProps }) => (
              // <ClickAwayListener onClickAway={handleClickAway}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Typography sx={{ p: 3 }}>
                    <div style={{ float: "right" }}>
                      <Button onClick={() => setOpenState(false)}>
                        <CloseIcon
                          style={{ color: "black", marginLeft: "50px" }}
                        />
                      </Button>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ pb: 2, mt: 6 }}
                    >
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                          label="Select Month"
                          views={["year", "month"]}
                          // disabled={loading1}
                          value={selectedDate[0] ? selectedDate[0] : null}
                          onMonthChange={(date1) => {
                            setLoading(true);
                            setSelected(true);
                            const date = get(date1, "_d");
                            setSelectedDate([
                              moment(date)
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                              moment(date).endOf("month").format("YYYY-MM-DD"),
                            ]);
                            // cleanUp();
                          }}
                          onChange={(date) => {}}
                          disableFuture={true}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              placeholder={moment(selectedDate[0]).format(
                                "YYYY-MMM"
                              )}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: `${moment(selectedDate[0]).format(
                                  "YYYY-MMM"
                                )}`,
                              }}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <div style={{ marginTop: 5 }}>
                      <FormControl sx={{ width: 300 }}>
                        <InputLabel id="demo-simple-select-readonly-label">
                          {" "}
                          Select Store
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-readonly-label"
                          id="demo-simple-select-readonly"
                          label="Select Store"
                          value={storeId}
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: 400 } },
                          }}
                          onChange={(e, value) => {
                            const val = get(value, "props.value", "");
                            setStoreId(val);
                            setLoading(true);
                          }}
                        >
                          {store?.map((i: any) => {
                            return (
                              <MenuItem value={i.storeId}>
                                {i.storeName}-{i.city}.
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </Typography>
                </Paper>
              </Fade>
              // </ClickAwayListener>
            )}
          </Popper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1.5}
          xl={1.5}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button
            variant="contained"
            sx={{
              // mx: 1,
              // my: 2,
              minWidth: "23px",
              backgroundColor: "#A0D5EF",
              color: "black",
              boxShadow: "1px 1px 1px 1px #DCDCDC",
              "&.Mui-disabled": {
                background: "#eaeaea",
                color: "#c0c0c0",
              },

              "&:hover": {
                backgroundColor: "#A0D5EF",
              },
              width: "100%",
            }}
            onClick={() => {
              onDownload(`${storeData.storeName}`);
            }}
            disabled={download}
          >
            {download ? (
              "Exporting .."
            ) : (
              <>
                Export
                <FileDownloadOutlined
                  sx={{ marginLeft: "7px", fontSize: "22px" }}
                />{" "}
              </>
            )}
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card sx={{ p: 1 }}>
            <Box sx={{ width: "100%" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Keywords Select" value="1" />
                    <Tab label="Edit Content" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={9}
                      md={10}
                      lg={10}
                      xl={10}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <p>
                        Total Number of Keywords : {resultData?.length || 0}{" "}
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={2}
                      lg={2}
                      xl={2}
                      sx={{ display: "flex", justifyContent: "right" }}
                    >
                      <Button
                        style={{ width: "100%" }}
                        variant="contained"
                        startIcon={<SendIcon />}
                        disabled={loading || resultData === null}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ width: "100%" }}
                    >
                      <CustomGrid
                        handleCellClick={undefined}
                        loading={loading}
                        disableClickSelectionRenderers={true}
                        suppressRowClickSelection
                        columnDefs={columnDef1}
                        enableCellChangeFlash={false}
                        noDataTxt="No Data..."
                        rowData={resultData}
                        TableHeight={70}
                        rowSelection="multiple"
                        currentPage={0}
                        serverSidePagination={false}
                        setServerSidePage={undefined}
                        totalDataCount={0}
                        rowHeight={rowHeight}
                        rowStyle={rowStyle}
                        headerHeight={headerHeight}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid container spacing={2} style={{ marginTop: "5px" }}>
                    <Grid item xs={12} sm={12} md={12}>
                      <CustomGrid
                        handleCellClick={undefined}
                        loading={loading}
                        disableClickSelectionRenderers={false}
                        suppressRowClickSelection={false}
                        columnDefs={columnDef2}
                        noDataTxt="No Data..."
                        rowData={reportData}
                        disableClickSelectionRenders={false}
                        suppressChangeDetection={false}
                        TableHeight={70}
                        rowSelection="single"
                        currentPage={0}
                        serverSidePagination={false}
                        setServerSidePage={undefined}
                        totalDataCount={0}
                        rowHeight={rowHeight}
                        rowStyle={rowStyle}
                        headerHeight={headerHeight}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default PagesOnFly;
