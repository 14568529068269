import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Typography,
  CircularProgress,
  CardActions,
  Backdrop,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import CustomGrid from "../../components/CustomGrid";
import { Card, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { AxiosError, AxiosResponse } from "axios";
import { API_CONSTANTS } from "../../constants/index";
import Moment from "react-moment";
import * as _ from "lodash";
import API from "../../services/apis/api";
import Dropzone from "./dropZone";
import { useAppDispatch } from "../../hooks";
import { openSnackbar } from "../../redux/features/snackBarSlice";
import { Upload } from "@mui/icons-material";
import moment from "moment";
import * as xlsx from "xlsx";
import { get, reduce } from "lodash";
import { DatePicker } from "rsuite";
import { Box } from "@mui/system";

export const LoadSpinner = styled("div")(({ theme }) => ({
  marginTop: "-39px",
  textAlign: "center",
}));
// const {
//   allowedMaxDays,
//   allowedDays,
//   allowedRange,
//   beforeToday,
//   afterToday,
//   combine,
// } = DateRangePicker;
function UploadPage() {
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordUpdated, setRecordUpdated] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const gridOptions: any = useRef();
  const [selectedDate, setSelectedDate] = useState<any>();
  const [AnalyticsData, setAnalytics] = useState([]);

  const dispatch = useAppDispatch();

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");

            var dateParts = dateAsString.split("/");

            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );

            if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
    };
  }, []);
  const columnDef = [
    {
      field: "Business name",
      sortable: true,
      minwidth: 600,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "Business name",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
    {
      field: "Date",
      minWidth: 70,
      resizable: true,
      tooltipField: "Date",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
      type: ["dateColumn", "nonEditableColumn"],
      cellRendererFramework: (props: any) => {
        const { data } = props;
        return <Moment format="DD-MMM-YYYY">{_.get(data, "Date", "")}</Moment>;
      },
    },
    {
      field: "Google Search - Mobile",
      minWidth: 220,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "Google Search Mobile",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },

    {
      field: "Google Search - Desktop",
      minWidth: 250,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "Google Search Desktop",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Google Maps - Mobile",
      minWidth: 250,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "Google Maps Mobile",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Google Maps - Desktop",
      minWidth: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "Google Maps Desktop",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Calls",
      minWidth: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "Calls",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Directions",
      minWidth: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "Directions",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Website clicks",
      minWidth: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "Website clicks",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
  ];
  function handleChange(file: any) {
    const fileName = moment(file.name, "YYYY-MM-DD").format();
    const dateChoosed = moment(selectedDate, "YYYY-MM-DD").format();
    if (moment(fileName).isSame(dateChoosed)) {
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        const bstr = evt.target.result;
        const wb = xlsx.read(bstr, {
          type: "binary",
          cellText: false,
          cellDates: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const ExcelToJson = xlsx.utils
          .sheet_to_json(ws, {
            raw: false,
            dateNF: "YYYY-MM-DD hh:mm:ss",
          })
          .slice(1);
        const excelData = reduce(
          ExcelToJson,
          (result, json: any) => {
            json["Date"] = moment(selectedDate, "YYYY-MM-DD").format();
            let temparray: any = result;
            temparray.push(json);
            return temparray;
          },
          []
        );
        setAnalytics(excelData);
      };
      reader.readAsBinaryString(file);
    } else {
      dispatch(
        openSnackbar({
          messege: "Invalid File Name",
          severity: "error",
          autHideDuriation: 4000,
        })
      );
    }
  }

  const onGridReady = useCallback((params: any) => {
    gridOptions.current = params;
  }, []);

  const onSelectionChanged = (event: any) => {
    setFileData(event.api.getSelectedRows());
  };

  function handleSubmit(event: any) {
    event.preventDefault();

    const selectedArray = gridOptions.current.api.getSelectedRows();
    setLoading(true);
    API.PostRequest(
      `${API_CONSTANTS.POST_UPLOAD}`,
      (res: AxiosResponse) => {
        setLoading(false);
        setRecordUpdated(true);
        setdisabled(true);
        dispatch(
          openSnackbar({
            messege: get(res, "data.message", `${res.data}`),
            severity: "success",
            autHideDuriation: 4000,
          })
        );
        gridOptions.current.api.setRowData([]);
        selectedDate(new Date());
      },
      (error: AxiosError) => {
        setLoading(false);
        if (error.response?.status === 400) {
          dispatch(
            openSnackbar({
              messege: get(error, "response.data.message", `${error.response}`),
              severity: "error",
              autHideDuriation: 4000,
            })
          );
        }
      },
      {
        analyticsData: selectedArray,
      }
    );
  }

  const handleError = () => {
    dispatch(
      openSnackbar({
        messege: "Invalid File Format",
        severity: "error",
        autHideDuriation: 3000,
      })
    );
  };

  return (
    <div>
      <Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress sx={{ color: "green" }} />
        </Backdrop>
      </Box>
      <Typography variant="h4" style={{ fontSize: "23px" }}>
        GMB - Upload
      </Typography>

      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item>
          <DatePicker
            size="lg"
            placeholder="Select Date"
            disabledDate={(date) => moment(date).isAfter()}
            onChange={(date) => {
              setSelectedDate(moment(date).format("YYYY-MM-DD"));
            }}
          />
          <Typography style={{ fontSize: "13px", margin: "5px" }}>
            Note: Please Upload YYYY-MM-DD File Format
          </Typography>
        </Grid>

        <Grid item container alignItems={"center"} xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Dropzone
              handleChange={handleChange}
              handleError={handleError}
              // disabled={moment(selectedDate).isValid()}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
          <Card>
            <CardContent>
              <Typography
                sx={{ mx: 1, my: 2 }}
                style={{
                  fontWeight: 400,
                  fontSize: "15px",
                  color: "#000000",
                }}
              >
                Total Selected Records : {fileData.length}
              </Typography>
              <CustomGrid
                onGridReady={onGridReady}
                handleCellClick={undefined}
                loading={false}
                columnTypes={columnTypes}
                disableClickSelectionRenderers={false}
                suppressRowClickSelection
                columnDefs={columnDef}
                noDataTxt="No Data..."
                rowData={AnalyticsData}
                TableHeight={70}
                onSelectionChanged={onSelectionChanged}
                rowSelection="multiple"
                currentPage={0}
                serverSidePagination={false}
                setServerSidePage={undefined}
                totalDataCount={0}
              />
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Button
                style={{
                  width: "20%",
                }}
                variant="contained"
                disabled={fileData.length === 0 ? true : false || recordUpdated}
                onClick={handleSubmit}
                endIcon={<Upload />}
              >
                Upload
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default UploadPage;
