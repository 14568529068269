import { Box, Grid, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { EditorState, ContentState } from "draft-js";
// import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CustomGrid from "../CustomGrid";
import moment from "moment";
import { get } from "lodash";
import api from "../../services/apis/api";
import { API_CONSTANTS } from "../../constants";
import { AxiosError, AxiosResponse } from "axios";
import { openSnackbar } from "../../redux/features/snackBarSlice";
import { useAppDispatch } from "../../hooks";

interface Iprops {
  fetch: any;
  relatedProducts: any;
  getKeywordsData: () => void;
  keyword: any;
  product: any;
  storeId: any;
  loading: any;
}

export default function PagesOnFlyEdit(props: Iprops) {
  const { keyword, product, getKeywordsData, relatedProducts, loading } = props;

  const dispatch = useAppDispatch();

  const [productLoading, setProductLoading] = useState(loading);
  const [productLoading1, setProductLoading1] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);

  const [relatedProduct] = useState(relatedProducts);
  const [selectedData, setSelectedData] = useState([]);
  const [filteredData, setfiltereData] = useState<any>();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromText(get(keyword, "content", ""))
    )
  );

  const [getOnchageValues, setGetOnChangeValues] = useState<any>();
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");

            var dateParts = dateAsString.split("/");

            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );

            if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
    };
  }, []);
  const onSelectionChanged = (event: any) => {
    const data = event.api.getSelectedRows();

    const selectedId = data.map((val: any) => val._id);

    setSelectedData(selectedId);
  };

  const columnDef2 = [
    {
      field: "productId",
      headerName: "productId",
      sortable: true,
      minWidth: 100,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "No",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // marginLeft:"20px",
      }),

      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
    {
      field: "productName",
      headerName: "productName",
      sortable: true,
      minWidth: 120,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "No",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        // marginLeft:"20px",
      }),

      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
    {
      field: "category",
      headerName: "category",
      sortable: true,
      minWidth: 120,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "No",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // marginLeft:"20px",
      }),

      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
  ];
  const headerHeight = 45;
  const rowHeight = 45;
  const rowStyle = {
    // borderBottom: 'white 8px solid',
    borderTop: "white 8px solid",
  };

  const handleSubmit = () => {
    setContentLoading(true);
    api.PatchRequest(
      `${API_CONSTANTS.UPDATE_PAGEONFLY_CONTENT}?id=${keyword?._id}`,
      (res: AxiosResponse) => {
        setContentLoading(false);
        // window.location.reload();
        dispatch(
          openSnackbar({
            severity: "success",
            messege: "Content Uploaded Successfully",
          })
        );
      },
      (error: AxiosError) => {
        setContentLoading(false);
        if (error) {
          dispatch(
            openSnackbar({
              severity: "error",
              messege: get(error, "response.data.messege", "Error"),
            })
          );
        } else {
          dispatch(
            openSnackbar({
              severity: "error",
              messege: "Try again Later",
            })
          );
        }
      },
      { content: `${getOnchageValues}` }
    );
  };
  const handleProductData = () => {
    setProductLoading(true);

    api.PatchRequest(
      `${API_CONSTANTS.EDIT_RELATEDPRODUCTS}?id=${keyword?._id}`,
      (res: AxiosResponse) => {
        setProductLoading(false);

        dispatch(
          openSnackbar({
            severity: "success",
            messege: "Related Product Updated  Successfully",
          })
        );

        getKeywordsData();
      },
      (error: AxiosError) => {
        setProductLoading(false);
        if (error) {
          dispatch(
            openSnackbar({
              severity: "error",
              messege: get(error, "response.data.messege", "Error"),
            })
          );
        } else {
          dispatch(
            openSnackbar({
              severity: "error",
              messege: "Try again Later",
            })
          );
        }
      },
      { products: selectedData }
    );
  };

  const handleProductAddData = () => {
    setProductLoading1(true);
    api.PatchRequest(
      `${API_CONSTANTS.ADD_RELATEDPRODUCTS}?id=${keyword?._id}`,
      (res: AxiosResponse) => {
        setProductLoading1(false);
        dispatch(
          openSnackbar({
            severity: "success",
            messege: "Related Product Added Successfully",
          })
        );
        getKeywordsData();
      },
      (error: AxiosError) => {
        setProductLoading1(false);
        if (error) {
          dispatch(
            openSnackbar({
              severity: "error",
              messege: get(error, "response.data.messege", "Error"),
            })
          );
        } else {
          dispatch(
            openSnackbar({
              severity: "error",
              messege: "Try again Later",
            })
          );
        }
      },
      { products: selectedData }
    );
  };

  useEffect(() => {
    const results = product.filter(
      (value: any) => !relatedProduct.some((val: any) => val._id === value._id)
    );
    setfiltereData(results);
  }, [product, relatedProduct]);

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Related Products" value="1" />
              <Tab label="Add Related Products" value="2" />
              <Tab label="Keyword Content" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container>
              <Grid item lg={5.5}>
                <p>Keyword Name : {keyword?.keyWord}</p>
              </Grid>
              <Grid
                item
                lg={4.5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <p>
                  Total Number of Related Products :{" "}
                  {relatedProduct?.length || 0}{" "}
                </p>
              </Grid>

              <Grid item lg={1}>
                <Button variant="contained" onClick={handleProductData}>
                  {productLoading ? (
                    <CircularProgress style={{ color: "white" }} size={16} />
                  ) : (
                    ""
                  )}

                  <span style={{ marginLeft: "10px" }}>Delete</span>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={12} sx={{ p: 1 }}>
                <CustomGrid
                  handleCellClick={undefined}
                  loading={productLoading}
                  columnTypes={columnTypes}
                  disableClickSelectionRenderers={false}
                  suppressRowClickSelection
                  columnDefs={columnDef2}
                  noDataTxt="No Data..."
                  rowData={relatedProduct}
                  TableHeight={45}
                  rowSelection="multiple"
                  currentPage={0}
                  onSelectionChanged={onSelectionChanged}
                  serverSidePagination={false}
                  setServerSidePage={undefined}
                  totalDataCount={0}
                  rowHeight={rowHeight}
                  rowStyle={rowStyle}
                  headerHeight={headerHeight}
                />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value="2">
            <Grid container>
              <Grid item lg={5.5}>
                <p>Keyword Name : {keyword?.keyWord}</p>
              </Grid>
              <Grid item lg={5} sx={{ display: "flex", alignItems: "center" }}>
                <p>
                  Total Number of Remaining Products :{" "}
                  {filteredData?.length || 0}{" "}
                </p>
              </Grid>

              <Grid
                item
                lg={1}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <Button variant="contained" onClick={handleProductAddData}>
                  {productLoading1 ? (
                    <CircularProgress style={{ color: "white" }} size={16} />
                  ) : (
                    ""
                  )}
                  <span style={{ marginLeft: "10px" }}>Add</span>
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{ p: 1 }}>
                {" "}
                <CustomGrid
                  handleCellClick={undefined}
                  loading={productLoading1}
                  columnTypes={columnTypes}
                  disableClickSelectionRenderers={false}
                  suppressRowClickSelection
                  columnDefs={columnDef2}
                  noDataTxt="No Data..."
                  rowData={filteredData}
                  TableHeight={45}
                  rowSelection="multiple"
                  currentPage={0}
                  onSelectionChanged={onSelectionChanged}
                  serverSidePagination={false}
                  setServerSidePage={undefined}
                  totalDataCount={0}
                  rowHeight={rowHeight}
                  rowStyle={rowStyle}
                  headerHeight={headerHeight}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="3">
            <Grid container>
              <Grid item lg={10.5}>
                <p>Keyword Name : {keyword?.keyWord}</p>
              </Grid>
              <Grid item lg={1.5}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <Button variant="contained" onClick={handleSubmit}>
                    {contentLoading ? (
                      <CircularProgress style={{ color: "white" }} size={16} />
                    ) : (
                      ""
                    )}
                    <span style={{ marginLeft: "10px" }}>Update</span>
                  </Button>
                </div>
              </Grid>
            </Grid>

            <br />
            <div
              style={{
                border: "1px solid black",
                height: "394px",
                marginTop: "-20px",
              }}
            >
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                onChange={(e: any) =>
                  setGetOnChangeValues(get(e.blocks?.[0], "text", ""))
                }
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
