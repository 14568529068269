import React from "react";
import { Grid, Typography, CircularProgress, Backdrop } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import OpportunityTable from "./OpportunityTables";
import { API_CONSTANTS } from "../../constants/index";
import API from "../../services/apis/api";
import { AxiosResponse } from "axios";
import { get } from "lodash";
import { useAppDispatch } from "../../hooks";
import { openSnackbar } from "../../redux/features/snackBarSlice";

export const GraphWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  boxShadow: "3px 3px 3px 3px #DCDCDC",
  cursor: "pointer",
  width: "100%",
  borderRadius: 10,
}));
export const Heading = styled("div")(({ theme }) => ({
  marginBottom: 25,
}));
function CoreTvsData() {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setLoading(true);
    API.fetchWithParams(
      API_CONSTANTS.GET_CALL_ANALYTICS_DAYS,
      (res: AxiosResponse) => {
        setLoading(false);
      },
      (err: any) => {
        setLoading(false);

        // if (err.response.status >=400 || err.response.status <500) {
        //   localStorage.clear();
        //   navigate("/login", { replace: true });

        //   dispatch(
        //     openSnackbar({
        //       messege: get(err, "", "Token Expired! Log In Again"),
        //       severity: "error",
        //     })
        //   );
        // }
      },
      {
        fromDate: moment().subtract(6, "day").format("YYYY-MM-DD"),
        toDate: moment().add(1, "day").format("YYYY-MM-DD"),
      }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_CALL_ANALYTICS_MONTH,
      (res: AxiosResponse) => {
        setLoading(false);
      },
      (error: any) => {
        setLoading(false);
        if (error.response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(error, "response.data.message", " Token Expired"),
              severity: "error",
            })
          );
        }
      },
      {
        fromDate: moment()
          .subtract(3, "month")
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD"),
        toDate: moment().add(1, "day").format("YYYY-MM-DD"),
      }
    );

    API.fetchWithParams(
      API_CONSTANTS.GET_CALL_ANALYTICS_MONTH,
      (res: AxiosResponse) => {
        setLoading(false);
      },
      (error: any) => {
        setLoading(false);
        if (error.response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(error, "response.data.message", " Token Expired"),
              severity: "error",
            })
          );
        }
      },
      {
        fromDate: moment()
          .subtract(6, "month")
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD"),
        toDate: moment().add(1, "day").format("YYYY-MM-DD"),
      }
    );
  }, []);

  return (
    <div>
      <Box>
        <Backdrop
          sx={{ color: "#fffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress sx={{ color: "green" }} />
        </Backdrop>
      </Box>
      <Grid container sx={{ flexGrow: 1 }} spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Heading>
            <Typography variant="h4" style={{ fontSize: "23px" }}>
              Call Log
            </Typography>
          </Heading>
        </Grid>
      </Grid>

      <Grid container style={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OpportunityTable />
        </Grid>
      </Grid>
    </div>
  );
}

export default CoreTvsData;
