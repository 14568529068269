/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-undef */
import Axios from 'axios';
import { getLocalStorageItem } from '../localStorage';

export default {
  fetchWithParams: async (
    EndPoint: string,
    sucessCallback: any,
    errorCallback: any,
    params: any
  ) => {
    const Token = getLocalStorageItem('token');
    await Axios.get(`${process.env.REACT_APP_BACKEND_API_PROXY}${EndPoint}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      params: { ...params },
    })
      .then((resp) => {
        sucessCallback && sucessCallback(resp);
      })
      .catch((err) => {
        errorCallback && errorCallback(err);
      });
  },

  GetRequest: (EndPoint: string, sucessCallback: any, errorCallback: any) => {
    const Token = getLocalStorageItem('token');
    Axios.get(`${process.env.REACT_APP_BACKEND_API_PROXY}${EndPoint}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((resp) => {
        sucessCallback && sucessCallback(resp);
      })
      .catch((err) => {
        errorCallback && errorCallback(err);
      });
  },
  PostRequest: (
    EndPoint: string,
    // Token: string,
    sucessCallback: any,
    errorCallback: any,
    values: any
  ) => {
    const Token = getLocalStorageItem('token');
    Axios.post(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${EndPoint}`,
      values,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 5000,
      }
    )
      .then((resp) => {
        sucessCallback && sucessCallback(resp);
      })
      .catch((err) => {
        errorCallback && errorCallback(err);
      });
  },
  PostFormRequest: (
    EndPoint: string,
    // Token: string,
    sucessCallback: any,
    errorCallback: any,
    values: any
  ) => {
    const Token = getLocalStorageItem('token');
    Axios.post(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${EndPoint}`,
      values,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((resp) => {
        sucessCallback && sucessCallback(resp);
      })
      .catch((err) => {
        errorCallback && errorCallback(err);
      });
  },
  DeleteRequest: (
    EndPoint: string,
    Token: string,
    sucessCallback: any,
    errorCallback: any,
    values: any
  ) => {
    Axios.delete(`${process.env.REACT_APP_BACKEND_API_PROXY}${EndPoint}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: { ...values },
      timeout: 5000,
    })
      .then((resp) => {
        sucessCallback && sucessCallback(resp);
      })
      .catch((err) => {
        errorCallback && errorCallback(err);
      });
  },
  PatchRequest: async (
    EndPoint: string,
    // Token: string,
    sucessCallback: any,
    errorCallback: any,
    values: any
  ) => {
    const Token = getLocalStorageItem('token');
    Axios.patch(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${EndPoint}`,
      values,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 5000,
      }
    )
      .then((resp) => {
        sucessCallback && sucessCallback(resp);
      })
      .catch((err) => {
        errorCallback && errorCallback(err);
      });
  },
  login: (sucessCallback: any, errorCallback: any, values: any) => {
    Axios.post(
      `${process.env.REACT_APP_BACKEND_API_PROXY}/api/sessions/login`,
      values,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 5000,
      }
    )
      .then((resp) => {
        sucessCallback && sucessCallback(resp);
      })
      .catch((err) => {
        errorCallback && errorCallback(err);
      });
  },
  postFile: (
    EndPoint: string,
    formData: any,
    sucessCallback: any,
    errorCallback: any
  ) => {
    Axios.post(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${EndPoint}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
      .then((res) => {
        sucessCallback(res);
      })
      .catch((err) => {
        errorCallback(err);
      });
  },
};
