import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  CircularProgress,
  Backdrop,
  Autocomplete,
  Icon,
  ClickAwayListener,
  Paper,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Grid from "@mui/material/Grid";
import CustomGrid from "../../components/CustomGrid";
import { Card } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { API_CONSTANTS } from "../../constants/index";
import Moment from "react-moment";
import * as _ from "lodash";
import API from "../../services/apis/api";
import { useAppDispatch } from "../../hooks";
import { openSnackbar } from "../../redux/features/snackBarSlice";
import moment from "moment";
import { get } from "lodash";
import { Box } from "@mui/system";
import { ICellRendererParams } from "ag-grid-community";

export const LoadSpinner = styled("div")(({ theme }) => ({
  marginTop: "-39px",
  textAlign: "center",
}));

function GenerateReport() {
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [stateValue, setStateValue] = useState<any>(null);
  const [cityValue, setCityValue] = useState<any>(null);
  const [storeValues, setStoreValue] = useState<any>(null);
  const [currentpage, setCurrentpage] = useState(1);
  const [state, setState] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [actionDatas, SetActionDatas] = useState([]);
  const [totalRowSize, setTotalRowSize]: any = useState(10);
  const [store, setStore] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<any>([
    moment(new Date())
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    moment(new Date()).subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  ]);

  const Theme = useTheme();

  const [resultData, setResultData] = useState<any>(null);
  const dispatch = useAppDispatch();

  const TotalValueRenderer2 = (props: ICellRendererParams) => {
    const cellValue = props.data;

    const buttonClicked1 = () => {
      const storeName = cellValue.storeDetails.storeName;
      const Date = moment(cellValue.date).format("MMM-YYYY");
      const link1 = cellValue.file[0].Location;
      const link = document.createElement("a");
      link.setAttribute("download", "true");
      link.setAttribute("target", "_blank");
      link.setAttribute("download", `Close_Bi_${storeName}_${Date}.pdf`);
      link.download = `Close_Bi_${storeName}_${Date}.pdf`;
      link.href = `${link1}`;
      document.body.appendChild(link);
      link.click();
    };
    return (
      <>
        <Button
          variant="contained"
          onClick={() => buttonClicked1()}
          // disabled={download}
          sx={{ mr: 1 }}
        >
          Download Report
        </Button>
      </>
    );
  };

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");

            var dateParts = dateAsString.split("/");

            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );

            if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
    };
  }, []);

  // useEffect(() => {
  //   setLoading(true);
  //   API.fetchWithParams(
  //     API_CONSTANTS.GET_STORE_DATA,
  //     (res: any) => {
  //       setLoading(false);
  //       setAnalyticsData(res.data?.data);
  //     },
  //     (err: any) => {
  //       setLoading(false);
  //       if (err.response.status <= 400 && err.response.status >= 500) {
  //         localStorage.clear();
  //         // navigate('/login', { replace: true });

  //         dispatch(
  //           openSnackbar({
  //             messege: get(err, '', 'Token Expired! Log In Again'),
  //             severity: 'error',
  //           })
  //         );
  //       }
  //     },
  //     {
  //       brandId: '62d1157242a9f208b4388b8a',
  //     }
  //   );
  //   UpdateFields(selectedDate[0], selectedDate[1]);
  // }, []);

  const headerHeight = 65;
  const rowHeight = 60;
  const rowStyle = {
    // borderBottom: 'white 8px solid',
    borderTop: "white 8px solid",
  };
  const columnDef2 = [
    {
      field: "storecode",
      headerName: "ID",
      sortable: true,
      minWidth: 120,
      filter: "agTextColumnFilter",
      resizable: true,
      tooltipField: "No",

      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // marginLeft:"20px",
      }),

      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },
    {
      field: "date",
      minWidth: 140,
      resizable: true,
      tooltipField: "date",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
      type: ["dateColumn", "nonEditableColumn"],
      cellRendererFramework: (props: any) => {
        const { data } = props;

        return <Moment format="MMM-YYYY">{_.get(data, "date", "")}</Moment>;
      },
    },
    {
      field: "storeDetails.storeName",
      headerName: "STORE NAME",
      minWidth: 350,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "storeName",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },

    {
      field: "storeDetails.city",
      headerName: "CITY",
      minWidth: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      tooltipField: "city",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "status",
      minWidth: 150,
      resizable: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),

      cellRenderer: (props: ICellRendererParams) => {
        const cellValue = props.data;

        return (
          <Typography>
            {cellValue.mailStatus ? (
              <Icon sx={{ color: "green" }}>check_circle</Icon>
            ) : (
              <Icon sx={{ color: "red" }}>cancel</Icon>
            )}
          </Typography>
        );
      },
    },
    {
      field: "Report Action",
      minWidth: 290,

      resizable: true,
      tooltipField: "keyword",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),

      cellRenderer: TotalValueRenderer2,
    },
  ];

  function UpdateFields1(storeId: any) {
    setLoading(true);
    API.fetchWithParams(
      `${API_CONSTANTS.GET_MONTHLY_REPORTS}?match=${JSON.stringify({
        date: { $gt: selectedDate[0], $lte: selectedDate[1] },
        storecode: storeId,
      })}`,
      (res: any) => {
        setLoading(false);
        setReportData(res?.data.data);
      },
      (err: any) => {
        setLoading(false);
        setReportData([]);
        if (err.response.data.data.length === 0) {
          setReportData([]);
          dispatch(
            openSnackbar({
              messege: get(err, "response.data.message", "No Logs Found"),
              severity: "error",
              autHideDuriation: 4000,
            })
          );
        }
      },
      {}
    );
  }

  useEffect(() => {
    let match;
    setLoading(true);

    match = JSON.stringify({
      date: { $gt: selectedDate[0], $lte: selectedDate[1] },
    });

    API.fetchWithParams(
      `${API_CONSTANTS.GET_MONTHLY_REPORTS}`,
      (res: any) => {
        setLoading(false);
        setResultData(res?.data.data);
        setReportData(res?.data.data);
      },
      (err: any) => {
        setLoading(false);
        setResultData([]);
        if (err.response.data.data.length === 0) {
          setResultData([]);
          dispatch(
            openSnackbar({
              messege: get(err, "response.data.message", "No Logs Found"),
              severity: "error",
              autHideDuriation: 4000,
            })
          );
        }
      },
      {
        match: match,
      }
    );
  }, [selectedDate]);

  useEffect(() => {
    if (stateValue !== null && cityValue === null) {
      setLoading(true);
      let stateResult = resultData?.filter(
        (eachObj: any) => eachObj.storeDetails.state === stateValue
      );
      setLoading(false);
      setReportData(stateResult);
    }

    if (cityValue !== null) {
      setLoading(true);
      const cityResult = resultData?.filter(
        (eachObj: any) => eachObj.storeDetails.city === cityValue
      );
      setLoading(false);
      setReportData(cityResult);
    }
  }, [stateValue, cityValue]);

  let stateArray = state?.map((i: any) => {
    return { stateName: i };
  });

  const cityArray = city?.map((i: any) => {
    return { cityName: i };
  });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const handleClickAway = () => {
    setOpen(false);
  };
  const cleanUp = () => {
    setTotalRowSize(0);
    SetActionDatas([]);
  };
  useEffect(() => {
    let match;
    if (stateValue !== null && cityValue === null) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && stateValue !== null) {
      match = JSON.stringify({ city: cityValue });
    } else {
      match = JSON.stringify({});
    }
    API.fetchWithParams(
      API_CONSTANTS.GET_STORE_DATA,
      (res: any) => {
        setStore(res.data?.data);
      },
      (err: any) => {
        if (err.response.status <= 400 && err.response.status >= 500) {
          localStorage.clear();
          // navigate('/login', { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
        match: match,
      }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_STATES,
      (res: any) => {
        setState(res?.data?.data);
      },
      (err: any) => {
        if (err.response.status <= 400 && err.response.status > 500) {
          localStorage.clear();

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
      }
    );
    if (stateValue !== null) {
      API.fetchWithParams(
        API_CONSTANTS.GET_CITIES,
        (res: any) => {
          setCity(res?.data?.data);
        },
        (err: any) => {
          if (err.response.status <= 400 && err.response.status > 500) {
            localStorage.clear();
            // navigate('/login', { replace: true });

            dispatch(
              openSnackbar({
                messege: get(err, "", "Token Expired! Log In Again"),
                severity: "error",
              })
            );
          }
        },
        {
          brandId: "62d1157242a9f208b4388b8a",
          state: `${stateValue}`,
        }
      );
    }
  }, [stateValue, cityValue]);
  return (
    <div>
      <Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress sx={{ color: "green" }} />
        </Backdrop>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8.5} md={9} lg={10} xl={10}>
          <Typography variant="h4" style={{ fontSize: "23px" }}>
            Monthly Report
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={2.5}
          lg={1.5}
          xl={1.5}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button
            aria-describedby={id}
            sx={{
              // ml: 5,
              backgroundColor: "#A0D5EF",
              color: "black",
              boxShadow: "1px 1px 1px 1px #DCDCDC",
              "&.Mui-disabled": {
                background: "#eaeaea",
                color: "#c0c0c0",
              },
              "&:hover": {
                backgroundColor: "#A0D5EF",
              },
              width: "100%",
            }}
            onClick={handleClick}
            variant="contained"
            startIcon={<FilterAltIcon />}
          >
            Filter
          </Button>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            transition
            sx={{ width: 350 }}
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography sx={{ p: 3 }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ pb: 2 }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopDatePicker
                            label="Select Month"
                            views={["year", "month"]}
                            value={selectedDate[0] ? selectedDate[0] : null}
                            onMonthChange={(date1) => {
                              const date = get(date1, "_d");
                              setStoreValue(null);
                              setStateValue(null);
                              setCityValue(null);
                              setSelectedDate([
                                moment(date)
                                  .startOf("month")
                                  .format("YYYY-MM-DD"),
                                moment(date)
                                  .endOf("month")
                                  // .add(1, 'days')
                                  .format("YYYY-MM-DD"),
                              ]);
                              cleanUp();
                            }}
                            onChange={(date) => {}}
                            disableFuture={true}
                            renderInput={(params) => (
                              <TextField sx={{ width: "100%" }} {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <div style={{ marginTop: "20px" }}>
                        <Autocomplete
                          id="storeName"
                          style={{ width: "100%" }}
                          options={stateArray}
                          clearOnBlur={true}
                          getOptionLabel={(option: any) => option.stateName}
                          sx={{ width: 300 }}
                          value={
                            stateValue !== null
                              ? stateArray?.find(
                                  (item: any) => item.stateName === stateValue
                                )
                              : null
                          }
                          placeholder="Select State"
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                style: {
                                  color: "green",
                                  fontSize: "20px",
                                  fontFamily: "Inter",
                                },
                              }}
                              variant="standard"
                              placeholder="Select state"
                            />
                          )}
                          onChange={(event: any, newValue: any, reason) => {
                            if (reason === "clear") {
                              setCityValue(null);
                              setStateValue(null);
                              setStoreValue(null);
                              setCurrentpage(1);
                              cleanUp();
                              return;
                            } else {
                              setCityValue(null);
                              setStoreValue(null);
                              setStateValue(newValue.stateName);
                              setCurrentpage(1);
                              cleanUp();
                            }
                          }}
                        />
                      </div>
                      <div>
                        <div style={{ marginTop: "20px" }}>
                          <Autocomplete
                            id="storeName"
                            style={{ width: "100%" }}
                            options={cityArray}
                            getOptionLabel={(option: any) => option.cityName}
                            clearOnBlur={true}
                            value={
                              cityValue !== null
                                ? cityArray?.find(
                                    (item: any) => item.cityName === cityValue
                                  )
                                : null
                            }
                            disabled={stateValue === null || !cityArray}
                            placeholder="Select City"
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                defaultValue=""
                                InputLabelProps={{
                                  style: {
                                    color: "green",
                                    fontSize: "20px",
                                    fontFamily: "Inter",
                                  },
                                }}
                                variant="standard"
                                placeholder="Select City"
                              />
                            )}
                            onChange={(event: any, newValue: any, reason) => {
                              if (reason === "clear") {
                                setCityValue(null); //for reset the value()
                                setStoreValue(null);
                                setCurrentpage(1);
                                cleanUp();
                                return;
                              } else {
                                setCityValue(newValue.cityName);
                                setStoreValue(null);
                                setCurrentpage(1);
                                cleanUp();
                              }
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <Autocomplete
                            id="storeName"
                            style={{ width: "100%" }}
                            options={store}
                            value={
                              storeValues !== null
                                ? store?.find(
                                    (item: any) => item.storeId === storeValues
                                  )
                                : null
                            }
                            getOptionLabel={(option: any) => option?.storeName}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option?.storeName} - {option?.city}
                              </Box>
                            )}
                            placeholder="Select Store"
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  style: {
                                    color: "green",
                                    fontSize: "20px",
                                    fontFamily: "Inter",
                                  },
                                }}
                                variant="standard"
                                placeholder="Select store"
                              />
                            )}
                            onChange={(event: any, newValue: any, reason) => {
                              if (reason === "clear") {
                                setStoreValue(null);
                                setCurrentpage(1);
                                cleanUp();
                                return;
                              } else {
                                setStoreValue(newValue.storeId);
                                UpdateFields1(newValue.storeId);
                                setCurrentpage(1);
                                cleanUp();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Typography>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </Grid>
        {/* <Grid item xs={12} md={12} lg={4} xl={4} sm={4}>
          <Autocomplete
            clearOnEscape
            id='storeName'
            style={{ width: '100%' }}
            options={analyticsData}
            getOptionLabel={(option: any) => option.storeName}
            renderOption={(props, option) => (
              <Box component='li' {...props}>
                {option.storeName} - {option.city}
              </Box>
            )}
            sx={{ width: 300 }}
            placeholder=' Select Store'
            renderInput={(params) => (
              <TextField
                {...params}
                // label="StoreName"
                InputLabelProps={{
                  style: {
                    color: 'green',
                    fontSize: '20px',
                    fontFamily: 'Inter',
                  },
                }}
                variant='standard'
                placeholder='Select store'
              />
            )}
            onChange={(event: any, newValue: any) => {
              setValue(newValue.storeId);
              
            }}
          />
        </Grid> */}
      </Grid>

      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={12} sm={12} md={12}>
          <Card sx={{ p: 2 }}>
            <CustomGrid
              handleCellClick={undefined}
              loading={loading}
              columnTypes={columnTypes}
              disableClickSelectionRenderers={false}
              suppressRowClickSelection
              columnDefs={columnDef2}
              noDataTxt="No Data..."
              rowData={reportData}
              TableHeight={70}
              rowSelection="single"
              currentPage={0}
              serverSidePagination={false}
              setServerSidePage={undefined}
              totalDataCount={0}
              rowHeight={rowHeight}
              rowStyle={rowStyle}
              headerHeight={headerHeight}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default GenerateReport;
