import { IdrawerOptions } from "../components/CustomDrawer";

// import AnalyticsIcon from '@mui/icons-material/Analytics';

export const drawerOptions: IdrawerOptions[] = [
  {
    label: "Call Log",
    path: "/manager/calllog",
    icon: "phone_in_talk",
  },
  {
    label: "Discovery",
    path: "/manager/dashBoard",
    icon: "person_search_sharp",
  },
  {
    label: "Action",
    path: "/manager/action",
    icon: "equalizerIcon",
  },
  {
    label: "Articles",
    path: "/manager/articles",
    icon: "descriptionIcon",
  },
  {
    label: "Wati",
    path: "/admin/wati",
    icon: "WhatsAppIcon",
  },
];
