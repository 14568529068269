import React, { useEffect, useMemo, useState } from "react";
import BarChat from "../../components/BarCharts";
import {
  Card,
  Grid,
  Typography,
  Skeleton,
  TextField,
  Autocomplete,
  CircularProgress,
  Button,
  ClickAwayListener,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import * as _ from "lodash";
import ActionTable from "./ActionTable";
import API from "../../services/apis/api";
import { API_CONSTANTS } from "../../constants";
import ActionDetailsCard from "./ActionDetailsCard";
import Backdrop from "@mui/material/Backdrop";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import { get } from "lodash";
import { useAppDispatch } from "../../hooks";
import { openSnackbar } from "../../redux/features/snackBarSlice";
import { useNavigate } from "react-router-dom";
import { getLocalStorageItem } from "../../services/localStorage";
import axios from "axios";

const FileDownload = require("js-file-download");
export const GraphWrapper = styled(Paper)(({ theme }) => ({
  //boxShadow: "3px 3px 3px 3px #DCDCDC",
  // borderRadius: 0.4,
  cursor: "pointer",
  width: "100%",
}));

export const Heading = styled("div")(({ theme }) => ({
  marginBottom: 5,
}));
export const TableContainer = styled("div")(({ theme }) => ({
  marginTop: 20,
}));
const { allowedRange }: any = DateRangePicker;
function Actions() {
  const [totalViews, setTotalViews] = useState({});
  const [searchViews, setSearchViews] = useState({});
  const [mapsViews, setMapsViews] = useState({});
  const [totalActions, setTotalActions] = useState({});
  const [websiteActions, setWebsiteActions] = useState({});
  const [directionsActions, setDirectionsActions] = useState({});

  const [loading, setLoading] = useState(false);

  const [value] = useState<any[]>([]);
  const [stateValue, setStateValue] = useState<any>(null);
  const [cityValue, setCityValue] = useState<any>(null);
  const [storeValues, setStoreValue] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<any>([]);
  const [updateData] = useState(true);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentpage, setCurrentpage] = useState(1);
  const [store, setStore] = useState<any>(null);
  const [download, setDownload] = useState(false);
  const [state, setState] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [dates] = useState({ fromDate: "", toDate: "" });
  const [actionDatas, SetActionDatas] = useState([]);
  const [totalRowSize, setTotalRowSize]: any = useState(10);
  const [rowSize]: any = useState(10);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    let match;
    if (stateValue !== null && cityValue === null && storeValues !== null) {
      match = JSON.stringify({ state: stateValue, storeId: storeValues });
    } else if (
      stateValue !== null &&
      cityValue === null &&
      storeValues === null
    ) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && storeValues === null) {
      match = JSON.stringify({ city: cityValue });
    } else if (cityValue !== null && storeValues !== null) {
      match = JSON.stringify({ city: cityValue, storeId: storeValues });
    } else if (
      storeValues !== null &&
      cityValue === null &&
      stateValue === null
    ) {
      match = JSON.stringify({ storeId: storeValues });
    } else {
      match = JSON.stringify({});
    }

    API.fetchWithParams(
      API_CONSTANTS.GET_ANALYTICS_GRAPH,
      (res: any) => {
        setLoading(false);
        setTotalViews(_.get(res, "data.data.data", [])[0]);
      },
      (err: any) => {
        setLoading(false);
        console.error(err);
      },
      { match: match, type: "GoogleSearchMobile" }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_ANALYTICS_GRAPH,
      (res: any) => {
        setLoading(false);
        setSearchViews(_.get(res, "data.data.data", [])[0]);
      },
      (err: any) => {
        setLoading(false);
        console.error(err);
      },
      { match: match, type: "GoogleSearchDesktop" }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_ANALYTICS_GRAPH,
      (res: any) => {
        setLoading(false);
        setMapsViews(_.get(res, "data.data.data", [])[0]);
      },
      (err: any) => {
        setLoading(false);
        console.error(err);
      },
      { match: match, type: "GoogleMapsMobile" }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_ANALYTICS_GRAPH,
      (res: any) => {
        setLoading(false);
        setTotalActions(_.get(res, "data.data.data", [])[0]);
      },
      (err: any) => {
        setLoading(false);
        console.error(err);
      },
      { match: match, type: "GoogleMapsDesktop" }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_ANALYTICS_GRAPH,
      (res: any) => {
        setLoading(false);
        setWebsiteActions(_.get(res, "data.data.data", [])[0]);
      },
      (err: any) => {
        setLoading(false);
        console.error(err);
      },
      { match: match, type: "Calls" }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_ANALYTICS_GRAPH,
      (res: any) => {
        setLoading(false);
        setDirectionsActions(_.get(res, "data.data.data", [])[0]);
      },
      (err: any) => {
        setLoading(false);
        console.error(err);
      },
      { match: match, type: "Directions" }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_ANALYTICS_GRAPH,
      (res: any) => {
        setLoading(false);
      },
      (err: any) => {
        setLoading(false);
        console.error(err);
      },
      { match: match, type: "PhoneCallActions" }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_STORE_DATA,
      (res: any) => {
        setLoading(false);
      },
      (err: any) => {
        setLoading(false);
        console.error(err);
        if (err.response.status <= 400 && err.response.status >= 500) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
      }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_AVALILABLE_DATES,
      (res: any) => {
        setLoading(false);
        setToDate(moment(res.data.data.endDate).format("DD-MMM-YYYY"));
      },
      (err: any) => {
        setLoading(false);
      },
      {}
    );
  }, [value, storeValues, stateValue, cityValue, selectedDate]);
  const lables = ["7 Days", "14 Days", "28 Days", "3 Months", "6 Months"];

  useEffect(() => {
    API.fetchWithParams(
      API_CONSTANTS.GET_AVALILABLE_DATES,
      (res: any) => {
        setLoading(false);
        setFromDate(moment(res.data.data.startDate).format("YYYY-MM-DD"));
        setToDate(moment(res.data.data.endDate).format("YYYY-MM-DD"));
        setSelectedDate([
          moment(res.data.data.endDate).format("YYYY-MM-DD"),
          moment(res.data.data.endDate).format("YYYY-MM-DD"),
        ]);
      },
      (err: any) => {
        setLoading(false);
      },
      {}
    );
  }, []);

  let Total = useMemo(() => {
    return _.values(totalViews).slice(1);
  }, [totalViews]);
  let Search = useMemo(() => {
    return _.values(searchViews).slice(1);
  }, [searchViews]);
  let Map = useMemo(() => {
    return _.values(mapsViews).slice(1);
  }, [mapsViews]);
  let TlAction = useMemo(() => {
    return _.values(totalActions).slice(1);
  }, [totalActions]);
  let WebAction = useMemo(() => {
    return _.values(websiteActions).slice(1);
  }, [websiteActions]);
  let DirectActions = useMemo(() => {
    return _.values(directionsActions).slice(1);
  }, [directionsActions]);
  useEffect(() => {
    let match;
    if (stateValue !== null && cityValue === null && storeValues !== null) {
      match = JSON.stringify({ state: stateValue, storeId: storeValues });
    } else if (
      stateValue !== null &&
      cityValue === null &&
      storeValues === null
    ) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && storeValues === null) {
      match = JSON.stringify({ city: cityValue });
    } else if (cityValue !== null && storeValues !== null) {
      match = JSON.stringify({ city: cityValue, storeId: storeValues });
    } else if (
      storeValues !== null &&
      cityValue === null &&
      stateValue === null
    ) {
      match = JSON.stringify({ storeId: storeValues });
    } else {
      match = JSON.stringify({});
    }

    API.fetchWithParams(
      API_CONSTANTS.GET_ACTION_DATA,
      (res: any) => {
        setLoading(false);
        SetActionDatas(_.get(res, "data.data.result", []));
        setTotalRowSize(_.get(res, "data.data.totalRecords", 0));
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status >= 400 && err.response.status < 500) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        fromDate: moment(selectedDate[0]).format("YYYY-MM-DD"),
        toDate: moment(selectedDate[1]).add(1, "day").format("YYYY-MM-DD"),
        pageNo: currentpage,
        size: rowSize,
        match: match,
      }
    );
  }, [
    stateValue,
    selectedDate,
    cityValue,
    storeValues,
    updateData,
    totalRowSize,
  ]);
  const cleanUp = () => {
    setTotalRowSize(0);
    SetActionDatas([]);
  };

  useEffect(() => {
    let match;
    if (stateValue !== null && cityValue === null) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && stateValue !== null) {
      match = JSON.stringify({ city: cityValue });
    } else {
      match = JSON.stringify({});
    }
    API.fetchWithParams(
      API_CONSTANTS.GET_STORE_DATA,
      (res: any) => {
        setLoading(false);
        setStore(res.data?.data);
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status <= 400 && err.response.status >= 500) {
          localStorage.clear();
          // navigate('/login', { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
        match: match,
      }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_STATES,
      (res: any) => {
        setState(res?.data?.data);
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status <= 400 && err.response.status > 500) {
          localStorage.clear();

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
      }
    );
    if (stateValue !== null) {
      API.fetchWithParams(
        API_CONSTANTS.GET_CITIES,
        (res: any) => {
          setCity(res?.data?.data);
        },
        (err: any) => {
          setLoading(false);
          if (err.response.status <= 400 && err.response.status > 500) {
            localStorage.clear();
            // navigate('/login', { replace: true });

            dispatch(
              openSnackbar({
                messege: get(err, "", "Token Expired! Log In Again"),
                severity: "error",
              })
            );
          }
        },
        {
          brandId: "62d1157242a9f208b4388b8a",
          state: `${stateValue}`,
        }
      );
    }
  }, [stateValue, cityValue]);
  useEffect(() => {
    if (totalRowSize > 0) {
      if (totalRowSize < rowSize) {
        setPageCount(Math.ceil(totalRowSize / rowSize - 1));
      } else {
        setPageCount(Math.ceil(totalRowSize / rowSize));
      }
    }
  }, [
    storeValues,
    dates.toDate,
    rowSize,
    currentpage,
    totalRowSize,
    selectedDate,
    pageCount,
  ]);

  let stateArray = state?.map((i: any) => {
    return { stateName: i };
  });

  const cityArray = city?.map((i: any) => {
    return { cityName: i };
  });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const Token = getLocalStorageItem("token");
  const onDownload = (path: any, name: string) => {
    setDownload(true);
    axios({
      url: path, //your url
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        setDownload(false);
        FileDownload(res.data, `Close_Bi_${name}.csv`);
      })
      .catch((error) => {
        setDownload(false);
        dispatch(
          openSnackbar({
            messege: get(error, "response.data.message", " Try again later"),
            severity: "error",
          })
        );
        console.error("download file error", error);
      });
  };
  const handleClickAway = () => {
    setOpen(false);
  };
  const selectDate = moment(selectedDate[1]).format("YYYY-MM-DD");
  return (
    <div>
      <Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress sx={{ color: "green" }} />
        </Backdrop>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={8} md={8} lg={8} xl={8} sm={8}>
          <Heading sx={{ mt: 1, ml: 1 }}>
            <Typography variant="h4" style={{ fontSize: "25px" }}>
              Actions
            </Typography>
          </Heading>
        </Grid>

        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button
            aria-describedby={id}
            sx={{
              backgroundColor: "#A0D5EF",
              color: "black",
              boxShadow: "1px 1px 1px 1px #DCDCDC",
              "&.Mui-disabled": {
                background: "#eaeaea",
                color: "#c0c0c0",
              },
              "&:hover": {
                backgroundColor: "#A0D5EF",
              },
            }}
            disabled={Total.length === 0}
            onClick={handleClick}
            variant="contained"
            startIcon={<FilterAltIcon />}
          >
            Filter
          </Button>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            transition
            sx={{ width: "360px" }}
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography sx={{ p: 3 }}>
                      <p> Select Date</p>
                      <Grid
                        item
                        xs={7}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <DateRangePicker
                          placeholder={moment(selectedDate?.[0]).format(
                            "DD/MMM/YYYY"
                          )}
                          format="dd-MMM-yyyy"
                          disabledDate={allowedRange(fromDate, toDate)}
                          style={{
                            width: "700px",
                          }}
                          onChange={(dates) => {
                            if (dates) {
                              setSelectedDate(dates);
                              cleanUp();
                            }
                          }}
                        />
                      </Grid>
                      <div style={{ marginTop: "20px" }}>
                        <Autocomplete
                          id="storeName"
                          style={{ width: "100%" }}
                          options={stateArray}
                          clearOnBlur={true}
                          getOptionLabel={(option: any) => option.stateName}
                          sx={{ width: 300 }}
                          value={
                            stateValue !== null
                              ? stateArray?.find(
                                  (item: any) => item.stateName === stateValue
                                )
                              : null
                          }
                          placeholder="Select State"
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                style: {
                                  color: "green",
                                  fontSize: "20px",
                                  fontFamily: "Inter",
                                },
                              }}
                              variant="standard"
                              placeholder="Select state"
                            />
                          )}
                          onChange={(event: any, newValue: any, reason) => {
                            if (reason === "clear") {
                              setCityValue(null);
                              setStateValue(null);
                              setStoreValue(null);
                              setCurrentpage(1);
                              return;
                            } else {
                              setStateValue(newValue.stateName);
                              setCurrentpage(1);
                            }
                          }}
                        />
                      </div>
                      <div>
                        <div style={{ marginTop: "20px" }}>
                          <Autocomplete
                            id="storeName"
                            style={{ width: "100%" }}
                            options={cityArray}
                            getOptionLabel={(option: any) => option?.cityName}
                            clearOnBlur={true}
                            value={
                              cityValue !== null
                                ? cityArray?.find(
                                    (item: any) => item.cityName === cityValue
                                  )
                                : null
                            }
                            disabled={stateValue === null || !cityArray}
                            placeholder="Select City"
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                defaultValue=""
                                InputLabelProps={{
                                  style: {
                                    color: "green",
                                    fontSize: "20px",
                                    fontFamily: "Inter",
                                  },
                                }}
                                variant="standard"
                                placeholder="Select City"
                              />
                            )}
                            onChange={(event: any, newValue: any, reason) => {
                              if (reason === "clear") {
                                setCityValue(null); //for reset the value()
                                setStoreValue(null);
                                setCurrentpage(1);
                                return;
                              } else {
                                setCityValue(newValue.cityName);
                                setCurrentpage(1);
                              }
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <Autocomplete
                            id="storeName"
                            style={{ width: "100%" }}
                            options={store}
                            value={
                              storeValues !== null
                                ? store?.find(
                                    (item: any) => item.storeId === storeValues
                                  )
                                : null
                            }
                            getOptionLabel={(option: any) => option?.storeName}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option?.storeName} - {option?.city}
                              </Box>
                            )}
                            sx={{ width: 300 }}
                            placeholder=" Select Store"
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  style: {
                                    color: "green",
                                    fontSize: "20px",
                                    fontFamily: "Inter",
                                  },
                                }}
                                variant="standard"
                                placeholder="Select Store"
                              />
                            )}
                            onChange={(event: any, newValue: any, reason) => {
                              if (reason === "clear") {
                                setStoreValue(null);
                                setCurrentpage(1);
                                return;
                              } else {
                                setStoreValue(newValue.storeId);
                                setCurrentpage(1);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Typography>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          style={{
            display: "flex",
            alignItems: "center",
            // marginLeft: '-100px',
          }}
        >
          <Button
            variant="contained"
            sx={{
              mx: 1,
              my: 2,
              backgroundColor: "#A0D5EF",
              color: "black",
              boxShadow: "1px 1px 1px 1px #DCDCDC",
              "&.Mui-disabled": {
                background: "#eaeaea",
                color: "#c0c0c0",
              },
              "&:hover": {
                backgroundColor: "#A0D5EF",
              },
            }}
            onClick={() => {
              let match;
              if (
                stateValue !== null &&
                cityValue === null &&
                storeValues !== null
              ) {
                match = JSON.stringify({
                  state: stateValue,
                  storeId: storeValues,
                });
              } else if (
                stateValue !== null &&
                cityValue === null &&
                storeValues === null
              ) {
                match = JSON.stringify({ state: stateValue });
              } else if (cityValue !== null && storeValues === null) {
                match = JSON.stringify({ city: cityValue });
              } else if (cityValue !== null && storeValues !== null) {
                match = JSON.stringify({
                  city: cityValue,
                  storeId: storeValues,
                });
              } else if (
                storeValues !== null &&
                cityValue === null &&
                stateValue === null
              ) {
                match = JSON.stringify({ storeId: storeValues });
              } else {
                match = JSON.stringify({});
              }

              onDownload(
                `${process.env.REACT_APP_BACKEND_API_PROXY}${
                  API_CONSTANTS.DOWNLOAD_ANALYTICS_IN_RANGE
                }?fromDate=${moment(selectedDate[0]).format(
                  "YYYY-MM-DD"
                )}&toDate=${moment(selectedDate[1])
                  .add(1, "day")
                  .format("YYYY-MM-DD")}&match=${match}`,
                `${moment(selectedDate[0]).format("YYYY-MM-DD")}_${moment(
                  selectedDate[1]
                ).format("YYYY-MM-DD")}&storeCode=${storeValues}`
              );
            }}
            disabled={
              download || totalRowSize === 0 || actionDatas.length === 0
            }
          >
            <Typography style={{ color: "black", fontSize: "16px" }}>
              {download ? "Please Wait..." : "Export Report"}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Card
            style={{
              padding: "10px",
              borderRadius: "10px",
              boxShadow: "3px 3px 3px 3px #DCDCDC",
            }}
          >
            <Box>
              {Total.length > 0 ? (
                <BarChat
                  data={{
                    labels: lables,
                    datasets: [
                      {
                        data: Total.length < 0 ? [0, 0, 0, 0] : Total,
                        barPercentage: 0.4,
                        backgroundColor: [
                          "#BDB7B7",
                          "#EDD069",
                          "#EDBEBF",
                          "#92F194",
                          "#A0D5EF",
                        ],
                      },
                    ],
                  }}
                  label={lables}
                  title={"Google Search Mobile"}
                />
              ) : (
                <Box>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton
                    height={100}
                    variant="rectangular"
                    animation="wave"
                  />
                </Box>
              )}
            </Box>
            <Box>
              {Total.length > 0 ? (
                <ActionDetailsCard data={totalViews} endDate={selectDate} />
              ) : (
                <Skeleton height={50} variant="rectangular" animation="wave" />
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Card
            style={{
              padding: "10px",
              borderRadius: "10px",
              boxShadow: "3px 3px 3px 3px #DCDCDC",
            }}
          >
            <Box>
              {Search.length > 0 ? (
                <BarChat
                  data={{
                    labels: lables,
                    datasets: [
                      {
                        data: Search.length < 0 ? [0, 0, 0, 0] : Search,
                        barPercentage: 0.4,
                        backgroundColor: [
                          "#BDB7B7",
                          "#EDD069",
                          "#EDBEBF",
                          "#92F194",
                          "#A0D5EF",
                        ],
                      },
                    ],
                  }}
                  label={lables}
                  title={"Google Search Desktop"}
                />
              ) : (
                <Box>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton
                    height={100}
                    variant="rectangular"
                    animation="wave"
                  />
                </Box>
              )}
            </Box>
            <Box>
              {Search.length > 0 ? (
                <ActionDetailsCard data={searchViews} endDate={selectDate} />
              ) : (
                <Skeleton height={50} variant="rectangular" animation="wave" />
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Card
            style={{
              padding: "10px",
              borderRadius: "10px",
              boxShadow: "3px 3px 3px 3px #DCDCDC",
            }}
          >
            <Box>
              {Map.length > 0 ? (
                <BarChat
                  data={{
                    labels: lables,
                    datasets: [
                      {
                        data: Map.length < 0 ? [0, 0, 0, 0] : Map,
                        barPercentage: 0.4,
                        backgroundColor: [
                          "#BDB7B7",
                          "#EDD069",
                          "#EDBEBF",
                          "#92F194",
                          "#A0D5EF",
                        ],
                      },
                    ],
                  }}
                  label={lables}
                  title={"Google Maps Mobile"}
                />
              ) : (
                <Box>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton
                    height={100}
                    variant="rectangular"
                    animation="wave"
                  />
                </Box>
              )}
            </Box>
            <Box>
              {Map.length > 0 ? (
                <ActionDetailsCard data={mapsViews} endDate={selectDate} />
              ) : (
                <Skeleton height={50} variant="rectangular" animation="wave" />
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: "1px", height: "380px" }}>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Card
            style={{
              padding: "10px",
              borderRadius: "10px",
              boxShadow: "3px 3px 3px 3px #DCDCDC",
            }}
          >
            <Box>
              {TlAction.length > 0 ? (
                <BarChat
                  data={{
                    labels: lables,
                    datasets: [
                      {
                        data: TlAction.length < 0 ? [0, 0, 0, 0] : TlAction,
                        barPercentage: 0.4,
                        backgroundColor: [
                          "#BDB7B7",
                          "#EDD069",
                          "#EDBEBF",
                          "#92F194",
                          "#A0D5EF",
                        ],
                      },
                    ],
                  }}
                  label={lables}
                  title={"Google Maps Desktop"}
                />
              ) : (
                <Box>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton
                    height={100}
                    variant="rectangular"
                    animation="wave"
                  />
                </Box>
              )}
            </Box>
            <Box>
              {TlAction.length > 0 ? (
                <ActionDetailsCard data={totalActions} endDate={selectDate} />
              ) : (
                <Skeleton height={50} variant="rectangular" animation="wave" />
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Card
            style={{
              padding: "10px",
              borderRadius: "10px",
              boxShadow: "3px 3px 3px 3px #DCDCDC",
            }}
          >
            <Box>
              {WebAction.length > 0 ? (
                <BarChat
                  data={{
                    labels: lables,
                    datasets: [
                      {
                        data: WebAction.length < 0 ? [0, 0, 0, 0] : WebAction,
                        barPercentage: 0.4,
                        backgroundColor: [
                          "#BDB7B7",
                          "#EDD069",
                          "#EDBEBF",
                          "#92F194",
                          "#A0D5EF",
                        ],
                      },
                    ],
                  }}
                  label={lables}
                  title={"Calls"}
                />
              ) : (
                <Box>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton
                    height={100}
                    variant="rectangular"
                    animation="wave"
                  />
                </Box>
              )}
            </Box>
            <Box>
              {WebAction.length > 0 ? (
                <ActionDetailsCard data={websiteActions} endDate={selectDate} />
              ) : (
                <Skeleton height={50} variant="rectangular" animation="wave" />
              )}
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Card
            style={{
              padding: "10px",
              borderRadius: "10px",
              boxShadow: "3px 3px 3px 3px #DCDCDC",
            }}
          >
            <Box>
              {DirectActions.length > 0 ? (
                <BarChat
                  data={{
                    labels: lables,
                    datasets: [
                      {
                        data:
                          DirectActions.length < 0
                            ? [0, 0, 0, 0]
                            : DirectActions,
                        barPercentage: 0.4,
                        backgroundColor: [
                          "#BDB7B7",
                          "#EDD069",
                          "#EDBEBF",
                          "#92F194",
                          "#A0D5EF",
                        ],
                      },
                    ],
                  }}
                  label={lables}
                  title={"Directions Actions"}
                />
              ) : (
                <Box>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton
                    height={100}
                    variant="rectangular"
                    animation="wave"
                  />
                </Box>
              )}
            </Box>
            <Box>
              {DirectActions.length > 0 ? (
                <ActionDetailsCard
                  data={directionsActions}
                  endDate={selectDate}
                />
              ) : (
                <Skeleton height={50} variant="rectangular" animation="wave" />
              )}
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ActionTable
            stateValue={stateValue}
            cityValue={cityValue}
            selectedDate={selectedDate}
            storeValue={storeValues}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Actions;
