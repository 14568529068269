import { Box, Typography } from "@mui/material";
import moment from "moment";
import { keys, map } from "lodash";
import { styled, useTheme } from "@mui/material/styles";

interface Iprops {
  data: any;
  endDate: any;
}
const ActionDetailsCard = ({ data, endDate }: Iprops) => {
  const keylist = keys(data).slice(1);
  const theme = useTheme();
  const Content = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
      marginLeft: "180px",
    },
  }));

  return (
    <Box sx={{ margin: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              justifyContent: "center",
              fontSize: "14px",
              color: "black",
            }}
          >
            {" "}
            {endDate &&
              moment(new Date(endDate))
                .subtract(180, "days")
                .format("DD MMM YYYY")}{" "}
            <span> - </span>
            {moment(new Date(endDate)).format("DD MMM YYYY")}
            <hr
              style={{
                borderBottom: "1px dashed #999",
                textDecoration: "none",
                width: "90%",
                margin: "0 auto",
              }}
            />
          </Typography>
        </Box>
        <Content>
          <Box sx={{ flexGrow: 1, textAlign: "left" }}>
            {map(keylist, (value, idx) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "5px",
                    marginLeft: "100px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      width: theme.spacing(20),
                      fontSize: "12px",
                      color: "black",
                      // margin: "0 auto",
                      // textAlign: "justify",
                    }}
                  >
                    {value}
                  </Typography>
                  <Typography
                    sx={{ width: theme.spacing(20), fontSize: "12px" }}
                  >
                    {/* {data[value] < 1000
                      ? data[value]
                      : (data[value] / 1000).toFixed(2) + 'k'}{' '} */}
                    {(() => {
                      if (data[value] < 1000) {
                        return data[value];
                      } else if (data[value] > 1000) {
                        return (data[value] / 1000).toFixed(2) + "k";
                      } else if (data[value] === null ? "-" : "#") {
                        return "-";
                      }
                    })()}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Content>
      </Box>
    </Box>
  );
};

export default ActionDetailsCard;
