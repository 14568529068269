import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function VerticalBarChart(props: any) {
  const { title, data } = props;

  const options: any = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: title,
        fontSize: 40,
      },
      tooltip: {
        callbacks: {
          beforeTitle: function (context: any) {
            return title;
          },
        },
      },

      legend: {
        position: "top",
        display: true,
      },
    },
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    scales: {
      yAxes: {
        ticks: {
          callback: function (datas: any) {
            if (datas < 1000) {
              return datas;
            } else if (datas < 900000) {
              return datas / 1000 + "k";
            }
            return datas / 1000000 + "M";
          },
        },
      },
    },
  };
  return <Bar data={data} options={options} />;
}

export default VerticalBarChart;
