import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Typography, CircularProgress, Backdrop, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import CustomGrid from "../../components/CustomGrid";
import { Card, CardContent } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_CONSTANTS } from "../../constants/index";
import { useIsMount } from "../../hooks";
import Moment from "react-moment";
import * as _ from "lodash";
import API from "../../services/apis/api";
import { DateRangePicker } from "rsuite";
import { startOfDay, endOfDay, subDays } from "date-fns";
import "rsuite/dist/rsuite.min.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { openSnackbar } from "../../redux/features/snackBarSlice";
import moment from "moment";
import { get } from "lodash";

// export const LoadSpinner = styled("div")(({ theme }) => ({
//   marginTop: "-39px",
//   textAlign: "center",
// }));

function DeletePage() {
  const [actionDatas, SetActionDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const gridOptions: any = useRef();
  const [selectedDate, setSelectedDate] = useState<any>([
    moment().format(),
    moment().add(1, "day").format(),
  ]);

  const isMount = useIsMount();
  const [currentpage, setCurrentpage] = useState(1);
  const [rowSize, setRowSize]: any = useState(10);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [deleteData, setDeleteData] = useState(false);
  const [totalRowSize, setTotalRowSize]: any = useState(10);
  let navigate = useNavigate();
  const { allowedRange }: any = DateRangePicker;
  const dispatch = useAppDispatch();
  const headerHeight = 50;
  const rowHeight = 60;
  const rowStyle = {
    // borderBottom: 'white 8px solid',
    borderTop: "white 8px solid",
  };
  const cleanUp = () => {
    setTotalRowSize(0);
    SetActionDatas([]);
  };

  function updateFields(date: any) {
    setLoading(true);
    API.fetchWithParams(
      API_CONSTANTS.GET_ACTION_DATA,
      (res: any) => {
        setLoading(false);
        SetActionDatas(_.get(res, "data.data.result", []));
        setTotalRowSize(_.get(res, "data.data.totalRecords", 0));
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
        }
      },
      {
        fromDate: moment(date[0]).format("YYYY-MM-DD"),
        toDate: moment(date[1]).add(1, "day").format("YYYY-MM-DD"),
        pageNo: currentpage,
        size: rowSize,
      }
    );
  }
  useEffect(() => {
    updateFields(Date);
  }, []);
  useEffect(() => {
    if (!isMount) {
      updateFields(selectedDate);
    }
    if (totalRowSize > 0) {
      setPageCount(Math.round(totalRowSize / rowSize));
    }
  }, [rowSize, currentpage, isMount, totalRowSize]);
  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 150, filter: "agNumberColumnFilter" },
      medalColumn: { width: 200, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");

            var dateParts = dateAsString.split("/");

            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );

            if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
    };
  }, []);
  const columnDef = [
    {
      field: "Business name",
      headerTooltip: "Business name",
      minWidth: 250,
      cellStyle: () => ({
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
      }),
      filter: "agTextColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "Business name",
      filterParams: {
        filterOptions: ["contains", "equals"],
        defaultOption: "contains",
      },
    },

    {
      field: "Date",
      minWidth: 180,
      resizable: true,
      tooltipField: "Date",
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
      type: ["dateColumn", "nonEditableColumn"],
      cellRendererFramework: (props: any) => {
        const { data } = props;
        return <Moment format="DD-MMM-YYYY">{_.get(data, "Date", "")}</Moment>;
      },
      sortable: true,
    },
    {
      field: "Total searches",
      minWidth: 250,
      filter: "agNumberColumnFilter",
      resizable: true,
      sortable: true,
      tooltipField: "Total searches",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },

    {
      field: "Direct searches",
      minWidth: 250,
      filter: "agNumberColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "Direct searches",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Discovery searches",
      minWidth: 250,
      filter: "agNumberColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "Discovery searches",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Total views",
      minWidth: 250,
      filter: "agNumberColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "Total views",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Search views",
      minWidth: 230,
      filter: "agNumberColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "Search views",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Maps views",
      minWidth: 250,
      filter: "agNumberColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "Maps views",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Total actions",
      minWidth: 250,
      filter: "agNumberColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "Total actions",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Total views",
      minWidth: 250,
      filter: "agNumberColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "Total views",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Website actions",
      minWidth: 250,
      sortable: true,
      resizable: true,
      tooltipField: "Website actions",
      filter: "agNumberColumnFilter",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
    {
      field: "Phone call actions",
      minWidth: 250,
      resizable: true,
      sortable: true,
      filter: "agNumberColumnFilter",
      tooltipField: "Phone call actions",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      filterParams: {
        filterOptions: [
          "equals",
          "greaterThan",
          "lessThan",
          "notEqual",
          "inRange",
        ],
        defaultOption: "equals",
      },
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      width: 200,
      flex: 1,
      resizable: true,
    };
  }, []);
  const defaultColGroupDef = useMemo(() => {
    return {
      marryChildren: true,
    };
  }, []);
  const onGridReady = useCallback((params: any) => {
    gridOptions.current = params;
  }, []);

  useEffect(() => {
    API.fetchWithParams(
      API_CONSTANTS.GET_AVALILABLE_DATES,
      (res: any) => {
        setLoading(false);
        setFromDate(moment(res.data.data.startDate).format("YYYY-MM-DD"));
        setToDate(moment(res.data.data.endDate).format("YYYY-MM-DD"));
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status <= 400 && err.response.status >= 500) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {}
    );
  }, []);

  const onDelete = (path: any, name: string) => {
    setDeleteData(true);
    const Token = localStorage.getItem("token");
    axios({
      url: path, //your url
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        setDeleteData(false);
        SetActionDatas([]);
        setSelectedDate([]);
        setPageCount(0);
        setTotalRowSize(0);

        if (res.data === "No data Found") {
          dispatch(
            openSnackbar({
              messege: get(res, "response.data.message", "No data Found"),
              severity: "error",
            })
          );
        } else
          dispatch(
            openSnackbar({
              messege: get(
                res,
                "response.data.message",
                "Records Deleted Sucessfully"
              ),
              severity: "success",
            })
          );
      })
      .catch((error) => {
        setDeleteData(false);
        SetActionDatas([]);
        dispatch(
          openSnackbar({
            messege: get(
              error,
              "response.data.message",
              " Can't Delete Record"
            ),
            severity: "error",
          })
        );
        console.error("Delete file error", error);
      });
  };

  return (
    <div>
      <Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress sx={{ color: "green" }} />
        </Backdrop>
      </Box>
      <Typography variant="h4" style={{ fontSize: "23px" }}>
        GMB - Delete
      </Typography>

      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <DateRangePicker
            placeholder="Select Date Range"
            format="dd-MMM-yyyy"
            // disabledDate={combine(
            //   allowedMaxDays(31),
            //   combine(before("2022-02-17"), afterToday())
            // )}
            // defaultValue={[
            //   new Date(selectedDate[0]),
            //   new Date(selectedDate[0]),
            // ]}
            style={{ minWidth: "300px" }}
            disabled={loading}
            onEnter={() => {
              setTotalRowSize(0);
              SetActionDatas([]);
              setSelectedDate([]);
            }}
            disabledDate={allowedRange(fromDate, toDate)}
            onChange={(dates) => {
              if (dates) {
                setSelectedDate(dates);
                cleanUp();
                updateFields(dates);
              }
            }}
            ranges={
              loading
                ? []
                : [
                    {
                      label: "Today",
                      value: [startOfDay(new Date()), endOfDay(new Date())],
                    },
                    {
                      label: "Yesterday",
                      value: [
                        startOfDay(subDays(new Date(), 1)),
                        endOfDay(subDays(new Date(), 1)),
                      ],
                    },
                  ]
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={5} xl={5} lg={5}>
          <Button
            variant="contained"
            style={{ borderRadius: "3px", minWidth: "100px" }}
            onClick={() => {
              onDelete(
                `${process.env.REACT_APP_BACKEND_API_PROXY}${
                  API_CONSTANTS.DELETE_ANALYTICS_INRANGE
                }?fromDate=${moment(selectedDate[0]).format(
                  "YYYY-MM-DD"
                )}&toDate=${moment(selectedDate[1])
                  .add(1, "day")
                  .format("YYYY-MM-DD")}`,
                `${moment(selectedDate[0]).format("YYYY-MM-DD")}_${moment(
                  selectedDate[1]
                ).format("YYYY-MM-DD")}`
              );
            }}
            disabled={deleteData || totalRowSize === 0}
          >
            <Typography style={{ color: "#FFFFFF", fontSize: "15px" }}>
              {deleteData ? "Please Wait..." : "Delete"}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography style={{ fontSize: "13px" }}>
            Note: Please Upload YYYY-MM-DD File Format
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
          <Card>
            <Typography
              variant="h6"
              sx={{ mx: 4, my: 1, mt: 2 }}
              style={{
                fontWeight: 500,
                fontSize: "23px",
                color: "#000000",
              }}
            >
              Store Wise analytics
            </Typography>
            <Typography
              sx={{ mx: 4 }}
              style={{
                fontWeight: 400,
                fontSize: "15px",
                color: "#000000",
              }}
            >
              Total Records Found For This Search : {totalRowSize}
            </Typography>
            <CardContent>
              <CustomGrid
                handleCellClick={undefined}
                loading={loading}
                onGridReady={onGridReady}
                suppressExcelExport={true}
                serverSidePagination={true}
                disableClickSelectionRenderers={false}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                defaultColGroupDef={defaultColGroupDef}
                columnTypes={columnTypes}
                suppressMultiRangeSelection={true}
                noDataTxt="No Data..."
                rowData={actionDatas}
                TableHeight={80}
                serverPageCount={pageCount}
                totalDataCount={totalRowSize}
                currentPage={currentpage}
                setServerSidePage={(event: any, page: number) => {
                  setCurrentpage(page);
                }}
                setServerRowSize={setRowSize}
                serverRowSize={rowSize}
                rowHeight={rowHeight}
                rowStyle={rowStyle}
                headerHeight={headerHeight}
              />
            </CardContent>
            {/* <CardContent
              sx={{
                "&:last-child": {
                  paddingBottom: 0,
                },
              }}
            > */}
            {/*<CustomGrid
                onGridReady={onGridReady}
                handleCellClick={undefined}
                loading={false}
                columnTypes={columnTypes}
                defaultColDef={defaultColDef}
                defaultColGroupDef={defaultColGroupDef}
                disableClickSelectionRenderers={false}
                suppressRowClickSelection
                columnDefs={columnDef}
                noDataTxt="No Data..."
                rowData={actionDatas}
                TableHeight={70}
                onSelectionChanged={onSelectionChanged}
                rowSelection="multiple"
                currentPage={0}
                setServerSidePage={(event: any, page: number) => {
                  setCurrentpage(page);
                }}
                totalDataCount={0}
                setServerRowSize={setRowSize}
                serverRowSize={rowSize}
                rowHeight={rowHeight}
                rowStyle={rowStyle}
                headerHeight={headerHeight}
                serverSidePagination={true}
              /> */}
            {/* <Grid item xs={12} md={3}>
                <LoadSpinner>
                  {loading && (
                    <CircularProgress
                      sx={{
                        mx: Theme.spacing(71),
                        display: "flex",
                        alignItems: "center",
                        justiContent: "center",
                      }}
                    />
                  )}
                </LoadSpinner>
            //   </Grid> */}
            {/* // </CardContent> */}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default DeletePage;
