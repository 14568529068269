import { ChevronLeft, Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar as MuiAppBar,
  Toolbar,
  AppBarProps as MuiAppBarProps,
  Box,
  IconButton,
  List,
  ListItemIcon,
  ListItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { drawerWidth } from "../../constants";
import { getLocalStorageItem } from "../../services/localStorage";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
    // top: LinearProgress
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface INavProps {
  toggleDrawer: any;
  drawerState: any;
  title: string;
}
export default function NavBar({
  toggleDrawer,
  drawerState,
  title,
}: INavProps) {
  let navigate = useNavigate();
  const handleCheckOut = () => {
    localStorage.clear();
    navigate("/", { replace: true });
  };

  const handleUser = getLocalStorageItem("user");

  return (
    // <LinearProgress />
    <AppBar
      open={drawerState}
      style={{ backgroundColor: "white", height: "70px" }}
      position="fixed"
    >
      {" "}
      <Toolbar>
        {" "}
        <IconButton
          // style={{ backgroundColor: 'white' }}
          size="large"
          edge="start"
          // color='inherit'
          aria-label="menu"
          onClick={toggleDrawer}
          sx={{ mr: 2 }}
        >
          {!drawerState ? <MenuIcon /> : <ChevronLeft />}
        </IconButton>
        <Box component="div" sx={{ flexGrow: 1 }} style={{ color: "black" }}>
          {/* <img src={logo} height={60} width={100} /> */}
        </Box>
        <Box component="div">
          <List>
            <ListItem>
              <ListItemIcon
                sx={{
                  "&.MuiListItemIcon-root": {
                    minWidth: "55px",
                  },
                }}
              >
                <AccountCircle style={{ fontSize: "38", color: "#65b17f" }} />
              </ListItemIcon>
              <p
                style={{
                  color: "black",
                  fontSize: "20px",
                  fontWeight: "260",
                  // marginBottom: "2vh",
                  textAlign: "center",
                  justifyContent: "center",
                  textTransform: "capitalize",
                }}
              >
                {handleUser}
              </p>
            </ListItem>
          </List>
        </Box>
        <Box
          style={{
            textAlign: "end",
            color: "black",
          }}
        >
          <IconButton aria-label="logout" onClick={handleCheckOut}>
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
