import React, { useEffect, useMemo, useState } from "react";
import BarChat from "../../components/BarCharts";
import {
  Grid,
  Paper,
  Typography,
  Skeleton,
  Autocomplete,
  TextField,
  CircularProgress,
  Button,
  ClickAwayListener,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import moment from "moment";
import HomeTable from "./HomeTable";
import { API_CONSTANTS } from "../../constants/index";
import API from "../../services/apis/api";
import { styled } from "@mui/material/styles";
import * as _ from "lodash";
import CustomDetailCard from "./CustomDetailCard";
import AdminCardSection from "./mdbCards";
import { DateRangePicker } from "rsuite";
import { Box } from "@mui/system";
import Card from "@mui/material/CardActions";
import DetailCard from "./Detailcard";
import Backdrop from "@mui/material/Backdrop";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import axios from "axios";
import { openSnackbar } from "../../redux/features/snackBarSlice";
import { getLocalStorageItem } from "../../services/localStorage";

const FileDownload = require("js-file-download");

export const GraphWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: "3px 3px 3px 3px #DCDCDC",
  borderRadius: "10px",
  width: "100%",
}));
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: "10px",
  color: theme.palette.text.secondary,
  width: "100%",
  boxShadow: "3px 3px 3px 3px #DCDCDC",
}));

export const Heading = styled(Box)(({ theme }) => ({
  marginLeft: 10,
}));

const { allowedRange }: any = DateRangePicker;

function DashBoard() {
  const [discoverySearch, setDiscoverSearch] = useState({});
  const [directSearch, setDirectSearch] = useState({});
  const [totalSearch, setToatalSearch] = useState({});
  const [loading, setLoading] = useState(false);
  const [value] = useState<any[]>([]);
  const [stateValue, setStateValue] = useState<any>(null);
  const [cityValue, setCityValue] = useState<any>(null);
  const [storeValues, setStoreValue] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<any>([]);
  const [updateData] = useState(true);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentpage, setCurrentpage] = useState(1);
  const [store, setStore] = useState<any>(null);
  const [download, setDownload] = useState(false);
  const [state, setState] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [dates] = useState({ fromDate: "", toDate: "" });
  const [actionDatas, SetActionDatas] = useState([]);
  const [totalRowSize, setTotalRowSize]: any = useState(10);
  const [rowSize]: any = useState(10);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let match;
    if (stateValue !== null && cityValue === null && storeValues !== null) {
      match = JSON.stringify({ state: stateValue, storeId: storeValues });
    } else if (
      stateValue !== null &&
      cityValue === null &&
      storeValues === null
    ) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && storeValues === null) {
      match = JSON.stringify({ city: cityValue });
    } else if (cityValue !== null && storeValues !== null) {
      match = JSON.stringify({ city: cityValue, storeId: storeValues });
    } else if (
      storeValues !== null &&
      cityValue === null &&
      stateValue === null
    ) {
      match = JSON.stringify({ storeId: storeValues });
    } else {
      match = JSON.stringify({});
    }

    API.fetchWithParams(
      API_CONSTANTS.GET_ANALYTICS_GRAPH,
      (res: any) => {
        setLoading(false);
        setDirectSearch(_.get(res, "data.data.data", [])[0]);
      },
      (err: any) => {
        setLoading(false);
        console.error(err);
        if (err.response.status <= 400 && err.response.status >= 500) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      { match: match, type: "Calls" }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_ANALYTICS_GRAPH,
      (res: any) => {
        setLoading(false);
        setToatalSearch(_.get(res, "data.data.data", [])[0]);
      },
      (err: any) => {
        setLoading(false);
        console.error(err);
      },
      {
        // storeCode: value || null,
        match: match,
        type: "GoogleSearchMobile",
      }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_ANALYTICS_GRAPH,
      (res: any) => {
        setLoading(false);
        setDiscoverSearch(_.get(res, "data.data.data", [])[0]);
      },
      (err: any) => {
        setLoading(false);
        console.error(err);
      },
      // { storeCode: value || null
      { match: match, type: "GoogleMapsMobile" }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_STORE_DATA,
      (res: any) => {
        setLoading(false);
      },
      (err: any) => {
        setLoading(false);
        console.error("err", err);
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
      }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_AVALILABLE_DATES,
      (res: any) => {
        setLoading(false);
        // setFromDate(moment(res.data.data.startDate).format('YYYY-MM-DD'));
        setToDate(moment(res.data.data.endDate).format("YYYY-MM-DD"));
        // setSelectedDate([
        //   moment(res.data.data.endDate).format('YYYY-MM-DD'),
        //   moment(res.data.data.endDate).format('YYYY-MM-DD'),
        // ]);
      },
      (err: any) => {
        setLoading(false);
      },
      {}
    );
  }, [value, stateValue, cityValue, storeValues]);

  const lables = ["7 days", "14 days", "28 days", "3 months", "6 months"];

  const discovery = useMemo(() => {
    return _.values(discoverySearch).slice(1);
  }, [discoverySearch]);
  const direct = useMemo(() => {
    return _.values(directSearch).slice(1);
  }, [directSearch]);
  const total = useMemo(() => {
    return _.values(totalSearch).slice(1);
  }, [totalSearch]);

  const Token = getLocalStorageItem("token");
  const onDownload = (path: any, name: string) => {
    setDownload(true);
    axios({
      url: path, //your url
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        setDownload(false);
        FileDownload(res.data, `Close_Bi_${name}.csv`);
      })
      .catch((error) => {
        setDownload(false);
        dispatch(
          openSnackbar({
            messege: get(error, "response.data.message", " Try again later"),
            severity: "error",
          })
        );
        console.error("download file error", error);
      });
  };
  useEffect(() => {
    API.fetchWithParams(
      API_CONSTANTS.GET_AVALILABLE_DATES,
      (res: any) => {
        setLoading(false);
        setFromDate(moment(res.data.data.startDate).format("YYYY-MM-DD"));
        setToDate(moment(res.data.data.endDate).format("YYYY-MM-DD"));
        setSelectedDate([
          moment(res.data.data.endDate).format("YYYY-MM-DD"),
          moment(res.data.data.endDate).format("YYYY-MM-DD"),
        ]);
      },
      (err: any) => {
        setLoading(false);
      },
      {}
    );
  }, []);

  useEffect(() => {
    let match;
    if (stateValue !== null && cityValue === null && storeValues !== null) {
      match = JSON.stringify({ state: stateValue, storeId: storeValues });
    } else if (
      stateValue !== null &&
      cityValue === null &&
      storeValues === null
    ) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && storeValues === null) {
      match = JSON.stringify({ city: cityValue });
    } else if (cityValue !== null && storeValues !== null) {
      match = JSON.stringify({ city: cityValue, storeId: storeValues });
    } else if (
      storeValues !== null &&
      cityValue === null &&
      stateValue === null
    ) {
      match = JSON.stringify({ storeId: storeValues });
    } else {
      match = JSON.stringify({});
    }

    API.fetchWithParams(
      API_CONSTANTS.GET_ACTION_DATA,
      (res: any) => {
        setLoading(false);
        SetActionDatas(_.get(res, "data.data.result", []));
        setTotalRowSize(_.get(res, "data.data.totalRecords", 0));
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status >= 400 && err.response.status < 500) {
          localStorage.clear();
          navigate("/login", { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        fromDate: moment(selectedDate[0]).format("YYYY-MM-DD"),
        toDate: moment(selectedDate[1]).add(1, "day").format("YYYY-MM-DD"),
        pageNo: currentpage,
        size: rowSize,
        match: match,
      }
    );
  }, [
    stateValue,
    selectedDate,
    cityValue,
    storeValues,
    updateData,
    totalRowSize,
  ]);

  const cleanUp = () => {
    setTotalRowSize(0);
    SetActionDatas([]);
  };
  useEffect(() => {
    let match;
    if (stateValue !== null && cityValue === null) {
      match = JSON.stringify({ state: stateValue });
    } else if (cityValue !== null && stateValue !== null) {
      match = JSON.stringify({ city: cityValue });
    } else {
      match = JSON.stringify({});
    }
    API.fetchWithParams(
      API_CONSTANTS.GET_STORE_DATA,
      (res: any) => {
        setLoading(false);
        setStore(res.data?.data);
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status <= 400 && err.response.status >= 500) {
          localStorage.clear();
          // navigate('/login', { replace: true });

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
        match: match,
      }
    );
    API.fetchWithParams(
      API_CONSTANTS.GET_STATES,
      (res: any) => {
        setState(res?.data?.data);
      },
      (err: any) => {
        setLoading(false);
        if (err.response.status <= 400 && err.response.status > 500) {
          localStorage.clear();

          dispatch(
            openSnackbar({
              messege: get(err, "", "Token Expired! Log In Again"),
              severity: "error",
            })
          );
        }
      },
      {
        brandId: "62d1157242a9f208b4388b8a",
      }
    );
    if (stateValue !== null) {
      API.fetchWithParams(
        API_CONSTANTS.GET_CITIES,
        (res: any) => {
          setCity(res?.data?.data);
        },
        (err: any) => {
          setLoading(false);
          if (err.response.status <= 400 && err.response.status > 500) {
            localStorage.clear();
            // navigate('/login', { replace: true });

            dispatch(
              openSnackbar({
                messege: get(err, "", "Token Expired! Log In Again"),
                severity: "error",
              })
            );
          }
        },
        {
          brandId: "62d1157242a9f208b4388b8a",
          state: `${stateValue}`,
        }
      );
    }
  }, [stateValue, cityValue]);
  useEffect(() => {
    if (totalRowSize > 0) {
      if (totalRowSize < rowSize) {
        setPageCount(Math.ceil(totalRowSize / rowSize - 1));
      } else {
        setPageCount(Math.ceil(totalRowSize / rowSize));
      }
    }
  }, [
    storeValues,
    dates.toDate,
    rowSize,
    currentpage,
    totalRowSize,
    selectedDate,
    pageCount,
  ]);

  let stateArray = state?.map((i: any) => {
    return { stateName: i };
  });

  const cityArray = city?.map((i: any) => {
    return { cityName: i };
  });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const handleClickAway = () => {
    setOpen(false);
  };
  const selectDate = moment(selectedDate[1]).format("YYYY-MM-DD");
  return (
    <>
      <div>
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress sx={{ color: "green" }} />
          </Backdrop>
        </Box>
        <Grid container sx={{ flexGrow: 1 }} spacing={2}>
          <Grid item xs={7.5} md={7.5} lg={8} xl={8} sm={7.5}>
            <Heading sx={{ mt: 2 }}>
              <Typography variant="h4" style={{ fontSize: "25px" }}>
                Discovery
              </Typography>
            </Heading>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button
              aria-describedby={id}
              sx={{
                backgroundColor: "#A0D5EF",
                boxShadow: "1px 1px 1px 1px #DCDCDC",
                color: "black",
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0",
                },
                "&:hover": {
                  backgroundColor: "#A0D5EF",
                },
              }}
              disabled={total.length === 0}
              onClick={handleClick}
              variant="contained"
              startIcon={<FilterAltIcon />}
            >
              Filter
            </Button>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              transition
              sx={{ width: "360px" }}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Typography sx={{ p: 3 }}>
                        <p> Select Date</p>
                        <Grid
                          item
                          xs={7}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <DateRangePicker
                            placeholder={moment(selectedDate[0]).format(
                              "DD/MMM/YYYY"
                            )}
                            format="dd-MMM-yyyy"
                            disabledDate={allowedRange(fromDate, toDate)}
                            style={{
                              width: "700px",
                            }}
                            onChange={(dates) => {
                              if (dates) {
                                setSelectedDate(dates);
                                cleanUp();
                              }
                            }}
                          />
                        </Grid>
                        <div style={{ marginTop: "20px" }}>
                          <Autocomplete
                            id="storeName"
                            style={{ width: "100%" }}
                            options={stateArray}
                            clearOnBlur={true}
                            getOptionLabel={(option: any) => option.stateName}
                            sx={{ width: 300 }}
                            value={
                              stateValue !== null
                                ? stateArray?.find(
                                    (item: any) => item.stateName === stateValue
                                  )
                                : null
                            }
                            placeholder="Select State"
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  style: {
                                    color: "green",
                                    fontSize: "20px",
                                    fontFamily: "Inter",
                                  },
                                }}
                                variant="standard"
                                placeholder="Select state"
                              />
                            )}
                            onChange={(event: any, newValue: any, reason) => {
                              if (reason === "clear") {
                                setCityValue(null);
                                setStateValue(null);
                                setStoreValue(null);
                                setCurrentpage(1);
                                return;
                              } else {
                                setCityValue(null);
                                setStoreValue(null);
                                setStateValue(newValue.stateName);
                                setCurrentpage(1);
                              }
                            }}
                          />
                        </div>
                        <div>
                          <div style={{ marginTop: "20px" }}>
                            <Autocomplete
                              id="storeName"
                              style={{ width: "100%" }}
                              options={cityArray}
                              getOptionLabel={(option: any) => option.cityName}
                              clearOnBlur={true}
                              value={
                                cityValue !== null
                                  ? cityArray?.find(
                                      (item: any) => item.cityName === cityValue
                                    )
                                  : null
                              }
                              disabled={stateValue === null || !cityArray}
                              placeholder="Select City"
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  defaultValue=""
                                  InputLabelProps={{
                                    style: {
                                      color: "green",
                                      fontSize: "20px",
                                      fontFamily: "Inter",
                                    },
                                  }}
                                  variant="standard"
                                  placeholder="Select City"
                                />
                              )}
                              onChange={(event: any, newValue: any, reason) => {
                                if (reason === "clear") {
                                  setCityValue(null); //for reset the value()
                                  setStoreValue(null);
                                  setCurrentpage(1);
                                  return;
                                } else {
                                  setCityValue(newValue.cityName);
                                  setStoreValue(null);
                                  setCurrentpage(1);
                                }
                              }}
                            />
                          </div>
                          <div style={{ marginTop: "20px" }}>
                            <Autocomplete
                              id="storeName"
                              style={{ width: "100%" }}
                              options={store}
                              value={
                                storeValues !== null
                                  ? store?.find(
                                      (item: any) =>
                                        item.storeId === storeValues
                                    )
                                  : null
                              }
                              getOptionLabel={(option: any) =>
                                option?.storeName
                              }
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option?.storeName} - {option?.city}
                                </Box>
                              )}
                              placeholder="Select Store"
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    style: {
                                      color: "green",
                                      fontSize: "20px",
                                      fontFamily: "Inter",
                                    },
                                  }}
                                  variant="standard"
                                  placeholder="Select store"
                                />
                              )}
                              onChange={(event: any, newValue: any, reason) => {
                                if (reason === "clear") {
                                  setStoreValue(null);
                                  setCurrentpage(1);
                                  return;
                                } else {
                                  console.log("newValue", newValue.storeId);
                                  setStoreValue(newValue.storeId);
                                  setCurrentpage(1);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Typography>
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </Grid>
          <Grid
            item
            xs={2.5}
            sm={2.5}
            md={2.5}
            lg={2}
            xl={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                mx: 1,
                my: 2,
                backgroundColor: "#A0D5EF",
                color: "black",
                boxShadow: "1px 1px 1px 1px #DCDCDC",
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0",
                },
                "&:hover": {
                  backgroundColor: "#A0D5EF",
                },
              }}
              onClick={() => {
                let match;
                if (
                  stateValue !== null &&
                  cityValue === null &&
                  storeValues !== null
                ) {
                  match = JSON.stringify({
                    state: stateValue,
                    storeId: storeValues,
                  });
                } else if (
                  stateValue !== null &&
                  cityValue === null &&
                  storeValues === null
                ) {
                  match = JSON.stringify({ state: stateValue });
                } else if (cityValue !== null && storeValues === null) {
                  match = JSON.stringify({ city: cityValue });
                } else if (cityValue !== null && storeValues !== null) {
                  match = JSON.stringify({
                    city: cityValue,
                    storeId: storeValues,
                  });
                } else if (
                  storeValues !== null &&
                  cityValue === null &&
                  stateValue === null
                ) {
                  match = JSON.stringify({ storeId: storeValues });
                } else {
                  match = JSON.stringify({});
                }

                onDownload(
                  `${process.env.REACT_APP_BACKEND_API_PROXY}${
                    API_CONSTANTS.DOWNLOAD_ANALYTICS_IN_RANGE
                  }?fromDate=${moment(selectedDate[0]).format(
                    "YYYY-MM-DD"
                  )}&toDate=${moment(selectedDate[1])
                    .add(1, "day")
                    .format("YYYY-MM-DD")}&match=${match}`,
                  `${moment(selectedDate[0]).format("YYYY-MM-DD")}_${moment(
                    selectedDate[1]
                  ).format("YYYY-MM-DD")}&storeCode=${storeValues}`
                );
              }}
              disabled={
                download || totalRowSize === 0 || actionDatas.length === 0
              }
            >
              <Typography style={{ color: "black", fontSize: "16px" }}>
                {download ? "Please Wait..." : "Export Report"}
              </Typography>
            </Button>
          </Grid>

          <Grid item xs={12} md={12} lg={12} xl={12} sm={12}>
            <AdminCardSection
              direct={directSearch || [100, 200, 300, 400]}
              discovery={discoverySearch || [100, 200, 300, 400]}
              total={totalSearch || [100, 200, 300, 400]}
            />
          </Grid>
          <Grid item xs={12} md={9} lg={9} xl={9} sm={12}>
            <Heading>
              <Typography variant="h5">Google Search Mobile</Typography>
            </Heading>
          </Grid>

          <Grid item xs={12} md={12} lg={9} xl={9} sm={12}>
            <Card>
              <GraphWrapper>
                <Box sx={{ height: "278px" }}>
                  {total.length > 0 ? (
                    <BarChat
                      title="Google Search Mobile"
                      data={{
                        labels: lables,
                        datasets: [
                          {
                            data: total.length < 0 ? [0, 0, 0, 0] : total,
                            barPercentage: 0.5,
                            backgroundColor: [
                              "#BDB7B7",
                              "#EDD069",
                              "#EDBEBF",
                              "#92F194",
                              "#A0D5EF",
                            ],
                          },
                        ],
                      }}
                    />
                  ) : (
                    <Box>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton
                        sx={{ height: 150 }}
                        animation="wave"
                        variant="rectangular"
                      />
                    </Box>
                  )}
                </Box>
              </GraphWrapper>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} lg={3} md={12} xl={3}>
            <Box>
              {total.length > 0 ? (
                (
                  <CustomDetailCard data={totalSearch} endDate={selectDate} />
                ) || [0]
              ) : (
                <Skeleton
                  sx={{ height: 290, marginTop: "34px" }}
                  animation="wave"
                  variant="rectangular"
                />
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: "5px", flexGrow: 1 }} spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography variant="h5">Google Maps Mobile</Typography>

            <Item sx={{ marginTop: "10px" }}>
              <Card>
                <Box sx={{ width: "100%", margin: "10px" }}>
                  <Box style={{ minHeight: 220 }}>
                    {discovery.length > 0 ? (
                      <BarChat
                        title="Google Maps Mobile"
                        data={{
                          labels: lables,
                          datasets: [
                            {
                              data:
                                discovery.length < 0 ? [0, 0, 0, 0] : discovery,
                              barPercentage: 0.3,
                              backgroundColor: [
                                "#BDB7B7",
                                "#EDD069",
                                "#EDBEBF",
                                "#92F194",
                                "#A0D5EF",
                              ],
                            },
                          ],
                        }}
                      />
                    ) : (
                      <Box>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton
                          sx={{ height: 190 }}
                          animation="wave"
                          variant="rectangular"
                        />
                      </Box>
                    )}
                  </Box>

                  <Box sx={{ flexGrow: 1, marginTop: "15px" }}>
                    {discovery.length > 0 ? (
                      <DetailCard
                        data={discoverySearch || [100, 200, 300, 400]}
                        endDate={selectDate}
                      />
                    ) : (
                      <Skeleton
                        sx={{ height: 160 }}
                        animation="wave"
                        variant="rectangular"
                      />
                    )}
                  </Box>
                </Box>
              </Card>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography variant="h5" sx={{ marginBottam: "8px" }}>
              Calls
            </Typography>

            <Item sx={{ marginTop: "10px" }}>
              <Card>
                <Box sx={{ width: "100%", margin: "10px" }}>
                  <Box style={{ minHeight: 220 }}>
                    {direct.length > 0 ? (
                      <BarChat
                        title="Calls"
                        data={{
                          labels: lables,
                          datasets: [
                            {
                              data: direct.length < 0 ? [0, 0, 0, 0] : direct,
                              barPercentage: 0.3,
                              backgroundColor: [
                                "#BDB7B7",
                                "#EDD069",
                                "#EDBEBF",
                                "#92F194",
                                "#A0D5EF",
                              ],
                            },
                          ],
                        }}
                      />
                    ) : (
                      <Box>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton
                          sx={{ height: 190 }}
                          animation="wave"
                          variant="rectangular"
                        />
                      </Box>
                    )}
                  </Box>

                  <Box sx={{ flexGrow: 1, marginTop: "15px" }}>
                    {direct.length > 0 ? (
                      <DetailCard
                        data={directSearch || [100, 200, 300, 400]}
                        endDate={selectDate}
                      />
                    ) : (
                      <Skeleton
                        sx={{ height: 160 }}
                        animation="wave"
                        variant="rectangular"
                      />
                    )}
                  </Box>
                </Box>
              </Card>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <HomeTable
              stateValue={stateValue}
              cityValue={cityValue}
              selectedDate={selectedDate}
              storeValue={storeValues}
              currentPage={currentpage}
              totalRowSize={totalRowSize}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default DashBoard;
