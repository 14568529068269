import { useEffect, useState } from "react";
import { Card, Typography, Grid, TextField, Button } from "@mui/material";
import { useAppDispatch } from "../../hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { openSnackbar } from "../../redux/features/snackBarSlice";
import { API_CONSTANTS } from "../../constants";
import axios from "axios";
import "./style.css";
import ReactQuil from "../../components/ReactQuil";

const ArticleForm = () => {
  const [getFeildVaules, setGetFeildValues] = useState<any>({
    storeId: "",
    title: "",
    storeName: "",
    metaTitle: "",
    url: "",
    keywords: "",
    metaDescription: "",
    content: "",
  });

  const [setError, setGetError] = useState<any>({});

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const state: any = location?.state || {};

  let getMode: any = "";

  if (state?.isView) {
    getMode = "view";
  } else if (state?.isEdit) {
    getMode = "edit";
  } else {
    getMode = "create";
  }

  useEffect(() => {
    if (state?.isView || state?.isEdit) {
      setGetFeildValues({
        storeId: state?.data?.storeId,
        title: state?.data?.title,
        storeName: state?.data?.storeName,
        metaTitle: state?.data?.metaTitle,
        url: state?.data?.url,
        keywords: state?.data?.keywords,
        metaDescription: state?.data?.metaDescription,
      });
    }
  }, []);

  const handleFormValues = (event: any) => {
    setGetFeildValues({
      ...getFeildVaules,
      [event.target.name]: event.target.value,
    });
  };

  const handleGrisReactQuil = (data: any) => {
    getFeildVaules["content"] = data;
  };

  // form feild error validation

  const handleError = () => {
    let isValid = true;
    let getError: any = {};
    if (!getFeildVaules?.storeName) {
      isValid = false;
      getError.storeName = "Please enter storename";
    }
    if (!getFeildVaules?.storeId) {
      isValid = false;
      getError.storeId = "Please enter storeId";
    }
    if (!getFeildVaules?.title) {
      isValid = false;
      getError.title = "Please enter title";
    }
    if (!getFeildVaules?.metaTitle) {
      isValid = false;
      getError.metaTitle = "Please enter Meta Title";
    }
    if (!getFeildVaules?.metaDescription) {
      isValid = false;
      getError.metaDescription = "Please enter Meta Description";
    }
    if (!getFeildVaules?.url) {
      isValid = false;
      getError.url = "Please enter URL";
    }
    if (!getFeildVaules?.keywords) {
      isValid = false;
      getError.keywords = "Please enter Keywords";
    }

    setGetError(getError);
    return isValid;
  };

  const handleSubmitValue = async () => {
    if (handleError()) {
      if (state?.isEdit) {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${
              API_CONSTANTS.UPDATE_ARTICLES
            }?match=${JSON.stringify({ _id: state?.data?._id })}`,
            getFeildVaules,
            {
              headers: {
                "content-type": "application/json",
              },
            }
          )
          .then((_) => {
            setGetFeildValues({
              storeId: "",
              title: "",
              storeName: "",
              metaTitle: "",
              metaDescription: "",
              content: "",
            });
            navigate("/admin/articles");
            dispatch(
              openSnackbar({
                severity: "success",
                messege: "Articles Update Successfully",
              })
            );
          })
          .catch((err) => {
            console.log("error", err);
            dispatch(
              openSnackbar({
                severity: "error",
                messege: "Articles Update Faild",
              })
            );
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_CONSTANTS.POST_ARTILCES}`,
            getFeildVaules,
            {
              headers: {
                "content-type": "application/json",
              },
            }
          )
          .then((_) => {
            setGetFeildValues({
              storeId: "",
              title: "",
              storeName: "",
              metaTitle: "",
              metaDescription: "",
              content: "",
            });
            navigate("/admin/articles");
            dispatch(
              openSnackbar({
                severity: "success",
                messege: "Articles Added Successfully",
              })
            );
          })
          .catch((err) => {
            console.log("error", err);
            dispatch(
              openSnackbar({
                severity: "error",
                messege: "Articles Not Added",
              })
            );
          });
      }
    }
  };

  return (
    <>
      <Card className="card">
        <Typography style={{ fontSize: "23px" }}>Articles</Typography>
        <Grid container spacing={2} className="formConteiner">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="StoreName"
              name="storeName"
              disabled={state?.isView ? true : false}
              value={getFeildVaules?.storeName}
              variant="outlined"
              onChange={handleFormValues}
            />
            {setError.storeName && (
              <p style={{ color: "red", fontSize: "14px" }}>
                {setError.storeName}
              </p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="StoreId"
              name="storeId"
              disabled={state?.isView ? true : false}
              value={getFeildVaules?.storeId}
              variant="outlined"
              onChange={handleFormValues}
            />
            {setError.storeId && (
              <p style={{ color: "red", fontSize: "14px" }}>
                {setError.storeId}
              </p>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="Title"
              name="title"
              disabled={state?.isView ? true : false}
              value={getFeildVaules?.title}
              variant="outlined"
              onChange={handleFormValues}
            />
            {setError.title && (
              <p style={{ color: "red", fontSize: "14px" }}>{setError.title}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="Meta Title"
              name="metaTitle"
              disabled={state?.isView ? true : false}
              value={getFeildVaules.metaTitle}
              variant="outlined"
              onChange={handleFormValues}
            />
            {setError.metaTitle && (
              <p style={{ color: "red", fontSize: "14px" }}>
                {setError.metaTitle}
              </p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="Meta Description"
              name="metaDescription"
              disabled={state?.isView ? true : false}
              value={getFeildVaules.metaDescription}
              variant="outlined"
              onChange={handleFormValues}
            />
            {setError.metaDescription && (
              <p style={{ color: "red", fontSize: "14px" }}>
                {setError.metaDescription}
              </p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="URL"
              name="url"
              disabled={state?.isView ? true : false}
              value={getFeildVaules.url}
              variant="outlined"
              onChange={handleFormValues}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              size="small"
              fullWidth
              placeholder="Keywords"
              name="keywords"
              disabled={state?.isView ? true : false}
              value={getFeildVaules.keywords}
              variant="outlined"
              onChange={handleFormValues}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ReactQuil
              callback={handleGrisReactQuil}
              updateDatas={state?.data?.content}
              mode={getMode}
            />
          </Grid>
          {!state?.isView && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                sx={{
                  mx: 1,
                  my: 2,
                  backgroundColor: "#A0D5EF",
                  color: "black",
                  boxShadow: "1px 1px 1px 1px #DCDCDC",
                  "&.Mui-disabled": {
                    background: "#eaeaea",
                    color: "#c0c0c0",
                  },
                  "&:hover": {
                    backgroundColor: "#A0D5EF",
                  },
                }}
                onClick={handleSubmitValue}
              >
                {state?.isEdit ? "Update" : "Submit"}
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
};
export default ArticleForm;
