import { IdrawerOptions } from "../components/CustomDrawer";

export const drawerOptions: IdrawerOptions[] = [
  {
    label: "Call Log",
    path: "/admin/callLog",
    icon: "phone_in_talk",
  },
  {
    label: "Discovery",
    path: "/admin/dashBoard",
    icon: "person_search_sharp",
  },
  {
    label: "Action",
    path: "/admin/Action",
    icon: "equalizerIcon",
  },
  {
    label: "Articles",
    path: "/admin/articles",
    icon: "descriptionIcon",
  },

  {
    label: "GMB-Upload",
    path: "/admin/upload",
    icon: "uploadFileIcon",
  },
  {
    label: "GMB-Delete",
    path: "/admin/delete",
    icon: " deleteForeverIcon",
  },
  {
    label: "Wati",
    path: "/admin/wati",
    icon: "WhatsAppIcon",
  },
  {
    label: "KeywordsData-Upload",
    path: "/admin/keywordupload",
    icon: "abc",
  },
  {
    label: "Monthly Report",
    path: "/admin/generatereport",
    icon: "printIcon",
  },
  {
    label: "Pages On Fly",
    path: "/admin/pagesOnFly",
    icon: "layersSharp",
  },
];
