/* eslint-disable react-hooks/exhaustive-deps */
import CustomGrid from "../../components/CustomGrid";
import {
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
  TextField,
  Autocomplete,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import moment from "moment";
import Fade from "@mui/material/Fade";
import DescriptionIcon from "@mui/icons-material/Description";
import { styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import * as React from "react";
import { Box } from "@mui/system";
import { API_CONSTANTS } from "../../constants";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useAppDispatch } from "../../hooks";
import { openSnackbar } from "../../redux/features/snackBarSlice";
import "rsuite/dist/rsuite.min.css";
import { ColDef } from "ag-grid-community";
import { getLocalStorageItem } from "../../services/localStorage";
import { useNavigate } from "react-router-dom";
import DropdownWithActionButton from "../../components/ActionButton";
import axios from "axios";

export const GridWrapper = styled(Box)(({ theme }) => ({}));

function Articles() {
  const gridOptions: any = useRef();
  const dispatch = useAppDispatch();
  const [rowSize, setRowSize]: any = useState(5);
  const [currentpage, setCurrentpage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalRowSize, setTotalRowSize]: any = useState(0);
  const [open, setOpen] = useState(false);
  const [getArticlesData, setGetArticlesData] = useState([]);

  const [store, setStore] = useState<any>(null);
  const [storeValue, setStoreValue] = useState<any>(null);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const onGridReady = useCallback((params: any) => {
    gridOptions.current = params;
  }, []);
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const cleanUp = () => {
    setTotalRowSize(0);
  };

  useEffect(() => {
    if (totalRowSize > 0) {
      if (totalRowSize < rowSize) {
        setPageCount(Math.ceil(totalRowSize / rowSize - 1));
      } else {
        setPageCount(Math.ceil(totalRowSize / rowSize));
      }
    }
  }, [rowSize, currentpage, totalRowSize, pageCount]);
  // setPageCount(0);

  const getArticlesDataFromAPi = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_CONSTANTS.GET_ARTICLES
        }?match=${JSON.stringify({
          page: currentpage,
          limit: rowSize,
        })}`
      )
      .then((res: any) => {
        setLoading(false);
        setStore(res.data.getAllArticalsDataForStores);
        setGetArticlesData(res.data.data);
        setTotalRowSize(res.data.totalRecords);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
      });
  };
  // delete articles
  const handleDeleteArticles = async (data: any) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_CONSTANTS.DELETE_ARTICLES
        }?match=${JSON.stringify({ _id: data?._id })}`
      )
      .then((_) => {
        dispatch(
          openSnackbar({
            severity: "success",
            messege: "Articles is Deleted",
          })
        );
        getArticlesDataFromAPi();
      })
      .catch((_) => {
        dispatch(
          openSnackbar({
            severity: "error",
            messege: "Data Not Found",
          })
        );
      });
  };

  useEffect(() => {
    setLoading(true);
    getArticlesDataFromAPi();
  }, [currentpage, rowSize]);

  const headerHeight = 60;
  const rowHeight = 40;
  const rowStyle = {
    borderTop: "white 8px solid",
  };

  const theme = useTheme();
  const columnDef: ColDef[] = [
    {
      field: "_id",
      headerName: "Id",
      minWidth: 280,
      filter: "agSetColumnFilter",
      sortable: true,
      resizable: true,
      tooltipField: "storeId",
      headerTooltip: "STORE ID",

      cellStyle: {
        lineHeight: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      },
    },

    {
      field: "storeName",
      headerName: "STORE NAME",
      headerTooltip: "STORE NAME",
      filter: "agSetColumnFilter",
      minWidth: 280,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      resizable: true,
      tooltipField: "STORE NAME",
    },
    {
      field: "title",
      headerName: "TITLE",
      filter: "agSetColumnFilter",
      minWidth: 400,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      resizable: true,
      headerTooltip: "TITLE",
    },
    {
      field: "url",
      headerName: "URL",
      filter: "agSetColumnFilter",
      minWidth: 400,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      resizable: true,
      headerTooltip: "URL",
    },
    {
      field: "keywords",
      headerName: "Keywords",
      filter: "agSetColumnFilter",
      minWidth: 400,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      resizable: true,
      headerTooltip: "Keywords",
    },
    {
      field: "metaTitle",
      filter: "agSetColumnFilter",
      sortable: true,
      headerName: "META TITLE",
      minWidth: 400,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      resizable: true,
      headerTooltip: "META TITLE",
    },
    {
      field: "metaDescription",
      sortable: true,
      headerName: "META DESCRIPTION",
      minWidth: 450,
      resizable: true,
      headerTooltip: "META DESCRIPTION",
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
    },

    {
      field: "action",
      sortable: true,
      minWidth: 600,
      resizable: true,
      headerName: "ACTION",
      headerTooltip: "ACTION",

      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),

      cellRenderer: (value: any) => {
        return (
          <div style={{ padding: "30px" }}>
            <DropdownWithActionButton
              data={value?.data}
              handleDelete={handleDeleteArticles}
            />
          </div>
        );
      },
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
    };
  }, []);
  const defaultColGroupDef = useMemo(() => {
    return {
      marryChildren: true,
    };
  }, []);
  const getRoleInLocalStorage: any = getLocalStorageItem("role");
  const getRole = JSON.parse(getRoleInLocalStorage);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const columnTypes = useMemo(() => {
    return {
      numberColumn: {
        width: 200,
        filter: "agNumberColumnFilter",
        flex: 5,
        marginLeft: 200,
      },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");

            var dateParts = dateAsString.split("/");

            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
    };
  }, []);

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleFilterStoreArticles = async (data: any) => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_CONSTANTS.GET_ARTICLES
        }?match=${JSON.stringify({
          page: currentpage,
          limit: rowSize,
          storeId: data,
        })}`
      )
      .then((res: any) => {
        setLoading(false);
        setStore(res.data.getAllArticalsDataForStores);

        setGetArticlesData(res.data.data);
        setTotalRowSize(res.data.totalRecords);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <div>
      <Paper
        style={{
          padding: theme.spacing(2),
          background: "#FFFFFF",
          boxShadow: "3px 3px 3px 3px #DCDCDC",
        }}
        square
      >
        <Grid container spacing={2}>
          <Grid item xs={8} sm={8} md={9} lg={9} xl={9}>
            <Typography
              variant="h6"
              sx={{ mx: 1, my: 2 }}
              style={{
                fontWeight: 500,
                fontSize: "23px",
                color: "#000000",
                marginRight: "30px",
              }}
            >
              Articles
            </Typography>
            <Typography
              sx={{ mx: 1, my: 2 }}
              style={{
                fontWeight: 400,
                fontSize: "15px",
                color: "#000000",
              }}
            >
              Total Records : {getArticlesData?.length}
            </Typography>
          </Grid>

          <Grid
            item
            xs={1.5}
            sm={1.5}
            md={1.5}
            lg={1}
            xl={1}
            style={{ display: "flex", alignItems: "center" }}
          >
            {getArticlesData?.length > 0 && (
              <Button
                aria-describedby={id}
                style={{
                  backgroundColor: "#A0D5EF",
                  color: "black",
                  boxShadow: "1px 1px 1px 1px #DCDCDC",
                }}
                onClick={handleClick}
                variant="contained"
                startIcon={<FilterAltIcon />}
              >
                Filter
              </Button>
            )}
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              transition
              sx={{ width: "360px", marginTop: "20px" }}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Typography sx={{ p: 3 }}>
                        <div>
                          <div style={{ marginTop: "20px" }}>
                            <Autocomplete
                              id="storeName"
                              style={{ width: "100%" }}
                              options={store}
                              getOptionLabel={(option: any) => option.storeName}
                              sx={{ width: 300 }}
                              value={
                                storeValue !== null
                                  ? store?.find(
                                      (item: any) => item.storeId === storeValue
                                    )
                                  : null
                              }
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.storeName} - {option.city}
                                </Box>
                              )}
                              placeholder="Select Store"
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    style: {
                                      color: "green",
                                      fontSize: "20px",
                                      fontFamily: "Inter",
                                    },
                                  }}
                                  variant="standard"
                                  placeholder="Select store"
                                />
                              )}
                              onChange={(event: any, newValue: any, reason) => {
                                if (reason === "clear") {
                                  setStoreValue(null);
                                  setCurrentpage(1);
                                  cleanUp();
                                  return;
                                } else {
                                  handleFilterStoreArticles(newValue?.storeId);
                                  setStoreValue(newValue.storeId);
                                  setCurrentpage(1);
                                  cleanUp();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Typography>
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </Grid>

          <Grid
            item
            xs={1.5}
            sm={1.5}
            md={1.5}
            lg={1.5}
            xl={1.5}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button
              fullWidth
              variant="contained"
              sx={{
                mx: 1,
                my: 2,
                backgroundColor: "#A0D5EF",
                color: "black",
                boxShadow: "1px 1px 1px 1px #DCDCDC",
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0",
                },
                "&:hover": {
                  backgroundColor: "#A0D5EF",
                },
              }}
              startIcon={<DescriptionIcon />}
              onClick={() => {
                navigate(`/${getRole}/articleForm`);
              }}
            >
              ADD
            </Button>
          </Grid>
        </Grid>
        <GridWrapper>
          <>
            <CustomGrid
              handleCellClick={undefined}
              loading={loading}
              onGridReady={onGridReady}
              suppressExcelExport={true}
              serverSidePagination={true}
              disableClickSelectionRenderers={false}
              columnDefs={columnDef}
              defaultColDef={defaultColDef}
              defaultColGroupDef={defaultColGroupDef}
              columnTypes={columnTypes}
              suppressMultiRangeSelection={true}
              noDataTxt="No Data..."
              rowData={getArticlesData}
              TableHeight={80}
              serverPageCount={pageCount}
              totalDataCount={totalRowSize}
              currentPage={currentpage}
              setServerSidePage={(event: any, page: number) => {
                setCurrentpage(page);
              }}
              setServerRowSize={setRowSize}
              serverRowSize={rowSize}
              rowHeight={rowHeight}
              rowStyle={rowStyle}
              headerHeight={headerHeight}
            />
          </>
        </GridWrapper>
      </Paper>
    </div>
  );
}

export default Articles;
