/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

function isLogin(element: any) {
  const user = localStorage.getItem("role");
  if (user) {
    let data = JSON.parse(user);

    if (data) {
      if (data === element.data) {
        return true;
      } else if (data === "") {
        return user;
      }
      return false;
    }
    return false;
  }
  return false;
}
function PrivateRoute({ element }: any) {
  let auth = isLogin(element);
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export default PrivateRoute;
