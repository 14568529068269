import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import CustomizedSnackbars from "./components/AdvanceSnackbar";
import { useAppDispatch, useAppSelector } from "./hooks";
import { closeSnackbar } from "./redux/features/snackBarSlice";
import SignIn from "./pages/login";
import PrivateRoute from "./components/AuthRoute";
import Layout from "./User";
import ManagerLayout from "./Manager";
import AdminLayout from "./Admin";

import "./App.css";
import PageNotFound from "./pages/404";
function App() {
  const snackbar = useAppSelector((state) => state.snackbar);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let data = localStorage.getItem("role");
    if (data) {
      const userData = JSON.parse(data);
      // const userData = user.role;
      if (userData) {
        console.log("userData", userData);
      }
    }
  }, []);

  return (
    <div className="root">
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<SignIn />} />
        {/* <Route path="/pages/register" element={<RegisterPage />} /> */}
        <Route element={<PrivateRoute element={{ data: "admin" }} />}>
          <Route path="/admin/*" element={<AdminLayout />} />
        </Route>
        <Route element={<PrivateRoute element={{ data: "user" }} />}>
          <Route path="/user/*" element={<Layout />} />
        </Route>
        <Route element={<PrivateRoute element={{ data: "manager" }} />}>
          <Route path="/manager/*" element={<ManagerLayout />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <CustomizedSnackbars
        messageString={snackbar.messege}
        severity={snackbar.severity}
        autoHideDuration={snackbar.autHideDuriation}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        handleClose={() => {
          dispatch(closeSnackbar());
        }}
        open={snackbar.open}
      />
    </div>
  );
}

export default App;
