import { Typography } from "@mui/material";
import React from "react";

const pageStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  height: "100vh",
};
const PageNotFound = () => {
  return (
    <div style={pageStyle}>
      <Typography variant="h4"> !OOPS PAGE NOT FOUND (404)</Typography>
    </div>
  );
};

export default PageNotFound;
